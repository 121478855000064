<form action="" class="feedback-form" >
    <div mat-dialog-title>
      <h2>{{title}}</h2>
      <a href="javascript:void(0)" mat-dialog-close>
        <span class="icon-close"></span>
      </a>
    </div>
    <div mat-dialog-content *ngIf="title === 'Zinc'">
      <p>
        Zinc Content: Beef is considered a good source of dietary zinc. The exact amount of zinc in beef can vary depending on factors such as the cut of meat and the feeding practices of the animals. On average, beef contains approximately 4-5 milligrams of zinc per 100 grams of cooked meat.
      </p>
      <p>
        Bioavailability: The zinc present in beef is highly bioavailable, meaning it can be easily absorbed and utilized by the body. This makes beef an excellent dietary source of zinc for meeting the body's requirements.
      </p>
      <p>
        Immune Function: Zinc is known for its role in supporting a healthy immune system. It is involved in numerous immune processes, including the production and function of immune cells. Adequate zinc intake, which can be obtained from consuming beef, is important for maintaining immune function and overall health.
      </p>
    </div>
    <div mat-dialog-content *ngIf="title === 'Iron'">
        <p>
            Iron Content: Beef is considered a good dietary source of iron, particularly the red meat varieties. The iron content in beef can vary depending on factors such as the cut of meat and the animal's diet. On average, beef contains around 2-3 milligrams of iron per 100 grams of cooked meat.
        </p>
        <p>
            Heme Iron: Beef contains a form of iron known as heme iron. This type of iron is highly bioavailable, meaning it is readily absorbed and utilized by the body. Heme iron is derived from hemoglobin and myoglobin, proteins found in animal tissues, and is more efficiently absorbed than non-heme iron, which is found in plant-based foods.
        </p>
        <p>
            Oxygen Transport: One of the primary functions of iron is its role in the transport of oxygen throughout the body. Iron is a crucial component of hemoglobin, the protein in red blood cells responsible for binding oxygen and delivering it to various tissues and organs. Consuming iron-rich foods like beef can support optimal oxygen transport and overall energy levels.
        </p>
    </div>
    <div mat-dialog-content *ngIf="title === 'B12'">
        <p>
            Vitamin B12 Content: Beef, particularly organ meats like liver, is considered a good dietary source of vitamin B12. The exact amount of vitamin B12 in beef can vary depending on factors such as the cut of meat and the animal's diet. On average, beef contains approximately 1-2 micrograms of vitamin B12 per 100 grams of cooked meat.
        </p>
        <p>
            Essential for Red Blood Cell Production: Vitamin B12 is essential for the production of red blood cells. It plays a crucial role in the synthesis of DNA and the maturation of red blood cells in the bone marrow. Adequate intake of vitamin B12, obtained from sources like beef, helps prevent megaloblastic anemia, a condition characterized by large, immature red blood cells.
        </p>
    </div>
  </form>