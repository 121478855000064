import { Component, OnInit } from '@angular/core'
import { Alert, AlertType } from '../_models/index'
import { AlertService } from '../_services/index'

@Component({
  moduleId: '',
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
})
export class AlertComponent implements OnInit {
  alerts: Alert[] = []
  alertStatus: string
  alertMessage: string
  alertCss: string
  status: boolean
  id: any
  signupStatus: any
  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alertService.getAlert().subscribe((alert: Alert) => {
      const url: any = window.location.pathname
      const str: any = url
      const res: any = str.split('/')
      this.id = res[3]
      if (!alert) {
        this.alerts = []
        return
      }
      if (AlertType.Success === alert.type) {
        if (alert.status === true) {
          this.alertStatus = 'suc'
        } else {
          this.alertStatus = 'success'
        }
        this.status = alert.status
        this.alertMessage = alert.message
        this.alertCss = 'success_message active'
        this.signupStatus = alert.signupStatus
      } else if (AlertType.Error === alert.type) {
        this.alertStatus = 'error'
        this.alertMessage = alert.message
        this.alertCss = 'faild_message active'
      } else if (AlertType.Info) {
        this.alertStatus = 'info';
        this.alertCss = 'info_message active';
      }
      setTimeout(() => {
        this.alertCss = ''
        this.status = false
      }, alert.timeoutValue)
    })
  }

  closeNotification(): void {
    this.status = false
    this.alertStatus = ''
  }
}
