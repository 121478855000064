import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../app.service'
import { DataService } from './../user/data.service'

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.scss']
})
export class TermConditionComponent implements OnInit {
  terms: any = { 'title': '', 'content': '' }
  showSimmer: boolean
  noRecordFound = false
  constructor(private appService: AppService, private dataService: DataService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.dataService.menuServiceData({ url: 'term-condition' })
    this.showSimmer = true
    const termCondition = "terms-and-conditions"
    this.appService.privacyPolicy(termCondition).subscribe(
      (data: any) => {
        this.spinner.hide()
        if (data) {
          this.terms = data
          !this.terms.content ? this.noRecordFound = true : this.noRecordFound = false
          this.showSimmer = false
        } else {
          this.noRecordFound = true
        }
      }
    )
  }

}
