import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any): any {
    return this.sanitized.bypassSecurityTrustHtml(value)
  }
}
@Pipe({ name: 'timeFormat' })
export class timeFormat implements PipeTransform {
  constructor() { }
  transform(time: any) {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'PM' : 'AM';
    if (parseInt(hour) == 0)
      hour = 12;
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }
}
@Pipe({ name: 'isDisplay'})
export class checkTime implements PipeTransform {
  transform(startTime: any, endTime: any) {
    let currentDate = new Date()
    let startDate: any
    let endDate: any

    startDate = new Date(currentDate.getTime());
    startDate.setHours(startTime?.split(":")[0]);
    startDate.setMinutes(startTime?.split(":")[1]);
    startDate.setSeconds(startTime?.split(":")[2]);

    endDate = new Date(currentDate.getTime());
    endDate.setHours(endTime?.split(":")[0]);
    endDate.setMinutes(endTime?.split(":")[1]);
    endDate.setSeconds(endTime?.split(":")[2]);
    return startDate <= currentDate && endDate >= currentDate ? true : false
  }
}

@Pipe({
  name: 'link'
})
export class LinkPipe implements PipeTransform {

  transform(link: string): string {
    return this.linkify(link);
  }

  private linkify(plainText): string{
    let replacedText;
  
   const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    const replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

   const  replacePattern3 = /(([a-zA-Z0-9\-\\_\\.])+@[a-zA-Z\\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
   }

}
