<!-- Info alert popups -->
<div *ngIf="alertStatus == 'info'">
  <div class="successpop {{ alertCss }} show" id="info_message">
    <div class="d-flex align-items-center">
      <img src="assets/images/smile.svg" width="60" alt="..." />
      <div class="text-left pl-3">
        <h4 style="color: #17a2b8;">Please Wait</h4>
        <p>{{ alertMessage }}</p>
      </div>
    </div>
  </div>
</div>

<!-- Success alert popups -->
<div *ngIf="alertStatus == 'success' && !status">
  <div class="successpop {{ alertCss }} " id="success_message">
    <div class="d-flex align-items-center">
      <img src="assets/images/smile.svg" width="60" alt="..." />
      <div class="text-left mb-2 pl-3">
        <h4 style="color: #32cd32;">Success!</h4>
        <p>{{ alertMessage }}</p>
        <p *ngIf="signupStatus == 'signup'">
          Maximize Search Result by Completing preferences
        </p>
        <button
          (click)="closeNotification()"
          class="btn theme-btn"
          *ngIf="signupStatus == 'signup'"
        >
          GO TO PREFERENCES
        </button>
      </div>
    </div>
  </div>
</div>

<!-- faild alert popups -->
<div *ngIf="alertStatus == 'error'">
  <div class="successpop {{ alertCss }} show" id="faild_message">
    <div class="d-flex align-items-center">
      <img src="assets/images/sad.svg" width="60" alt="..." />
      <div class="text-left pl-3">
        <h4 style="color: #c63939;">Oops!!</h4>
        <p>{{ alertMessage }}</p>
      </div>
    </div>
  </div>
</div>
