<div class="container">
    
    <div class="row mt-3">
        <div class="col-md-12 d-flex">
            <div class="">
                <h5 class="heads">Carbon Intensity Calculator</h5>
            </div>
            <div class="ml-auto">
                <h5 class="heads d-flex">Carbon Intensity Score: 
                    <div class="total">
                        <!-- <span id="totalci" class="text">12.3 g/Mj</span> -->
                        <span id="totalci" class="text">{{ totalci ? totalci.toFixed(2) + ' g/Mj' : '0 g/Mj' }}</span>
                    </div>
                </h5>
            </div>
        </div>
    </div>
    
    <div class="card">
        <h5 class="heads">Yields - Corn, Ethanol, & Energy</h5>
        <table class="table2">
            <tbody>
                <tr class="head-tr">
                    <td class="border-right border-top-right head-border">Corn, Ethanol, and Energy Yield</td>
                    <td class="border-left border-top-left head-border">User's Value</td>
                </tr>
                <tr> 
                    <td class="border-right border-bottom">Corn Yield (Bu/Acre)</td>
                    <td class="border-bottom"><input type="number" [(ngModel)]="model.corn_yield" placeholder="0" value="178.4" required (change)="calculateButton()"></td>
                </tr>
                <tr>
                    <td class="border-right border-bottom-right">Ethanol Yield</td>
                    <td class="border-bottom-left"><input type="number" [(ngModel)]="model.ethanol_yield" placeholder="0" value="2.864" required (change)="calculateButton()"></td>
                </tr>
            </tbody>
        </table>
    </div>
    

    <div class="card">
        <h5 class="heads">Corn Farming Input Parameters</h5>
        <div class="div1">
            
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Fuels & Emissions</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <td class="border-right border-top-right-left head-border">% Blend</td>
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Fossil Fuel Diesel</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.fossil_fuel_diesel" placeholder="0" value="7.22" required (change)="calculateButton()"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-bottom"><span>{{model.fossil_fuel_diesel_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Bio and Renewable Diesel</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.bio_renewable_diesel" placeholder="0" value="0" required (change)="calculateButton()"></td>
                        <td class="border-right border-bottom"><select name="bio_renewable_diesel_percent" [(ngModel)]="model.bio_renewable_diesel_percent" (change)="calculateButton()">
                            <option value="11766">5%</option>
                            <option value="11340">10%</option>
                            <option value="10486">20%</option>
                            <option value="7925">50%</option>
                            <option value="3658">100%</option>
                        </select></td>
                        <td class="border-bottom"><span id="bio_renewable_diesel_ci">{{model.bio_renewable_diesel_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">E-98 Diesel</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.clear_flame_diesel" placeholder="0" value="0" required (change)="calculateButton()"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-bottom"><span id="clear_flame_diesel_ci">{{model.clear_flame_diesel_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Ethanol Gasoline Blend</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.etoh_blended_gasoline" placeholder="0" value="1.28" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom"><select name="etoh_blended_gasoline_percent" [(ngModel)]="model.etoh_blended_gasoline_percent" (change)="calculateButton()">
                            <option value="10700">10%</option>
                            <option value="10232">15%</option>
                            <option value="9403">30%</option>
                            <option value="6361">85%</option>
                        </select></td>
                        <td class="border-bottom"><span id="etoh_blended_gasoline_ci">{{model.etoh_blended_gasoline_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Natural Gas (ft^3/acre)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.natural_gas" placeholder="0" value="87" required (change)="calculateButton()"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-bottom"><span id="natural_gas_ci">{{model.natural_gas_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">LPG/Propane</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.lpg" placeholder="0" value="2.2" required (change)="calculateButton()"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-bottom"><span id="lpg_ci">{{model.lpg_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Electricity (kWh/Acre)</td>
                        <td class="border-right border-bottom-right-left"><input type="number" [(ngModel)]="model.electricity" placeholder="0" value="69.3" required (change)="calculateButton()"></td>
                        <td class="border-right border-bottom-right-left"></td>
                        <td class="border-bottom-left"><span id="electricity_ci">{{model.electricity_ci}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Transportation (1 way)</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <!-- <td class="border-right border-top-right-left head-border">% Blend</td> -->
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Miles to Farm Storage</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.corn_transport_miles_field_farm" placeholder="0" value="10" (change)="calculateButton()"></td>
                        <!-- <td class="border-right border-bottom"><select name="corn_transport_miles_field_farm_percent" [(ngModel)]="model.corn_transport_miles_field_farm_percent" (change)="calculateButton()">
                            <option value="85">0%</option>
                            <option value="82">5%</option>
                            <option value="79">10%</option>
                            <option value="73">20%</option>
                            <option value="55">50%</option>
                            <option value="25.5">100%</option>
                        </select></td> -->
                        <td class="border-bottom"><span id="corn_transport_miles_field_farm_ci">{{model.corn_transport_miles_field_farm_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Miles to Ethanol Plant</td>
                        <td class="border-right border-bottom-right-left"><input type="number" [(ngModel)]="model.corn_transport_miles_farm_plant" placeholder="0" value="40"(change)="calculateButton()"></td>
                        <!-- <td class="border-right border-bottom-right-left"><select name="corn_transport_miles_farm_plant_percent" [(ngModel)]="model.corn_transport_miles_farm_plant_percent" (change)="calculateButton()">
                            <option value="85">0%</option>
                            <option value="82">5%</option>
                            <option value="79">10%</option>
                            <option value="73">20%</option>
                            <option value="55">50%</option>
                            <option value="25.5">100%</option>
                        </select></td> -->
                        <td class="border-bottom-left"><span id="corn_transport_miles_farm_plant_ci">{{model.corn_transport_miles_farm_plant_ci}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div3 div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Nitrogen Fertilizer (lbs/acre)</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Ammonia</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.nitrogen_fertilizer_ammonia" placeholder="0" value="49" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="nitrogen_fertilizer_ammonia_ci">{{model.nitrogen_fertilizer_ammonia_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Urea</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.nitrogen_fertilizer_urea" placeholder="0" value="36.3" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="nitrogen_fertilizer_urea_ci">{{model.nitrogen_fertilizer_urea_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Ammonium Nitrate</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.nitrogen_fertilizer_ammonium_nitrate" placeholder="0" value="3.2" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="nitrogen_fertilizer_ammonium_nitrate_ci">{{model.nitrogen_fertilizer_ammonium_nitrate_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Ammonium Sulfate</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.nitrogen_fertilizer_ammonium_sulfate" placeholder="0" value="3.2" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="nitrogen_fertilizer_ammonium_sulfate_ci">{{model.nitrogen_fertilizer_ammonium_sulfate_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Urea Ammonium Nitrate</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.nitrogen_fertilizer_urea_ammonium_nitrate" placeholder="0" value="50.5" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="nitrogen_fertilizer_urea_ammonium_nitrate_ci">{{model.nitrogen_fertilizer_urea_ammonium_nitrate_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Nitrogen in MAP (11-52-0)</td>
                        <td class="border-right border-bottom"><span id="nitrogen_fertilizer_MAP">{{model.nitrogen_fertilizer_MAP}}</span></td>
                        <td class="border-bottom"><span id="nitrogen_fertilizer_MAP_ci">{{model.nitrogen_fertilizer_MAP_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Nitrogen in DAP</td>
                        <td class="border-right border-bottom-right-left"><span id="nitrogen_fertilizer_DAP">{{model.nitrogen_fertilizer_DAP}}</span></td>
                        <td class="border-bottom-left"><span id="nitrogen_fertilizer_DAP_ci">{{model.nitrogen_fertilizer_DAP_ci}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div4 div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">N2O from Fertilizer</td>
                        <td class="border-right border-top-right-left head-border">Precip. (Inches)</td>
                        <td class="border-right border-top-right-left head-border">EEFs</td>
                        <td class="border-right border-top-right-left head-border">Fertilizer Form</td>
                        <td class="border-right border-top-right-left head-border">App. Placement</td>
                        <td class="border-right border-top-right-left head-border">App. Timing</td>
                        <td class="border-right border-top-right-left head-border">Total N Rate</td>
                        <td class="border-left border-top-left head-border">N20 (g/Mj)</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Anhydrous Ammonia</td>
                        <td class="border-right border-bottom"><select name="anhydrous_precip" [(ngModel)]="model.anhydrous_precip" (change)="calculateButton()">
                            <option value="0.88">< 25</option>
                            <option value="0.93">25-30</option>
                            <option value="1.07">> 30</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom"><select name="anhydrous_eef" [(ngModel)]="model.anhydrous_eef" (change)="calculateButton()">
                            <option value="0.7">Yes</option>
                            <option value="1">No</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom">Am.</td>
                        <td class="border-right border-bottom">In Soil</td>
                        <td class="border-right border-bottom"><select name="anhydrous_application_timing" [(ngModel)]="model.anhydrous_application_timing" (change)="calculateButton()">
                            <option value="0.7">Side-Dress</option>
                            <option value="1.0879">Fall</option>
                            <option value="0.9">At Planting</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom"><select name="anhydrous_nrate" [(ngModel)]="model.anhydrous_nrate" (change)="calculateButton()">
                            <option value="0.6">< .8 lbs/bu</option>
                            <option value="0.913">.8 - 1 lbs/bu.</option>
                            <option value="1.2">>1 lb/bu</option>
                            </select>
                        </td>
                        <td class="border-bottom"><span id="anhydrous_n2o">{{model.anhydrous_n2o}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Granular Urea</td>
                        <td class="border-right border-bottom" rowspan="5"></td>
                        <td class="border-right border-bottom"><select name="granular_urea_eef" [(ngModel)]="model.granular_urea_eef" (change)="calculateButton()">
                            <option value="0.7">Yes</option>
                            <option value="1">No</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom">Am.</td>
                        <td class="border-right border-bottom"><select name="granular_urea_placement" [(ngModel)]="model.granular_urea_placement" (change)="calculateButton()">
                            <option value="1">In soil</option>
                            <option value="1.35">On Surface</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom"><select name="granular_urea_timing" [(ngModel)]="model.granular_urea_timing" (change)="calculateButton()">
                            <option value="0.75">Side-Dress</option>
                            <option value="1.25">Fall</option>
                            <option value="1.1331">At Planting</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom" rowspan="5"></td>
                        <td class="border-bottom"><span id="granular_urea_n2o">{{model.granular_urea_n2o}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Ammonium Nitrate</td>
                        <td class="border-right border-bottom"><select name="ammonium_nitrate_eef" [(ngModel)]="model.ammonium_nitrate_eef" (change)="calculateButton()">
                            <option value="0.7">Yes</option>
                            <option value="1">No</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom">50-50</td>
                        <td class="border-right border-bottom"><select name="ammonium_nitrate_placement" [(ngModel)]="model.ammonium_nitrate_placement" (change)="calculateButton()">
                            <option value="1">In soil</option>
                            <option value="1.25">On Surface</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom"><select name="ammonium_nitrate_timing" [(ngModel)]="model.ammonium_nitrate_timing" (change)="calculateButton()">
                            <option value="0.8">Side-Dress</option>
                            <option value="1.2">Fall</option>
                            <option value="0.982">At Planting</option>
                            </select>
                        </td>
                        <td class="border-bottom"><span id="ammonium_nitrate_n2o">{{model.ammonium_nitrate_n2o}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Ammonium Sulfate</td>
                        <td class="border-right border-bottom"><select name="ammonium_sulfate_eef" [(ngModel)]="model.ammonium_sulfate_eef" (change)="calculateButton()">
                            <option value="0.7">Yes</option>
                            <option value="1">No</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom">Am.</td>
                        <td class="border-right border-bottom"><select name="ammonium_sulfate_placement" [(ngModel)]="model.ammonium_sulfate_placement" (change)="calculateButton()">
                            <option value="1">In soil</option>
                            <option value="1.25">On Surface</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom"><select name="ammonium_sulfate_timing" [(ngModel)]="model.ammonium_sulfate_timing" (change)="calculateButton()">
                            <option value="0.8">Side-Dress</option>
                            <option value="1.3">Fall</option>
                            <option value="1.1544">At Planting</option>
                            </select>
                        </td>
                        <td class="border-bottom"><span id="ammonium_sulfate_n2o">{{model.ammonium_sulfate_n2o}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Urea Ammonium Nitrate Solution</td>
                        <td class="border-right border-bottom"><select name="urea_ammonium_nitrate_eef" [(ngModel)]="model.urea_ammonium_nitrate_eef" (change)="calculateButton()">
                            <option value="0.7">Yes</option>
                            <option value="1">No</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom">Am.</td>
                        <td class="border-right border-bottom"><select name="urea_ammonium_nitrate_placement" [(ngModel)]="model.urea_ammonium_nitrate_placement" (change)="calculateButton()">
                            <option value="0.9">In soil</option>
                            <option value="1.15">On Surface</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom"><select name="urea_ammonium_nitrate_timing" [(ngModel)]="model.urea_ammonium_nitrate_timing" (change)="calculateButton()">
                            <option value="0.7">Side-Dress</option>
                            <option value="1.25">Fall</option>
                            <option value="0.9825">At Planting</option>
                            </select>
                        </td>
                        <td class="border-bottom"><span id="urea_ammonium_nitrate_n2o">{{model.urea_ammonium_nitrate_n2o}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Nitrogen in Phosphate Fertilizer</td>
                        <td class="border-right border-bottom border-left"></td>
                        <td class="border-right border-bottom">Am.</td>
                        <td class="border-right border-bottom"><select name="nitrogen_phosphate_placement" [(ngModel)]="model.nitrogen_phosphate_placement" (change)="calculateButton()">
                            <option value="0.9">In soil</option>
                            <option value="1.2">On Surface</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom"><select name="nitrogen_phosphate_timing" [(ngModel)]="model.nitrogen_phosphate_timing" (change)="calculateButton()">
                            <option value="0.8">Side-Dress</option>
                            <option value="1.2">Fall</option>
                            <option value="1">At Planting</option>
                            </select>
                        </td>
                        <td class="border-bottom"><span id="nitrogen_phosphate_n2o">{{model.nitrogen_phosphate_n2o}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom text">Precip.</td>
                        <td class="border-right border-bottom text">C:N Ratio</td>
                        <td class="border-right border-bottom" colspan="3">Total Nitrogen Fertilizer N2O</td>
                        <td class="border-right border-bottom"><span id="n2o_fertilizer_cisum">{{model.n2o_fertilizer_cisum}}</span></td>
                        <td class="border-bottom"></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right text">Total N2O From Crop Residue</td>
                        <!--Total Precip needs to populate via nh3_precip!-->
                        <td class="border-right border-bottom-right-left"><span id="total_precip">{{model.total_precip}}
                            </span>
                        </td> 
                        <td class="border-right border-bottom-right-left"><select name="cn_ratio" [(ngModel)]="model.cn_ratio" (change)="calculateButton()">
                            <option value="0.2">> 40:1</option>
                            <option value="0.35">20:1-40:1</option>
                            <option value="0.7">< 20:1</option>
                            </select>
                        </td>
                        <td class="border-right border-bottom-right-left" colspan="3">Total Corn Residue Nitrogen N2O</td>
                        <td class="border-right border-bottom-right-left"><span id="total_corn_residue_n2o">{{model.total_corn_residue_n2o}}</span></td>
                        <td class="border-bottom-left"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div5 div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Phosphate Fertilizer Man. & Trans</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">MAP (11-52-0)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.phosphate_fert_map" placeholder="0" value="29.6" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="phosphate_fert_map_ci">{{model.phosphate_fert_map_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">DAP (18-46-0)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.phosphate_fert_dap" placeholder="0" value="29.6" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="phosphate_fert_dap_ci">{{model.phosphate_fert_dap_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Liquid P2O5 (10-34-0, 7-21-7, etc.)</td>
                        <td class="border-right border-bottom-right-left"><input type="number" [(ngModel)]="model.liquid_fert" placeholder="0" value="0" required (change)="calculateButton()"></td>
                        <td class="border-bottom-left"><span id="liquid_fert_ci">{{model.liquid_fert_ci}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div6 div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Potash Fertilizer Man. & Trans.</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">K2O Fertilizer (0-0-60)</td>
                        <td class="border-right border-bottom-right-left"><input type="number" [(ngModel)]="model.potash_fertilizer" placeholder="0" value="59.9" required (change)="calculateButton()"></td>
                        <td class="border-bottom-left"><span id="potash_fertilizer_ci">{{model.potash_fertilizer_ci}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div7 div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Lime for Soil pH</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">CaCO3</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.calcium_carbonate" placeholder="0" value="573" required (change)="calculateButton()"></td>
                        <td class="border-bottom"><span id="calcium_carbonate_ci">{{model.calcium_carbonate_ci}}</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Soil CO2 Emm. due to Lime use</td>
                        <td class="border-bottom-left" colspan="2"><span id="soil_emmissions_ci">{{model.soil_emmissions_ci}}</span></td>
                    </tr>
                </tbody>
                
            </table>
        </div>

        <div class="div8 div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Herbicide Man. & Trans.</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Herbicide</td>
                        <td class="border-right border-bottom-right-left"><input type="number" [(ngModel)]="model.herbicide" placeholder="0" value="2.3" required (change)="calculateButton()"></td>
                        <td class="border-bottom-left"><span id="herbicide_ci">{{model.herbicide_ci}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="div9 div2">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">Insecticide Man. & Trans.</td>
                        <td class="border-right border-top-right-left head-border">User's Value</td>
                        <td class="border-left border-top-left head-border">CI Score</td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Insecticide</td>
                        <td class="border-right border-bottom-right-left"><input type="number" [(ngModel)]="model.insecticide" placeholder="0" value="0.0049" required (change)="calculateButton()"></td>
                        <td class="border-bottom-left"><span id="insecticide_ci">{{model.insecticide_ci}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
