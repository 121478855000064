import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-holy-smokes-detail',
  templateUrl: './holy-smokes-detail.component.html',
  styleUrls: ['./holy-smokes-detail.component.scss']
})
export class HolySmokesDetailComponent implements OnInit {
  tabIndex : any;
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.tabIndex = params.id
    })
  }


}
