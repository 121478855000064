import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
@Injectable({
  providedIn: 'root',
})
export class DataService {
  private localService: any = new BehaviorSubject('default message')
  localDada: any = this.localService.asObservable()

  private activeMenu: any = new BehaviorSubject({ url: '' })
  menuChange: any = this.activeMenu.asObservable()
  menuServiceData(data: any): void {
    this.activeMenu.next(data)
  }
  private messageSource = new BehaviorSubject('default message')
  currentMessage = this.messageSource.asObservable()
  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  changeLocalData(message: string): any {
    this.messageSource.next(message)
  }
  private productType = new BehaviorSubject('food')
  currentMessage1 = this.productType.asObservable()
  productTypeData(data: any): void {
    this.productType.next(data)
  }
}
