const ASSETS_BASE_URL = 'https://assets.wiseupfood.com/media/'
// const VIDEO_URL = "https://results-wiseupfood.s3.amazonaws.com/"
// const VIDEO_URL = 'https://dh7jinrdd8ia3.cloudfront.net/'
const VIDEO_URL = 'https://video.wiseupfood.com/'

export const environment: any = {
  production: false,
  apiUrl: 'https://devadminapi.wiseupfood.com/',
  secretKey: '1234567890',
  brandImage: ASSETS_BASE_URL + 'brands/',
  brandThumbImage: ASSETS_BASE_URL + 'brands/thumbs/',
  dietaryImage: ASSETS_BASE_URL + 'products/dietary_alerts/icons/',
  brandNoImage: 'assets/images/tyson.png',
  noImage: 'assets/images/no-image.png',
  productImage: ASSETS_BASE_URL + 'products/images/',
  productThumbImage: ASSETS_BASE_URL + 'products/images/thumbs/',
  productNoImage: 'assets/images/product_default.png',
  userImage: ASSETS_BASE_URL + 'customers/',
  userNoImage: 'assets/images/user-grey.svg',
  headerUserNoImage: 'assets/images/user-white.svg',
  recipesImage: ASSETS_BASE_URL + 'recipes/images/',
  recipesThumbImage: ASSETS_BASE_URL + 'recipes/images/thumbs/',
  recipesNoImage: 'assets/images/product.png',
  couponImage: ASSETS_BASE_URL + 'products/coupons/',
  couponNoImage: 'assets/images/product.png',
  undefinedImage: ASSETS_BASE_URL + 'products/undefined/',
  undefinedNoImage: 'assets/images/product.png',
  couponProductImage: ASSETS_BASE_URL + 'products/coupons/product_images/',
  couponBarCodeImage: ASSETS_BASE_URL + 'products/coupons/barcode_images/',
  noLogo: 'assets/images/up-no-logo.png',
  contentVideoThumbnailPath: ASSETS_BASE_URL + 'content/videos/',
  brandDocumentsPath: ASSETS_BASE_URL + 'brands/documents/',
  noUploadImg: 'assets/images/up-no-doc.png',
  productQrcodePath: ASSETS_BASE_URL + 'products/qrcode/',
  noQrCode: 'assets/images/QR-code.png',
  qrcode_logo_path: ASSETS_BASE_URL + 'qrcode/logos/',
  qrcodeDesignPath: ASSETS_BASE_URL + 'qrcode/design/',
  qrcodeNoImage: 'assets/images/QR-code.png',
  productDetailPath: 'https://www.wiseupfood.com/products/',
  noUserImg: 'assets/images/up-no-img.png',
  faviconImagePath: ASSETS_BASE_URL + 'content/images/',
  noFaviconImage: 'assets/images/up-no-img.png',
  brandAdminImage: ASSETS_BASE_URL + 'brands_admin/',
  couponsImagePath: ASSETS_BASE_URL + 'products/coupons/',
  undefinedImagePath: ASSETS_BASE_URL + 'products/undefined/',
  couponBarcodeImagePath: ASSETS_BASE_URL + 'products/coupons/barcode_images/',
  couponProductImagePath: ASSETS_BASE_URL + 'products/coupons/product_images/',
  logoImagePath: 'assets/images/logo.png',
  adminImage: ASSETS_BASE_URL + 'admin/images/',
  // productVideoPath: ASSETS_BASE_URL + 'products/videos/',
  productVideoPath: VIDEO_URL,
  content_image_path: ASSETS_BASE_URL + 'content/images/',
  content_video_path: VIDEO_URL,
  // content_video_path: ASSETS_BASE_URL + 'content/videos/',
  source_video_path: VIDEO_URL,
  // video_thumbnail_path: ASSETS_BASE_URL + 'products/videos/',
  video_thumbnail_path: VIDEO_URL,
  website_logo_path: ASSETS_BASE_URL + 'content/images/',
  website_banner_path: ASSETS_BASE_URL + 'content/images/',
  sharing_url: 'https://sharing.wiseupfood.com/product/',
  recipe_sharing_url: 'https://sharing.wiseupfood.com/recipe/',
  coupon_sharing_url: 'https://sharing.wiseupfood.com/coupon/',
  restaurant_sharing_url: 'https://sharing.wiseupfood.com/restaurant/',
  analytics_status: false,
  noRecordFound: 'assets/images/no-record.png',
  smartAppBannerLogo: 'assets/images/wiseup-smart-app-logo.png',
  googleApiKey: 'AIzaSyANp1gacZewUy0SjwF2FOoa0lLnzkCyBeY',
  locationStorage: 'locationStorage'

  // apiUrl: 'https://api.wiseupfood.com/',
  // secretKey: '1234567890',
  // brandImage: 'https://wiseupfood.com/media/brands/',
  // brandNoImage: 'assets/images/tyson.png',
  // noImage: 'assets/images/no-image.png',
  // productImage: 'https://wiseupfood.com/media/products/images/',
  // productNoImage: 'assets/images/product_default.png',
  // userImage: 'https://wiseupfood.com/media/customers/',
  // userNoImage: 'assets/images/user.jpg',
  // recipesImage: 'https://wiseupfood.com/media/recipes/images/',
  // recipesNoImage: 'assets/images/product.png',
  // couponImage: 'https://wiseupfood.com/media/products/coupons/',
  // couponNoImage: 'assets/images/product.png',
  // undefinedImage: 'https://wiseupfood.com/media/products/undefined/',
  // undefinedNoImage: 'assets/images/product.png',
  // couponProductImage: 'https://wiseupfood.com/media/products/coupons/product_images/',
  // couponBarCodeImage: 'https://wiseupfood.com/media/products/coupons/barcode_images/',
  // adminImage: 'https://wiseupfood.com/media/admin/images/',
  // productVideoPath:
  //   'https://wiseupfood.com/media/products/videos/',
  // content_image_path:
  //   'https://wiseupfood.com/media/content/images/',
  // content_video_path:
  //   'https://wiseupfood.com/media/content/videos/',
  // source_video_path:
  //   'https://wiseupfood.com/media/products/videos/',
  // video_thumbnail_path:
  //   'https://wiseupfood.com/media/products/videos/',
  // website_logo_path:
  //   'https://wiseupfood.com/media/content/images/',
  // website_banner_path:
  //   'https://wiseupfood.com/media/content/images/',
  // sharing_url: 'https://sharing.wiseupfood.com/product/',
  // recipe_sharing_url: 'https://sharing.wiseupfood.com/recipe/',
  // coupon_sharing_url: 'https://sharing.wiseupfood.com/coupon/',
  // analytics_status: true
}

// https://api.wiseupfood.com/
//http://staging.wiseupfood.com:8080/
