import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatTabsModule } from '@angular/material/tabs'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { GoogleLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { EqualValidator } from './_directives/equal-validator.directive'
import { AlertComponent } from './_directives/index'
import { AlertService } from './_services/index'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { LoginComponent } from './login/login.component'
import { PipeModule } from './pipemodule/pipe.module'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { SignUpComponent } from './sign-up/sign-up.component'
import { AppService } from './app.service'
import { HomeService } from './user/home/home.service';
import { FilterComponent } from './product-management/product-list/filter/filter.component'
import { MatDialogModule } from '@angular/material/dialog';
const googleOauthClientId: any = '307412274319-gjs66s6kge0g5hlht0s0935qogk1136a.apps.googleusercontent.com'
import { OwlModule } from 'ngx-owl-carousel';
import { LightboxModule } from 'ngx-lightbox';
import { CookieService } from 'ngx-cookie-service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ShareModule } from '@ngx-share-pat/core';
import { PrivcyPolicyComponent } from './privcy-policy/privcy-policy.component'
import { NgxSpinnerModule } from 'ngx-spinner'
import { TermConditionComponent } from './term-condition/term-condition.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSmartBannerModule } from '@netcreaties/ngx-smart-banner';
import { HolySmokesComponent } from './holy-smokes/holy-smokes.component';
import { HolySmokesDetailComponent } from './holy-smokes-detail/holy-smokes-detail.component';
import { HolySmokesVillageDetailComponent } from './holy-smokes-village-detail/holy-smokes-village-detail.component';
import { BrandsComponent } from './brands/brands.component';
import { SourceDetailsComponent } from './product-management/product-detail/source-details/source-details.component';
import { CiscoreComponent } from './ciscore/ciscore.component';
import { PlantCiscoreComponent } from './plant-ciscore/plant-ciscore.component';

const config: any = [
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      googleOauthClientId)
  }
]

export function provideConfig(): any {
  return config
}

@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    EqualValidator,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignUpComponent,
    FilterComponent,
    PrivcyPolicyComponent,
    TermConditionComponent,
    HolySmokesComponent,
    HolySmokesDetailComponent,
    HolySmokesVillageDetailComponent,
    BrandsComponent,
    SourceDetailsComponent,
    CiscoreComponent,
    PlantCiscoreComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    MatTabsModule,
    MatExpansionModule,
    HttpClientModule,
    NgxSmartBannerModule,
    PipeModule,
    MatDialogModule,
    SocialLoginModule,
    CarouselModule,
    ShareModule.forRoot(),
    OwlModule,
    NgxSpinnerModule,
    MatMenuModule,
    LightboxModule,
    NgxSmartBannerModule,
    MatProgressSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [AlertService, AppService, HomeService, CookieService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: config,
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
      useFactory: provideConfig,
    }],
  bootstrap: [AppComponent],
  entryComponents: [FilterComponent,],
})

export class AppModule { }
