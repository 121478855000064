import { Component, OnInit } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { AppService } from '../app.service'
import { DataService } from './../user/data.service'

@Component({
  selector: 'app-privcy-policy',
  templateUrl: './privcy-policy.component.html',
  styleUrls: ['./privcy-policy.component.scss'],
})
export class PrivcyPolicyComponent implements OnInit {
  noRecordFound = false
  privcyPolicy: any = { 'title': '', 'content': '' }
  showSimmer: boolean
  constructor(private appService: AppService, private dataService: DataService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.dataService.menuServiceData({ url: 'privacy-menu' })
    this.showSimmer = true
    const type = "privacy-policy"
    this.appService.privacyPolicy(type).subscribe(
      (data: any) => {
        if (data) {
          this.spinner.hide()
          this.privcyPolicy = data
          this.showSimmer = false
          !this.privcyPolicy.content ? this.noRecordFound = true : this.noRecordFound = false
        }
        else {
          this.noRecordFound = true
        }
      }
    )
  }
}
