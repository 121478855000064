import { Pipe, PipeTransform } from '@angular/core'
@Pipe({ name: 'second_time', pure: false })
export class SecondToTime implements PipeTransform {
  transform(text: number): any {
    if (text) {
      let totalSeconds: any = text
      totalSeconds = totalSeconds * 60
      const hours: any = Math.floor(totalSeconds / 3600)
      const minutes: any = Math.floor((totalSeconds - hours * 3600) / 60)
      let result: any = hours < 10 ? '0' + hours : hours
      let strings: any = ''
      if (hours > 0) {
        if (hours === 1) {
          result += ' hr'
        } else {
          result += ' hrs'
        }

        strings += result
      }
      if (minutes > 0) {
        if (minutes === 1) {
          strings += ' ' + (minutes < 10 ? '0' + minutes : minutes) + ' min'
        } else {
          strings += ' ' + (minutes < 10 ? '0' + minutes : minutes) + ' mins'
        }
      }
      return strings
    } else {
      return '0 Sec'
    }
  }
}

