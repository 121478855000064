import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holy-smokes',
  templateUrl: './holy-smokes.component.html',
  styleUrls: ['./holy-smokes.component.scss']
})
export class HolySmokesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
