<section class="howitwork product-list">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="title mt-0 text-center">
                    <h2 class="m2 mb-2">
                        Brands
                    </h2>
                </div>
            </div>
            <div class="product-search">
                <div class="col-12">
                    <form class="d-flex align-items-center justify-content-center inner-search">
                        <div class="serch-in">
                            <span class="icon-search"></span>

                            <input type="text" name="search" [(ngModel)]="searchData" placeholder="Search brand by products or name" />
 
                            <button class="btn theme-btn search-btn" (click)="getBrands()">
                                Go
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div
              class="col-sm-6 col-md-4 col-xl-3"
              *ngFor="let product of [0, 1, 2, 3, 5, 6, 7, 8]"
              [ngClass]="{ 'd-none': showSimmer === false }"
            >
              <div class="pro-box">
                <article class="simmer-animation">
                  <div class="img-h"></div>
                  <div class="sh-content">
                    <div class="text head"></div>
                    <div class="text w-80"></div>
                    <div class="text w-75"></div>
                    <div class="text w-50"></div>
                  </div>
                </article>
              </div>
            </div>
          </div>

        <div class="row justify-content-center" *ngIf="brands.length > 0 && showSimmer == false">
            <div class="col-sm-6 col-md-4 col-xl-3" *ngFor="let brand of brands; let i = index">
                <div class="pro-box">
                    <div class="img">
                        <img [src]="
                        brand?.brand_showcase_img
                          ? appConstant.brandImage + brand?.brand_showcase_img
                          : appConstant.brandNoImage
                      "  />

                    </div>
                    <div class="content" >
                        <h4>{{brand.name}}</h4>
                        <p *ngIf="brand.order_online_products === 0">
                            {{brand.total_products}} Products
                        </p>
                        <div class="row" *ngIf="brand.order_online_products > 0">
                            <div class="col-6">
                                <p>{{brand.total_products}} Products</p>
                            </div>
                            <div class="col-6">
                                <p class="online">Order Online</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="hover-box">
                    <button
                        routerLink="/restaurant-detail/{{ brand.public_id }}"
                        
                        class="btn theme-btn"
                    *ngIf="brand.brand_type == 'restaurant'">
                        View Details
                    </button>
                    <!-- <button
                        routerLink="/product-list"
                        [queryParams]="{
                            id: brand.public_id
                          }"
                        class="btn theme-btn"
                    *ngIf="brand.brand_type != 'restaurant'">
                        View Details
                    </button> -->
                    <button
                       (click)="clickFood(brand.public_id)"
                        class="btn theme-btn"
                    *ngIf="brand.brand_type != 'restaurant' && brand.product_type == 'food'">
                        View Details
                    </button>
                    <button
                       (click)="clickBeverage(brand.public_id)"
                        class="btn theme-btn"
                    *ngIf="brand.brand_type != 'restaurant' && brand.product_type == 'beverage'">
                        View Details
                    </button>
                    </div>
                </div>
            </div>

            
            
        </div>

        <div class="row" *ngIf="brands.length == 0 && showSimmer == false">
            <div class="col-md-12 text-center no-data">
              <img src="assets/images/no-record.png" />
              <h4 class="mt-3">Record Not Found</h4>
            </div>
        </div>

        <div class="text-center product-spiner mb-2">
            <ngx-spinner class="" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#00ff00" type="square-jelly-box"
                [fullScreen]="true">
            </ngx-spinner>
        </div>
    </div>
</section>