import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { AlertService } from '../_services/index'
import { LoginService } from '../login/login.service'
import * as CryptoJS from 'crypto-js'
import { ContactUsModalComponent } from '../user/home/contact-us-modal/contact-us-modal.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  model: any = {}
  btnDisable: boolean
  forgotInfo: any = {}
  pageInfo: any = {}
  appConstant: any

  constructor(private router: Router,
    private alertService: AlertService,
    private loginService: LoginService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.appConstant = environment
    this.pageInfo = JSON.parse(localStorage.getItem('contentInfo'))
    if (localStorage.getItem('forgotInfo')) {
      localStorage.removeItem('forgotInfo')
    }
  }
  forgotPassword(valid: any): void {
    if (valid) {
      this.btnDisable = true
      this.model.userType = "customer"
      this.loginService.forGotPassword(this.model).subscribe(
        (data: any) => {
          if (data) {
            this.forgotInfo = {
              'publicId': data?.public_id,
              'email': this.model.email
            }
            const setData: any = CryptoJS.AES.encrypt(
              JSON.stringify(this.forgotInfo),
              environment.secretKey
            ).toString()

            localStorage.setItem('forgotInfo', setData)
            this.router.navigate(['/reset-password'])
            this.alertService.success(data.message)
          } 
          this.btnDisable = false
        }
      )
    }
  }

  contactUsDialog() {
    const dialogRef = this.dialog.open(ContactUsModalComponent, {
      width: '670px',
      panelClass: "contact-us-dialog"
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        
      }
    });
  }
}
