declare var google:any;
const googleOauthClientId: any = '307412274319-gjs66s6kge0g5hlht0s0935qogk1136a.apps.googleusercontent.com'
import { Component, ElementRef, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider} from '@abacritt/angularx-social-login';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { AlertService } from '../_services/index';
import { LoginService } from './login.service';
import { ContactUsModalComponent } from '../user/home/contact-us-modal/contact-us-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  currentUrl: any;
  passwordType: any = 'password';
  model: any = {};
  btnDisable = false;
  accessToken: any;
  expiresIn: any;
  tokenType: any;
  rememberMe: any = false;
  encryptedData: any;
  decryptedData: any;
  password: any;
  redirectUrl: any;
  socialData: any = {};
  pageInfo: any = {};
  appConstant: any;
  alertStatus: string;
  alertMessage: string;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private el: ElementRef,
    private loginService: LoginService,
    public authService: SocialAuthService,
    public dialog: MatDialog,
    private ngZone: NgZone // Injecting NgZone
  ) {}

  ngOnInit(): void {
    this.appConstant = environment;
    this.pageInfo = JSON.parse(localStorage.getItem('contentInfo'));
    this.encryptedData = localStorage.getItem('rememberMe');
    if (this.encryptedData) {
      this.model = JSON.parse(
        CryptoJS.AES.decrypt(
          this.encryptedData,
          environment.secretKey
        ).toString(CryptoJS.enc.Utf8)
      );
      this.rememberMe = true;
    }
    if (localStorage.getItem('forgotInfo')) {
      localStorage.removeItem('forgotInfo');
    }

    this.initializeGoogleSignIn();
  }

  /**
   * Initializes Google Sign-In for the web application.
   * This method sets up the Google Identity Services library to enable users to sign in with their Google accounts.
   * It configures the client ID for the OAuth flow, specifies the context of the sign-in, and binds the sign-in callback function.
   * Additionally, it renders the Google Sign-In button within a specified HTML element and optionally triggers the sign-in flow immediately.
   */
  initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id : googleOauthClientId,
      context   : 'signin',
      callback  : this.onSignInGSI.bind(this)
    });

    google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      { theme: 'outline', size: 'large'}
    );
    
    google.accounts.id.prompt();
  }

  /**
   * Decodes a JWT token returned from Google's API.
   * This method extracts and decodes the payload from the JWT token,
   * which is Base64Url encoded, to a JSON object.
   *
   * @param {string} token - The JWT token received from Google's API.
   * @returns {Object} The decoded JSON payload of the JWT token.
   */
  decodeJwtResponseFromGoogleAPI(token) {
    let base64Url   = token.split('.')[1];
    let base64      = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  /**
   * Handles the sign-in response from Google's Identity Services.
   * This method is specified as the callback function in the Google Sign-In initialization process.
   * It decodes the JWT response to retrieve the user's profile information and logs it to the console.
   *
   * @param {Object} response - The response object received from Google upon successful authentication.
   */
  onSignInGSI(response) {
    let responsePayload = this.decodeJwtResponseFromGoogleAPI(response.credential);
    console.log("ID: " + responsePayload.sub);
    console.log('Full Name: ' + responsePayload.name);
    console.log('Given Name: ' + responsePayload.given_name);
    console.log('Family Name: ' + responsePayload.family_name);
    console.log("Image URL: " + responsePayload.picture);
    console.log("Email: " + responsePayload.email);
    
    // Ensuring that Angular change detection is run in the Angular zone
    this.ngZone.run(() => {
      this.handleCredentialResponse(response);
    });
  }

  /**
   * Processes the Google Sign-In token and user information to perform a social login.
   * This method prepares and sends the information required by the application's backend
   * for a user trying to sign in using Google. It also handles the response or any errors
   * from the backend and updates the UI accordingly.
   *
   * @param {Object} response - The response object received from Google upon successful authentication.
   */
  async handleCredentialResponse(response: any) {
    console.log("Encoded JWT ID token: " + response.credential);
    console.log(response);
    
    let responsePayload   = this.decodeJwtResponseFromGoogleAPI(response.credential);
    const googleTokenData = {
        social_type         : 'google',
        social_access_token : response.credential,
        social_id           : responsePayload.sub, // Use the correct field for social_id
        first_name          : responsePayload.given_name,
        last_name           : responsePayload.family_name,
        email               : responsePayload.email
    };
    // Setting the alert to show "Logging in..."
    this.alertService.info('Logging in...');
    this.alertStatus  = 'info';
    this.alertMessage = 'Logging in...';

    try {
        const response: any = await this.loginService.socialLogin(googleTokenData).toPromise();
        
        this.alertService.success('You are logged in successfully.');
        this.accessToken  = response.access_token;
        this.expiresIn    = response.expires_in;
        this.tokenType    = response.token_type;
        delete this.model.password;
        localStorage.removeItem('socialInfo');
        this.getUserInfo();
    } catch (err) {
        if (err.status === 401 || err.status === 406) {
            if (err.error.error) {
                this.alertService.error(err.error.error);
            }
        } else if (err.status === 400 || err.status === 404) {
            const sendData = {
                first_name          : '',
                last_name           : '',
                social_access_token : '',
                social_type         : '',
                user_type           : '',
            };
            sendData.first_name           = err.error.first_name;
            sendData.last_name            = err.error.last_name;
            sendData.social_access_token  = this.socialData.social_access_token;
            const setUserData: any = CryptoJS.AES.encrypt(
                JSON.stringify(sendData),
                environment.secretKey
            ).toString();
            localStorage.setItem('socialInfo', setUserData);
            this.alertService.error('Failed to log in with Google.');
            console.error('Google sign-in error:', err);
        }
    }
  }

  login(valid: any): void {
    if (valid && !this.btnDisable) {
      this.btnDisable = true;
      localStorage.removeItem('productType');
      localStorage.removeItem('bevarageRememberMe');
      localStorage.removeItem('checkedRemember');
      if (localStorage.getItem('bevarageRememberMe')) {
        localStorage.removeItem('bevarageRememberMe');
      }
      if (localStorage.getItem('isNotRedirect')) {
        localStorage.removeItem('isNotRedirect');
      }
      if (localStorage.getItem('isCheckedBevarage')) {
        localStorage.removeItem('isCheckedBevarage');
      }
      if (localStorage.getItem('food')) {
        localStorage.removeItem('food');
      }
      if (localStorage.getItem('beverage')) {
        localStorage.removeItem('beverage');
      }
      this.loginService.login(this.model).subscribe((data: any) => {
        if (data) {
          this.accessToken  = data?.access_token;
          this.expiresIn    = data?.expires_in;
          this.tokenType    = data?.token_type;
          this.password     = this.model.password;
          this.getUserInfo();
        }
        this.btnDisable = false;
      });
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      firstInvalidControl.focus();
    }, 1000);
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset: any = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  getUserInfo(): void {
    const sendData: any = {
      accessToken: this.accessToken,
      expiresIn: this.expiresIn,
      tokenType: this.tokenType,
    };
    sendData.userType = 'customer';
    this.loginService.getUserInfo(sendData).subscribe(
      (data: any) => {
        if(data){
          this.model          = JSON.parse(JSON.stringify(data));
          this.model.userType = sendData.userType;
          this.model.first_name = data.first_name;
          this.model.last_name = data.last_name;
          this.model.email = data.email;
          this.model.image = data.image;
          this.model.accessToken = this.accessToken;
          this.model.expiresIn = this.expiresIn;
          this.model.tokenType = this.tokenType;
          this.model.password = this.password;
          const setUserData: any = CryptoJS.AES.encrypt(
            JSON.stringify(this.model),
            environment.secretKey
          ).toString();
          localStorage.setItem('userInfo', setUserData);
          this.alertService.success('You are logged in successfully.');
          if (this.rememberMe === true) {
            const rememberMe: any = CryptoJS.AES.encrypt(
              JSON.stringify(this.model),
              environment.secretKey
            ).toString();
            this.encryptedData = localStorage.setItem('rememberMe', rememberMe);
          } else {
            localStorage.removeItem('rememberMe');
          }
          this.router.navigate(['/']);
        }
        this.btnDisable = false;
      }
    );
  }

  changePasswordType(): void {
    this.passwordType === 'password'
      ? (this.passwordType = 'text')
      : (this.passwordType = 'password');
  }

  contactUsDialog() {
    const dialogRef = this.dialog.open(ContactUsModalComponent, {
      width: '670px',
      panelClass: "contact-us-dialog"
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        
      }
    });
  }
}
