import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/internal/Observable'
import { NgxSpinnerService } from 'ngx-spinner'
import { of, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { AlertService } from './_services'
import constApi from 'src/assets/json/endpoint.json'
@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) { }
  getInformation(): Observable<any> {
    return this.http.get(constApi.homeApi.baseUrl + constApi.homeApi.webContent)
  }
  privacyPolicy(type: any): Observable<any> {
    this.spinner.show()
    return this.http
      .get(constApi.homeApi.baseUrl + constApi.homeApi.content + type + '/')
      .pipe(
        catchError((err) => {
          this.errorHandler(err)
          return of(null)
        })
      )
  }
 
  errorHandler(error: any): any {
    this.spinner.hide()
    if (error.status === 403) {
      this.alertService.error(error.error.error)
    } else if (error.status === 400) {
      this.alertService.error(error.error.error)
    } else if (error.status === 404) {
      this.alertService.error(error.error.error)
    } else if (error.status === 500) {
      this.alertService.error(error.error.error)
    } else if (error.status === 0) {
      this.alertService.error(error.error.error)
    } else if (error.status === 422) {
      this.alertService.error(error.error.error)
    } else if (error.status === 409) {
      this.alertService.error(error.error.error)
    } else if (error.status === 401) {
      this.alertService.error(error.error.error)
    }
    return throwError(error)
  }
}
