<div class="login-page">
  <div class="login-content user-log">
    <div class="login-logo">
      <a class="navbar-brand" routerLink="/login">
        <img
          src="assets\images\TW-Logo-(26).png" alt="image"
        />
        <img
          class="mob-logo"
          src="assets\images\TW-Logo-(26).png" alt="image"
        />
      </a>
    </div>
    <div class="co-box">
      <p>
        "If a picture is worth a thousand words, a video is worth a thousand
        pictures"
      </p>
      <!-- <button class="btn btn-yellow" routerLink="/help-and-support">
        Contact Us
      </button> -->
      <button class="btn btn-yellow" (click)="contactUsDialog()">
        Contact Us
      </button>
    </div>
  </div>
  <div class="user-form">
    <div class="loginnow">
      <div class="form-box">
        <div class="head d-flex align-items-center justify-content-between">
          <h3 class="text-capitalize">Signup</h3>
          <div class="d-flex align-items-center">
            <a href="javascript:void(0)" routerLink="/login">
              <span class="icon-arrow mr-1"></span> Back to Login
            </a>
          </div>
        </div>
        <form (ngSubmit)="signup(signUpForm.valid)" #signUpForm="ngForm">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>First Name</label>
                <input
                  pattern="^[a-zA-z]+([\s][a-zA-Z]+)*$"
                  #first_name="ngModel"
                  required
                  [(ngModel)]="model.first_name"
                  name="first_name"
                  name="firstName"
                  [(ngModel)]="model.first_name"
                  type="text"
                  placeholder="Enter first name"
                  class="form-control"
                  required
                  maxlength="30"
                  [ngClass]="{
                    'input-error':
                      first_name?.errors &&
                      (first_name.dirty || signUpForm.submitted)
                  }"
                />
                <div
                  class="validation_div"
                  *ngIf="
                    first_name.errors &&
                    (first_name.dirty ||
                      first_name.touched ||
                      signUpForm.submitted)
                  "
                >
                  <p class="mb-0" *ngIf="first_name.errors.required">
                    The first name is required
                  </p>
                  <p class="mb-0" *ngIf="first_name.errors.pattern">
                    The first name is invalid.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>Last Name</label>
                <input
                  pattern="^[a-zA-z]+([\s][a-zA-Z]+)*$"
                  #last_name="ngModel"
                  name="lastName"
                  [(ngModel)]="model.last_name"
                  type="text"
                  placeholder="Enter last name"
                  class="form-control"
                  required
                  maxlength="150"
                  [ngClass]="{
                    'input-error':
                      last_name?.errors &&
                      (last_name.dirty || signUpForm.submitted)
                  }"
                />
                <div
                  class="validation_div"
                  *ngIf="
                    last_name.errors &&
                    (last_name.dirty ||
                      last_name.touched ||
                      signUpForm.submitted)
                  "
                >
                  <p class="mb-0" *ngIf="last_name.errors.required">
                    The last name is required
                  </p>
                  <p class="mb-0" *ngIf="last_name.errors.pattern">
                    The last name is invalid.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Email</label>
            <input
              name="email"
              [(ngModel)]="model.email"
              type="email"
              placeholder="Enter email"
              class="form-control"
              required
              pattern="[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-zA-Z]{2,8}$"
              type="email"
              #email="ngModel"
              maxlength="150"
              [ngClass]="{
                'input-error':
                  email?.errors && (email.dirty || signUpForm.submitted)
              }"
            />
            <div *ngIf="email.errors">
              <div class="validation_div">
                <p
                  *ngIf="email.errors.pattern && signUpForm.submitted"
                  class="mb-0"
                >
                  The correct email is required
                </p>
                <p
                  *ngIf="email.errors.required && signUpForm.submitted"
                  class="mb-0"
                >
                  The email is required
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="!model.social_access_token" class="form-group ic-pass">
            <label>Password</label>
            <span
              (click)="changePasswordType()"
              [ngClass]="{
                'icon-show-pass': passwordType != 'password',
                'icon-hide-pass': passwordType == 'password'
              }"
            ></span>
            <input
              minlength="6"
              maxlength="30"
              validateEqual="confirm_password"
              reverse="true"
              #new_password="ngModel"
              required
              [(ngModel)]="model.password"
              name="new_password"
              type="{{ passwordType }}"
              placeholder="Enter password"
              class="form-control with-icon"
              [ngClass]="{
                'input-error':
                  new_password?.errors &&
                  (new_password.dirty || signUpForm.submitted)
              }"
              required
            />
            <div
              class="validation_div"
              *ngIf="
                new_password.errors &&
                (new_password.dirty ||
                  new_password.touched ||
                  signUpForm.submitted)
              "
            >
              <p class="mb-0" *ngIf="new_password.errors.required">
                The password is required
              </p>
              <p
                class="mb-0"
                *ngIf="
                  new_password.errors.minlength &&
                  signUpForm.submitted &&
                  !new_password.errors.pattern
                "
              >
                The Password must be at least 6 characters long
              </p>
              <p
                class="mb-0"
                *ngIf="new_password.errors.pattern && signUpForm.submitted"
              >
                Password does not contain any space
              </p>
            </div>
          </div>
          <div *ngIf="!model.social_access_token" class="form-group ic-pass">
            <label>Confirm Password</label>
            <span
              (click)="changeConfirmPasswordType()"
              [ngClass]="{
                'icon-show-pass': confirmPasswordType != 'password',
                'icon-hide-pass': confirmPasswordType == 'password'
              }"
            ></span>
            <input
              maxlength="30"
              validateEqual="new_password"
              reverse="false"
              [(ngModel)]="model.confirm_password"
              name="confirm_password"
              #confirm_password="ngModel"
              required
              type="{{ confirmPasswordType }}"
              name="password"
              value=""
              placeholder="Enter confirm password"
              class="form-control with-icon"
              required
              [ngClass]="{
                'input-error':
                  confirm_password?.errors &&
                  (confirm_password.dirty || signUpForm.submitted)
              }"
            />
            <div
              [hidden]="
                confirm_password.valid ||
                (confirm_password.pristine && !signUpForm.submitted)
              "
              class="validation_div"
              style="color: red"
            >
              <p class="mb-0" *ngIf="model.confirm_password">
                Confirm password doesn't match
              </p>
              <p class="mb-0" *ngIf="!model.confirm_password">
                The confirm password is required
              </p>
            </div>
          </div>
          <div class="form-group info-form">
            <label for=""
              >Zip Code
              <a href="javascript:void(0)" (click)="toggle()">
                <span class="icon-info"></span>
              </a>
            </label>
            <div class="info-content" [ngClass]="{ show: isShow }">
              <h6>Why do we ask for your Zip Code?</h6>
              <a href="javascript:void(0)" (click)="toggle()">
                <span class="icon-close"></span>
              </a>
              <p>
                We require your zip code to allow us to provide you and the
                other Wise Guys the best deals as well as to allow us to notify
                you in the case of recalls and/or there are revenant issues
                specific to your zip code.
              </p>
              <p class="mb-0">
                Please note: Wise Up Food does not require you're exact location
                nor do we ever log your location in the case you provide access
                for more exact deal offerings.
              </p>
            </div>
            <input
              type="text"
              placeholder="Enter zip code"
              class="form-control"
              name="zipCode"
              [(ngModel)]="model.zipCode"
              #zipCode="ngModel"
              required
              maxlength="20"
              [ngClass]="{
                'input-error':
                  zipCode?.errors && (zipCode.dirty || signUpForm.submitted)
              }"
            />
            <div
              class="validation_div"
              *ngIf="
                zipCode.errors &&
                (zipCode.dirty || zipCode.touched || signUpForm.submitted)
              "
            >
              <p class="mb-0" *ngIf="zipCode.errors.required">
                The zip code is required
              </p>
            </div>
          </div>
          <div class="form-group">
            <div class="my_radio mb-0">
              <input
                type="checkbox"
                class="form-check-input"
                name="agree"
                [(ngModel)]="model.agree"
                #agree="ngModel"
                id="terms1"
                required
                [ngClass]="{
                  'input-error':
                    agree?.errors && (agree.dirty || signUpForm.submitted)
                }"
              />
              <label class="form-check-label" for="terms1">
                I hereby agree to the
                <a href="javascript:void()" (click)="ContentTermstoggle()"
                  ><b>Terms & Conditions</b></a
                >
                and the
                <a href="javascript:void()" (click)="ContentPrivacytoggle()"
                  ><b>Privacy Policy</b></a
                >
              </label>
              <span class="check"></span>
            </div>
            <div
              class="validation_div"
              *ngIf="
                agree.errors &&
                (agree.dirty || agree.touched || signUpForm.submitted)
              "
            >
              <p class="mb-0" *ngIf="agree.errors.required">
                Please agree to the Terms & Conditions and Privacy Policy.
              </p>
            </div>
          </div>
          <div class="text-center pt-3">
            <button [disabled]="btnDisable" type="submit" class="btn theme-btn">
              <span
                class=""
                [ngClass]="{ 'icon-loader load spin': btnDisable }"
              ></span
              >Signup
            </button>
          </div>
        </form>
        <div class="signup-text">
          <p>Already have an account? <a routerLink="/login">Login</a></p>
        </div>
      </div>
    </div>
    <div class="content-managment-box" [ngClass]="{ show: isShowTermsContent }">
      <a
        href="javascript:void(0)"
        (click)="ContentTermstoggle()"
        class="close-btn"
      >
        <span class="icon-close"></span>
      </a>
      <app-term-condition></app-term-condition>
    </div>
    <div
      class="content-managment-box"
      [ngClass]="{ show: isShowPrivacyContent }"
    >
      <a
        href="javascript:void(0)"
        (click)="ContentPrivacytoggle()"
        class="close-btn"
      >
        <span class="icon-close"></span>
      </a>
      <app-privcy-policy></app-privcy-policy>
    </div>
  </div>
</div>
