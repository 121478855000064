import { Component, ViewContainerRef } from '@angular/core'
import { DataService } from './user/data.service'
import { AppService } from './app.service'
import { environment } from './../environments/environment'
import firebase from 'firebase'
import { NgxSmartBannerService } from '@netcreaties/ngx-smart-banner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  contentInfo: any = {}
  favIcon = ''
  favImgPath = ''
  appConstant = environment

  constructor(private appService: AppService, private dataService: DataService, private readonly ngxSmartBannerService: NgxSmartBannerService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.ngxSmartBannerService.onClose.subscribe(() => {
    });
    this.ngxSmartBannerService.onOpen.subscribe(() => {
    });
    // this.ngxSmartBannerService.initialize({
    //   viewContainerRef: this.viewContainerRef,
    //   title: "Transparency Wise",
    //   author: 'TransparencyWise.com',
    //   buttonLabel: 'Install App',
    //   icon: {
    //     ios:
    //       this.appConstant.smartAppBannerLogo,
    //     android:
    //       this.appConstant.smartAppBannerLogo,
    //   },
    //   buttonUrl: {
    //     ios: 'https://apps.apple.com/us/app/id1530680239',
    //     android: 'https://play.google.com/store/apps/details?id=com.it.wiseupfood',
    //   },
    //   daysHidden: null,
    //   daysReminder: null,
    //   hideRating: true,
    // });
  }
  ngOnInit(): void {
    const firebaseConfig = {
      apiKey: 'AIzaSyANp1gacZewUy0SjwF2FOoa0lLnzkCyBeY',
      authDomain: 'wise-up-food.firebaseapp.com',
      databaseURL: 'https://wise-up-food.firebaseio.com',
      projectId: 'wise-up-food',
      storageBucket: 'wise-up-food.appspot.com',
      messagingSenderId: '307412274319',
      appId: '1:307412274319:web:2df689a4df1e08cd9af4fa',
      measurementId: 'G-561G3YL095',
    }
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
    this.appService.getInformation().subscribe((res: any) => {
      if (res) {
        this.contentInfo = JSON.stringify(res)
        this.favIcon = res?.website_content?.favicon_icon?.favicon_icon
        localStorage.setItem('contentInfo', this.contentInfo)
        this.dataService.changeLocalData('update data')
        const src = document.querySelector('img[name="website_logo"]') as HTMLImageElement
        if (src?.src) {
          src.src = this.appConstant?.website_logo_path + res?.website_content?.website_logo?.website_logo

        }
        this.favIcon
          ? (this.favImgPath = environment.content_image_path + this.favIcon)
          : (this.favImgPath = 'favicon.ico')
        const favIcon: HTMLLinkElement = document.querySelector('#favicon')
        favIcon.href = this.favImgPath
      }
    })
  }
}
