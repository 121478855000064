import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { environment } from '../../environments/environment'
import { AlertService } from '../_services/index'
import { BrandService } from './brand.service'
//import { FilterComponent } from './filter/filter.component'
import { DataService } from '../user/data.service'
import { HomeService } from '../user/home/home.service'
import { ActivatedRoute, Router } from '@angular/router'
import * as CryptoJS from 'crypto-js'
import { FormControl } from '@angular/forms'
import { Observable, throwError } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { MatDialog } from '@angular/material/dialog'
import { ProductBeverageComponent } from '../user/home/header/product-beverage/product-beverage.component'

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  productType: any = 'Product Food'
  onLoad = false
  control = new FormControl('');
  getAllBrands: any[] = [];
  filteredBrand: Observable<string[]>;
  callApi = false
  brands: any = []
  classCheck: any
  showSimmer = false
  searchMode: boolean;
  searchData: any
  sub: any
  subscription: any
  appConstant: any = environment
  userInfo: any

  constructor(
    private data: DataService,
    private BrandService: BrandService,
    private dataService: DataService,
    private homeService: HomeService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.sub = this.route.params.subscribe((params: any) => {
      this.searchData.search = params.search
    })
    this.getBrandsData()
  }

  ngOnInit(): void {
    this.userInfo = localStorage.getItem('userInfo')
    if (this.userInfo) {
      this.userInfo = JSON.parse(
        CryptoJS.AES.decrypt(this.userInfo, environment.secretKey).toString(
          CryptoJS.enc.Utf8
        )
      )
    }
    this.getBrandList()
  }

  getBrandsData() {
    this.BrandService.getAllBrands(this.searchData).subscribe(
      (data: any) => {
        this.getAllBrands = data?.results
        console.log(this.getAllBrands)
        this.filteredBrand = this.control.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      },
      (err: any) => {
        this.apiErrorHandler(err)
      }
    )
  }

  getBrandList() {
    this.showSimmer = true
    this.BrandService.getAllBrands(this.searchData).subscribe(
      (data: any) => {
        let final = []
        for (let i = 0; i < data.results.length; i++) {
          if (data.results[i]['total_products'] > 0) {
            final.push(data.results[i])
          }
        }
        console.log(final)
        this.brands = final
        this.showSimmer = false
      },
      (err: any) => {
        this.apiErrorHandler(err)
      }
    )
  }

  clickFood(id: any) {
    this.productType = 'Product Food'
    this.data.productTypeData(this.productType)
    this.router.navigate(['/product-list'],
      { queryParams: { id: id } })
  }

  clickBeverage(id: any) {
    if (!localStorage.getItem('bevarageRememberMe') || localStorage.getItem('bevarageRememberMe') === 'no' && !localStorage.getItem('isCheckedBevarage')) {
      this.openBeverage(id)
    } else if (localStorage.getItem('bevarageRememberMe') === 'yes') {
      this.productType = 'beverage'
      this.data.productTypeData(this.productType)
      this.router.navigate(['/product-list'],
        { queryParams: { id: id } })
    } else if (localStorage.getItem('bevarageRememberMe') === 'no' && localStorage.getItem('isCheckedBevarage') && this.userInfo) {
      this.openBeverage(id)
    }
  }

  openBeverage(id: any) {
    localStorage.setItem('isNotRedirect', 'no')
    const dialogRef = this.dialog.open(ProductBeverageComponent, {
      width: '500px',
    })
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response === 'yes') {
        this.data.productTypeData('beverage')
        this.router.navigate(['/product-list'],
          { queryParams: { id: id } })
      }
    })

  }

  getBrands(): void {
    //onsole.log(this.searchData)
    this.showSimmer = true
    this.BrandService.getAllBrands(this.searchData).subscribe(
      (data: any) => {
        let final = []
        for (let i = 0; i < data.results.length; i++) {
          if (data.results[i]['total_products'] > 0) {
            final.push(data.results[i])
          }
        }
        this.brands = final
        //console.log(this.brands)
        this.showSimmer = false
      },
      (err: any) => {
        this.apiErrorHandler(err)
      }
    )
  }

  onSearchChange(value) {
    console.log(value)
    console.log(this.searchData)
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    const filter = this.getAllBrands.filter(brand => this._normalizeValue(brand.name).includes(filterValue));
    filter.length > 0 ? this.classCheck : this.classCheck = false
    return filter.slice(0, 5)
  }
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  apiErrorHandler(error: any): any {
    this.homeService.log_out(error.status)
    if (error.status === 403) {
      this.alertService.error(error.error.error)
    } else if (error.status === 400) {
      this.alertService.error(error.error.error)
    } else if (error.status === 404) {
      this.alertService.error(error.error.error)
    } else if (error.status === 500) {
      this.alertService.error(error.error.error)
    } else if (error.status === 0) {
      this.alertService.error(error.error.error)
    } else if (error.status === 422) {
      this.alertService.error(error.error.error)
    } else if (error.status === 409) {
      this.alertService.error(error.error.error)
    } else if (error.status === 401) {
      this.alertService.error(error.error.error)
    }
    return throwError(error)
  }
  // ngOnDestroy() {
  //   this.subscription.unsubscribe()
  // }
  brandList(type): void {
    // this.productService.list(this.searchData).subscribe(
    //   (data: any) => {
    //     this.onLoad = true
    //     if (data) {
    //       this.callApi = false
    //       this.spinner.hide()
    //       this.totalRecords = data.total_records
    //       if (this.searchData.page === 1) {
    //         this.products = data.results
    //       } else {
    //         this.products = this.products.concat(data.results)
    //         this.loadMoreDisable = false
    //       }
    //       if (type != 'filter' && type != 'search') {
    //         const productApiData: any = CryptoJS.AES.encrypt(
    //           JSON.stringify(data),
    //           environment.secretKey
    //         ).toString()
    //         if (this.searchData.product_type === 'food') {
    //           if (this.searchData.page === 1) {
    //             localStorage.setItem('food', productApiData)
    //           }

    //         } else if (this.searchData.product_type === 'beverage') {
    //           if (this.searchData.page === 1) {
    //             localStorage.setItem('beverage', productApiData)
    //           }
    //         }
    //       }
    //       this.showSimmer = false

    //       for (let i = 0; i < this.products.length; i++) {
    //         this.products[i].buttons = []
    //       }
    //       if (this.products.length > 0) {
    //         if (this.products[0].product_type == 'food') {
    //           this.productTypes = this.productTypesProduct
    //         } else {
    //           this.productTypes = this.productTypesBevrage
    //         }
    //       }


    //     }
    //   },
    //   (err: any) => {
    //     this.showSimmer = false
    //     this.apiErrorHandler(err)
    //   }
    // )
  }
  // getBrands(type): void {
  //   if (this.searchData && type == 'search') {
  //     this.searchData.search !== ''
  //       ? (this.showSimmer = true)
  //       : (this.showSimmer = false)
  //     if (this.searchData.search.trim()) {
  //       this.searchData.page = 1
  //       this.searchMode = true
  //       this.searchData.search !== '' ? this.brandList('search') : ''
  //     }
  //   } else {
  //     // this.isLoadMore = true
  //     // this.resetButton = false
  //     // this.loadMoreDisable = true
  //     this.searchData.page = this.searchData.page + 1
  //     this.brandList('')
  //   }
  // }

}
