<div class="container">
    <div class="row mt-3">
        <div class="col-md-12 d-flex">
            <div class="">
                <h5 class="heads">Ethanol Plant CI Calculator</h5>
            </div>
        </div>
    </div>

    <div class="card">
        <h5 class="heads">Ethanol Plant Energy Use</h5>
        <table class="table2">
            <tbody>
                <tr class="head-tr">
                    <td class="border-right border-top-right head-border">
                        Ethanol Plant Energy Use <br><small>(California GREET &amp; DoE GREET 2021 Midwest Average values from Industry Surveys)</small>
                    </td>
                    <td class="border-right border-top-right-left head-border">
                        User Specified Value
                    </td>
                    <td class="border-right border-top-right-left head-border">
                        Grams /gallon
                    </td>
                    <td class="border-right border-top-right-left head-border">CI (g/MJ)</td>
                    <td class="border-right border-top-right-left head-border">
                        CI Change from GREET Default
                    </td>
                    <td class="border-right border-top-right-left head-border">
                        $/Gal.CI<br><small>Reduction Value</small>
                    </td>
                    <td class="border-left border-top-left head-border">
                        $/Acre CI<br><small>Reduction Value</small>
                    </td>
                </tr>
                <tr>
                    <td class="border-right border-bottom">Natural Gas (btu/gallon)</td>
                    <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.etoh_natural_gas" name="etoh_natural_gas" placeholder="24628" value="24628" (change)="calculateButton()"></td>
                    <td class="border-right border-bottom">{{model.etoh_natural_gas_grams}}</td>
                    <td class="border-right border-bottom">{{model.etoh_natural_gas_ci}}</td>
                    <td class="border-right border-bottom">{{model.etoh_natural_gas_ci_change}}</td>
                    <td class="border-right border-bottom">{{model.etoh_natural_gas_gal_value}}</td>
                    <td class="border-bottom"><span>$100</span></td>
                </tr>
                <tr>
                    <td class="border-right border-bottom">Electrical Energy (kWh/gallon)</td>
                    <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.electrical_energy" name="electrical_energy" required="" placeholder="0.63" value="0.63" (change)="calculateButton()"></td>
                    <td class="border-right border-bottom">{{model.electrical_energy_grams}}</td>
                    <td class="border-right border-bottom">{{model.electrical_energy_ci}}</td>
                    <td class="border-right border-bottom">{{model.electrical_energy_ci_change}}</td>
                    <td class="border-right border-bottom">{{model.electrical_energy_gal_value}}</td>
                    <td class="border-bottom"><span>$100</span></td>
                </tr>
                <tr>
                    <td class="border-right border-bottom-right"><strong>Ethanol Plant Energy use Totals</strong></td>
                    <td class="border-right border-bottom-right-left"><input type="number" [(ngModel)]="model.etoh_energy_total" name="etoh_energy_total" placeholder="1" value="1" (change)="calculateButton()"><br><small>Electricity Grid CI Multiplication Factor</small></td>
                    <td class="border-right border-bottom-right-left">{{model.etoh_energy_total_grams}}</td>
                    <td class="border-right border-bottom-right-left">{{model.etoh_energy_total_ci}}</td>
                    <td class="border-right border-bottom-right-left">{{model.etoh_energy_total_ci_change}}</td>
                    <td class="border-right border-bottom-right-left">{{model.etoh_energy_total_gal_value}}</td>
                    <td class="border-bottom-left"><span>$100</span></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="card">
        <h5 class="heads">Ethanol Plant Chemical Manufacture & Transportation Emissions</h5>
        <div class="div1">
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">
                            Ethanol Plant Chemical Manufacture &amp; Transportation Emissions <br><small>(California GREET &amp; DoE GREET 2021 Midwest Average values from Industry Surveys)</small>
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            User Specified Value
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            Grams /gallon
                        </td>
                        <td class="border-right border-top-right-left head-border">CI (g/MJ)</td>
                        <td class="border-right border-top-right-left head-border">
                            CI Change from GREET Default
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            $/Gal.CI<br><small>Reduction Value</small>
                        </td>
                        <td class="border-left border-top-left head-border">
                            $/Acre CI<br><small>Reduction Value</small>
                        </td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Alpha Amylase (grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.alpha_amylase" name="alpha_amylase" placeholder="330" value="330" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.alpha_amylase_grams}}</td>
                        <td class="border-right border-bottom">{{model.alpha_amylase_ci}}</td>
                        <td class="border-right border-bottom">{{model.alpha_amylase_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.alpha_amylase_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Gluco Amylase (grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.gluco_amylase" name="gluco_amylase" required="" placeholder="710" value="710" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.gluco_amylase_grams}}</td>
                        <td class="border-right border-bottom">{{model.gluco_amylase_ci}}</td>
                        <td class="border-right border-bottom">{{model.gluco_amylase_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.gluco_amylase_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Yeast (grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.yeast" name="yeast" required="" placeholder="360" value="360" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.yeast_grams}}</td>
                        <td class="border-right border-bottom">{{model.yeast_ci}}</td>
                        <td class="border-right border-bottom">{{model.yeast_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.yeast_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Sulfuric acid (H<sub>2</sub>SO<sub>4</sub>,grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.sulfuric_acid" name="sulfuric_acid" required="" placeholder="554" value="554" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.sulfuric_acid_grams}}</td>
                        <td class="border-right border-bottom">{{model.sulfuric_acid_ci}}</td>
                        <td class="border-right border-bottom">{{model.sulfuric_acid_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.sulfuric_acid_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Ammonia (NH<sub>3</sub>, grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.ammonia" name="ammonia" required="" placeholder="2123" value="2123" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.ammonia_grams}}</td>
                        <td class="border-right border-bottom">{{model.ammonia_ci}}</td>
                        <td class="border-right border-bottom">{{model.ammonia_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.ammonia_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">NaOH (grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.naoh" name="naoh" required="" placeholder="2663" value="2663" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.naoh_grams}}</td>
                        <td class="border-right border-bottom">{{model.naoh_ci}}</td>
                        <td class="border-right border-bottom">{{model.naoh_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.naoh_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">CaO (grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.cao" name="cao" required="" placeholder="1270" value="1270" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.cao_grams}}</td>
                        <td class="border-right border-bottom">{{model.cao_ci}}</td>
                        <td class="border-right border-bottom">{{model.cao_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.cao_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Urea (grams/dry ton corn)</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.urea_chem" name="urea_chem" required="" placeholder="0" value="0" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">{{model.urea_chem_grams}}</td>
                        <td class="border-right border-bottom">{{model.urea_chem_ci}}</td>
                        <td class="border-right border-bottom">{{model.urea_chem_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.urea_chem_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right"><strong>Ethanol Plant Chemical Total</strong></td>
                        <td class="border-right border-bottom-right-left"></td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_chemical_total_grams}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_chemical_total_ci}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_chemical_total_ci_change}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_chemical_total_gal_value}}</td>
                        <td class="border-bottom-left"><span>$100</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div2 mt-5">
            <h5 class="heads">Ethanol Transportation Emissions</h5>
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">
                            Ethanol Transportation Emissions <br><small>(California GREET values for Midwest Ethanol to California, GREET 2021 values for Midwest Ethanol, User Specified for Ethanol consumed)</small>
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            User Specified <br>Values for <br>Your Specific Destinations
                        </td>
                        <td class="border-right border-top-right-left head-border"></td>
                        <td class="border-right border-top-right-left head-border">
                            Grams /gallon
                        </td>
                        <td class="border-right border-top-right-left head-border">CI (g/MJ)</td>
                        <td class="border-right border-top-right-left head-border">
                            CI Change from GREET Default
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            $/Gal.CI<br><small>Reduction Value</small>
                        </td>
                        <td class="border-left border-top-left head-border">
                            $/Acre CI<br><small>Reduction Value</small>
                        </td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom"><strong>Ethanol Plant to Bulk Terminal</strong></td>
                        <td class="border-right border-bottom">Miles</td>
                        <td class="border-right border-bottom">%</td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-bottom"></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Barge Transport</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.etoh_barge" name="etoh_barge" required="" placeholder="520" value="520" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom d-flex"><input type="number" [(ngModel)]="model.etoh_barge_percent" name="etoh_barge_percent" required="" placeholder="20" value="20"  (change)="calculateButton()" style="width: 90%;"> %</td>
                        <td class="border-right border-bottom">{{model.etoh_barge_grams}}</td>
                        <td class="border-right border-bottom">{{model.etoh_barge_ci}}</td>
                        <td class="border-right border-bottom">{{model.etoh_barge_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.etoh_barge_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Rail Transport</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.etoh_rail" name="etoh_rail" required="" placeholder="0" value="0" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom d-flex"><input type="number" [(ngModel)]="model.etoh_rail_percent" name="etoh_rail_percent" required="" placeholder="20" value="20"  (change)="calculateButton()" style="width: 90%;"> %</td>
                        <td class="border-right border-bottom">{{model.etoh_rail_grams}}</td>
                        <td class="border-right border-bottom">{{model.etoh_rail_ci}}</td>
                        <td class="border-right border-bottom">{{model.etoh_rail_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.etoh_rail_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Truck Transport</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.etoh_truck" name="etoh_truck" required="" placeholder="80" value="80" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom d-flex"><input type="number" [(ngModel)]="model.etoh_truck_percent" name="etoh_truck_percent" required="" placeholder="60" value="60"  (change)="calculateButton()" style="width: 90%;"> %</td>
                        <td class="border-right border-bottom">{{model.etoh_truck_grams}}</td>
                        <td class="border-right border-bottom">{{model.etoh_truck_ci}}</td>
                        <td class="border-right border-bottom">{{model.etoh_truck_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.etoh_truck_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom"><strong>Bulk Terminal to Refueling Station</strong></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-right border-bottom"></td>
                        <td class="border-bottom"></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom">Truck Transport</td>
                        <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.bulk_truck" name="bulk_truck" required="" placeholder="30" value="30" (change)="calculateButton()"></td>
                        <td class="border-right border-bottom">100%</td>
                        <td class="border-right border-bottom">{{model.bulk_truck_grams}}</td>
                        <td class="border-right border-bottom">{{model.bulk_truck_ci}}</td>
                        <td class="border-right border-bottom">{{model.bulk_truck_ci_change}}</td>
                        <td class="border-right border-bottom">{{model.bulk_truck_gal_value}}</td>
                        <td class="border-bottom"><span>$100</span></td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right"><strong>Ethanol Transportation Totals</strong></td>
                        <td class="border-right border-bottom-right-left"></td>
                        <td class="border-right border-bottom-right-left"></td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_transportation_totals_grams}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_transportation_totals_ci}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_transportation_totals_ci_change}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_transportation_totals_gal_value}}</td>
                        <td class="border-bottom-left"><span>$100</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div3 div2 mt-5">
            <h5 class="heads">Denaturant Use Emissions</h5>
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border">
                            Denaturant Use Emissions
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            User Specified Value
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            Grams /gallon
                        </td>
                        <td class="border-right border-top-right-left head-border">CI (g/MJ)</td>
                        <td class="border-right border-top-right-left head-border">
                            CI Change from GREET Default
                        </td>
                        <td class="border-right border-top-right-left head-border">
                            $/Gal.CI<br><small>Reduction Value</small>
                        </td>
                        <td class="border-left border-top-left head-border">
                            $/Acre CI<br><small>Reduction Value</small>
                        </td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right">Denaturant inclusion rate</td>
                        <td class="border-right border-bottom-right-left d-flex"><input type="number" [(ngModel)]="model.denaturant" name="denaturant" required="" placeholder="2" value="2"  (change)="calculateButton()" style="width: 90%;"> %</td>
                        <td class="border-right border-bottom-right-left">{{model.denaturant_grams}}</td>
                        <td class="border-right border-bottom-right-left">{{model.denaturant_ci}}</td>
                        <td class="border-right border-bottom-right-left">{{model.denaturant_ci_change}}</td>
                        <td class="border-right border-bottom-right-left">{{model.denaturant_gal_value}}</td>
                        <td class="border-bottom-left">{{model.denaturant_acre_value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="div4 div2 mt-5">
            <h5 class="heads">SUBTOTAL: Ethanol Plant Energy, Chemicals, Transportation & Denaturant</h5>
            <table class="table2">
                <tbody>
                    <tr class="head-tr">
                        <td class="border-right border-top-right head-border"></td>
                        <td class="border-right border-top-right-left head-border"></td>
                        <td class="border-right border-top-right-left head-border"></td>
                        <td class="border-right border-top-right-left head-border">
                            $/Gal.CI<br><small>Reduction Value</small>
                        </td>
                        <td class="border-left border-top-left head-border">
                            $/Acre CI<br><small>Reduction Value</small>
                        </td>
                    </tr>
                    <tr>
                        <td class="border-right border-bottom-right"><strong>Ethanol Plant Energy, Chemicals, Transportation &amp; Den.</strong></td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_plant_totals_ci}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_plant_totals_ci_change}}</td>
                        <td class="border-right border-bottom-right-left">{{model.etoh_plant_totals_gal_value}}</td>
                        <td class="border-bottom-left">{{model.etoh_plant_totals_acre_value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="card">
        <h5 class="heads">Co-Product Credits</h5>
        <table class="table2">
            <tbody>
                <tr class="head-tr">
                    <td class="border-right border-top-right head-border">
                        Co-Product Credits
                    </td>
                    <td class="border-right border-top-right-left head-border">
                        User Specified Value
                    </td>
                    <td class="border-right border-top-right-left head-border">CI (g/MJ)</td>
                    <td class="border-right border-top-right-left head-border">
                        CI Change from GREET Default
                    </td>
                    <td class="border-right border-top-right-left head-border">
                        $/Gal.CI<br><small>Reduction Value</small>
                    </td>
                    <td class="border-left border-top-left head-border">
                        $/Acre CI<br><small>Reduction Value</small>
                    </td>
                </tr>
                <tr>
                    <td class="border-right border-bottom">Dry DGS Yield (dry lbs/gallon Ethanol)</td>
                    <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.dry_dgs" name="dry_dgs" placeholder="3.25" value="3.25" (change)="calculateButton()"></td>
                    <td class="border-right border-bottom">{{model.dry_dgs_ci}}</td>
                    <td class="border-right border-bottom">{{model.dry_dgs_ci_change}}</td>
                    <td class="border-right border-bottom">$100</td>
                    <td class="border-bottom">{{model.dry_dgs_gal_value}}</td>
                </tr>
                <tr>
                    <td class="border-right border-bottom">Wet DGS Yield (dry lbs/gallon Ethanol)</td>
                    <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.wet_dgs" name="wet_dgs" placeholder="1.36" value="1.36" (change)="calculateButton()"></td>
                    <td class="border-right border-bottom">{{model.wet_dgs_ci}}</td>
                    <td class="border-right border-bottom">{{model.wet_dgs_ci_change}}</td>
                    <td class="border-right border-bottom">$100</td>
                    <td class="border-bottom">{{model.wet_dgs_gal_value}}</td>
                </tr>
                <tr>
                    <td class="border-right border-bottom">Corn Oil Yield (lbs/gallon Ethanol)</td>
                    <td class="border-right border-bottom"><input type="number" [(ngModel)]="model.corn_oil" name="corn_oil" placeholder="0.27" value="0.27" (change)="calculateButton()"></td>
                    <td class="border-right border-bottom">{{model.corn_oil_ci}}</td>
                    <td class="border-right border-bottom">{{model.corn_oil_ci_change}}</td>
                    <td class="border-right border-bottom">$100</td>
                    <td class="border-bottom">{{model.corn_oil_gal_value}}</td>
                </tr>
                <tr>
                    <td class="border-right border-bottom-right"><strong>Co-Product Credit Totals</strong></td>
                    <td class="border-right border-bottom-right-left">{{model.coproduct_totals}}</td>
                    <td class="border-right border-bottom-right-left">{{model.coproduct_totals_ci}}</td>
                    <td class="border-right border-bottom-right-left">{{model.coproduct_totals_ci_change}}</td>
                    <td class="border-right border-bottom-right-left">$100</td>
                    <td class="border-bottom-left">{{model.coproduct_totals_gal_value}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="card">
        <h5 class="heads">International and Domestic Land Use Change Penalty</h5>
        <table class="table2">
            <tbody>
                <tr class="head-tr">
                    <td class="border-right border-top-right head-border"></td>
                    <td class="border-right border-top-right-left head-border"></td>
                    <td class="border-right border-top-right-left head-border">
                        CI Change from GREET Default
                    </td>
                    <td class="border-right border-top-right-left head-border">
                        $/Gal.CI<br><small>Reduction Value</small>
                    </td>
                    <td class="border-left border-top-left head-border">
                        $/Acre CI<br><small>Reduction Value</small>
                    </td>
                </tr>
                <tr>
                    <td class="border-right border-bottom-right">Indirect Land Use Change</td>
                    <td class="border-right border-bottom-right-left"><input type="number" class="" [(ngModel)]="model.indirect_ci" name="indirect_ci" required="" placeholder="7.38" value="7.38" (change)="calculateButton()"></td>
                    <td class="border-right border-bottom-right-left">{{model.indirect_ci_change}}</td>
                    <td class="border-right border-bottom-right-left">{{model.indirect_gal_value}}</td>
                    <td class="border-bottom-left">{{model.indirect_acre_value}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="card">
        <h5 class="heads">Credit for Carbon Capture and Sequestration</h5>
        <table class="table2">
            <tbody>
                <tr class="head-tr">
                    <td class="border-right border-top-right head-border"></td>
                    <td class="border-right border-top-right-left head-border"></td>
                    <td class="border-right border-top-right-left head-border">
                        $/Gal.CI<br><small>Reduction Value</small>
                    </td>
                    <td class="border-left border-top-left head-border">
                        $/Acre CI<br><small>Reduction Value</small>
                    </td>
                </tr>
                <tr>
                    <td class="border-right border-bottom-right">Ethanol Plant Fermentation CO<sub>2</sub> Capture, Transportation, and Sequestration</td>
                    <td class="border-right border-bottom-right-left"><input type="number" class="" [(ngModel)]="model.carbon_capture" name="carbon_capture" required="" placeholder="0" value="0" (change)="calculateButton()"></td>
                    <td class="border-right border-bottom-right-left">{{model.carbon_capture_gal_value}}</td>
                    <td class="border-bottom-left">{{model.carbon_capture_acre_value}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
