import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { Observable } from 'rxjs/internal/Observable'
import { environment } from '../../environments/environment'
import { of, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import constApi from 'src/assets/json/endpoint.json'
import { AlertService } from '../_services'
import { HomeService } from '../user/home/home.service'

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  isAgeVerify = false
  options: any
  encryptedData: any
  api: any
  mapApi: any
  constructor(private http: HttpClient, private alertService: AlertService, private homeService: HomeService) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    this.options = { headers }
    this.api = constApi.productApi
    this.mapApi = constApi.googleApi
  }
  updateToken(): void {
    this.encryptedData = localStorage.getItem('userInfo')
    if (this.encryptedData) {
      const user: any = JSON.parse(
        CryptoJS.AES.decrypt(
          this.encryptedData,
          environment.secretKey
        ).toString(CryptoJS.enc.Utf8)
      )
      const headers: any = new HttpHeaders({
        Authorization: user.tokenType + ' ' + user.accessToken,
      })
      this.options = { headers }
    }
  }
  getLatLong(addres): Observable<any> {
    if (!addres) {
      addres = "";
    }
    return this.http.get(
      this.mapApi.geoApi +
      addres +
      "&result_type=postal_code  &key=" + environment.googleApiKey
    );
  }

  list(data: any): Observable<any> {
    this.updateToken()
    !data.search ? (data.search = '') : data.search
    // (data.product_type = '')
    let endPoint: any
    !this.encryptedData
      ? (endPoint = this.api.productList)
      : (endPoint = this.api.customerProductList)
    return this.http.get(
      this.api.baseUrl +
      endPoint +
      data.limit +
      '&page=' +
      data.page +
      '&search_value=' +
      data.search.trim() +
      '&brands=' +
      data.brands +
      '&product_type=' +
      data.product_type,
      this.options
    )
  }
  getNewRestaurants(data) {
    this.updateToken()
    if(data.latitude && data.longitude) {
      return this.http.get(
        this.api.baseUrl +
        this.api.restaurant +
        '?latitude=' +
        data.latitude +
        '&longitude=' +
        data.longitude,
        this.options
      ).pipe(
        catchError((err) => {
          this.errorHandler(err)
          return of(null)
        })
      )
    } else {
      return this.http.get(
        this.api.baseUrl +
        this.api.restaurant +
        '?latitude=41.850033&longitude=-87.6500523',
        this.options
      ).pipe(
        catchError((err) => {
          this.errorHandler(err)
          return of(null)
        })
      )
    }
    
  }

  nearestRestaurantList(data: any, searchData: any): Observable<any> {
    this.updateToken()
    !searchData.search ? (searchData.search = '') : (searchData.product_type = '')
    https://kn58y8m3m8.execute-api.us-east-1.amazonaws.com/dev/aroundBrands/
    if(data.latitude && data.longitude) {
      return this.http.get(
        this.api.baseUrl + 'aroundBrands/' +
        '?size=' +
        searchData.limit +
        '&page=' +
        searchData.page +
        '&name=' +
        searchData.search.trim() +
        '&brands=' +
        searchData.brands +
        '&product_type=' +
        searchData.product_type +
        '&latitude=' +
        data.latitude +
        '&longitude=' +
        data.longitude,
        this.options
      ).pipe(
        catchError((err) => {
          this.errorHandler(err)
          return of(null)
        })
      )
    } else {
      return this.http.get(
        this.api.baseUrl + 'aroundBrands/' +
        '?size=' +
        searchData.limit +
        '&page=' +
        searchData.page +
        '&name=' +
        searchData.search.trim() +
        '&brands=' +
        searchData.brands +
        '&product_type=' +
        searchData.product_type +
        '&latitude=41.850033&longitude=-87.6500523',
        this.options
      ).pipe(
        catchError((err) => {
          this.errorHandler(err)
          return of(null)
        })
      )
    }
    

  }

  detail(productId: number): Observable<any> {
    this.updateToken()
    return this.http.get(
      this.api.baseUrl + this.api.product + productId + '/',
      this.options
    )
  }
  /**
   * This function is use to track product
   * @param data 
   * @returns 
   */
  trackProduct(data: any): Observable<any> {
    let sendData : any = {}
    data.is_shared ? sendData.is_shared = '1' : '0';
    data.tabId ? sendData.tabs = JSON.stringify(data.tabId) : '';
    sendData.is_web = '1';
    data.device_type ? sendData.device_type = data.device_type : ''
    data.device_unique_id ? sendData.device_unique_id = data.device_unique_id : ''
    data.zipCode ? sendData.zip_code = data.zipCode : ''
    data.videoPlaybackTime ? sendData.watch_duration = data.videoPlaybackTime : ''
    data.url ? sendData.url = data.url : ''
    data.country ? sendData.country = data.country : ''
    data.state ? sendData.state = data.state : ''
    data.city ? sendData.city = data.city : ''
    data.latitude ? sendData.latitude = data.latitude : ''
    data.longitude ? sendData.longitude = data.longitude : ''
    sendData.scan_type = 'third_party'
    return this.http.post(
      this.api.baseUrl + 'customer/productVideo/analytic/',
      sendData,
      this.options
    )
  }

  brandDeatil(data: any): Observable<any> {
    this.updateToken()
    return this.http.get(
      this.api.baseUrl + 'brand/' + data.brandId,
      this.options
    )
  }
  brandMenuList(data: any): Observable<any> {
    this.updateToken()
    return this.http.get(
      this.api.baseUrl + this.api.brandMenu + '?brands=' + data.brandId,
      this.options
    ).pipe(
      catchError((err) => {
        this.errorHandler(err)
        return of(null)
      })
    )
  }
  getSelectedMenuData(data: any): Observable<any> {
    this.updateToken()
    this.updateToken()
    return this.http.get(
      this.api.baseUrl + this.api.brand + data.brandId + '/detail/brandMenu/' + data.brandMenuId,
      { ...this.options, responseType: 'text' }
    ).pipe(
      catchError((err) => {
        this.errorHandler(err)
        return of(null)
      })
    )
  }
  getSelectedSubMenuData(data: any): Observable<any> {
    this.updateToken()
    return this.http.get(
      this.api.baseUrl + this.api.brand + data.brandId + '/menu/' + data.brandMenuId + '/subMenu/' + data.subMenuId,
      this.options
    ).pipe(
      catchError((err) => {
        this.errorHandler(err)
        return of(null)
      })
    )
  }


  trueFavorite(productId: number) {
    this.updateToken()
    return this.http.post(
      this.api.baseUrl + this.api.product + productId + this.api.favorite,
      '',
      this.options
    )
  }
  trueRestaurantFavourite(productId: number) {
    this.updateToken()
    return this.http.post(
      this.api.baseUrl + this.api.brands + productId + this.api.favorite,
      '',
      this.options
    )
  }

  falseFavorite(productId: number) {
    this.updateToken()
    return this.http.delete(
      this.api.baseUrl + this.api.product + productId + this.api.favorite,
      this.options
    )
  }
  falseRestaurantFavourite(productId: number) {
    this.updateToken()
    return this.http.delete(
      this.api.baseUrl + this.api.brands + productId + this.api.favorite,
      this.options
    )
  }

  feedBack(data: any): Observable<any> {
    const productId = data.productId
    const inputData = {
      description: data.message,
    }
    return this.http.post(
      this.api.baseUrl + this.api.product + productId + this.api.feedback,
      inputData,
      this.options
    )
  }

  brandList(type: any): Observable<any> {
    return this.http.get(this.api.baseUrl + this.api.brands + '?brand_type=' + type)
  }
  getAllProducts(): Observable<any> {
    return this.http.get(this.api.baseUrl + this.api.allProducts)
  }
  trueFavoriteRecipe(recipeId: number) {
    this.updateToken()
    return this.http.post(
      this.api.baseUrl + this.api.recipes + recipeId + this.api.favorite,
      '',
      this.options
    )
  }

  falseFavoriteRecipe(recipeId: number) {
    this.updateToken()
    return this.http.delete(
      this.api.baseUrl + this.api.recipes + recipeId + this.api.favorite,
      this.options
    )
  }

  trueFavoriteCoupon(couponId: number, productId: number) {
    const inputData = {
      product_id: productId,
    }
    this.updateToken()
    return this.http.post(
      this.api.baseUrl + this.api.coupon + couponId + this.api.favorite,
      inputData,
      this.options
    )
  }

  falseFavoriteCoupon(couponId: number) {
    this.updateToken()
    return this.http.delete(
      this.api.baseUrl + this.api.coupon + couponId + this.api.favorite,
      this.options
    )
  }

  recipeRetail(recipeId: number) {
    return this.http.get(this.api.baseUrl + this.api.recipe + recipeId + '/')
  }

  couponRetail(couponId: number) {
    return this.http.get(this.api.baseUrl + this.api.coupon + couponId + '/')
  }

  recipeDetail(recipeId: number): Observable<any> {
    this.updateToken()
    return this.http.get(
      this.api.baseUrl + this.api.recipe + recipeId + '/',
      this.options
    )
  }

  couponDetail(couponId: number, productId: number): Observable<any> {
    this.updateToken()
    return this.http.get(
      this.api.baseUrl + this.api.coupon + couponId + '/' + productId + '/',
      this.options
    )
  }
  /**
   * This function is use for send feedback.
   * @param data 
   * @returns 
   */
  sendFeedback(data) {
    this.updateToken()
    let body: any = {}
    data.rating ? body.rating = data.rating : ""
    data.consFeedback ? body.cons_feedback = data.consFeedback : ""
    data.prosFeedback ? body.pros_feedback = data.prosFeedback : ""
    return this.http.post(
      this.api.baseUrl + 'v2/products/' + data.id + this.api.feedback,
      body,
      this.options
    ).pipe(
      catchError((err) => {
        this.errorHandler(err)
        return of(null)
      })
    )
  }


  productScanCount(data: any): Observable<any> {
    this.updateToken()
    !data.country
      ? delete data.country
      : !data.state
        ? delete data.state
        : !data.city
          ? delete data.city
          : !data.zip_code
            ? delete data.zip_code
            : !data.device_type
              ? delete data.device_type
              : !data.latitude
                ? delete data.latitude
                : !data.longitude
                  ? delete data.longitude
                  : !data.device_unique_id
                    ? delete data.device_unique_id
                    : ''
    return this.http.post(
      this.api.baseUrl + this.api.customerProductScanCount,
      data,
      this.options
    )
  }
  getAddress(): Observable<any> {
    return this.http
      .get('https://freegeoip.app/json/')
      .pipe(
        catchError((err) => {
          this.handleError()
          return of(null)
        })
      )

  }
  private handleError() {
    return throwError('Something bad happened; please try again later.')
  }
  errorHandler(error: any): any {
    this.alertService.error(error.error.error ? error.error.error : 'Something went wrong')
    this.homeService.log_out(error.status)
    return throwError(error)
  }
}
