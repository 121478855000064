import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from '../_services/index'
import { LoginService } from '../login/login.service'
import * as CryptoJS from 'crypto-js'
import { environment } from '../../environments/environment'
import { ContactUsModalComponent } from '../user/home/contact-us-modal/contact-us-modal.component'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  model: any = {}
  btnDisable: boolean
  confirmPasswordType: any = 'password'
  passwordType: any = 'password'
  forgotInfo: any = {}
  resendBtnDisable = false
  otpCorrectValidation = true
  otpRequiredValidation = true
  pageInfo: any = {}
  appConstant: any
  constructor(
    private router: Router,
    private alertService: AlertService,
    private loginService: LoginService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.appConstant = environment
    this.pageInfo = JSON.parse(localStorage.getItem('contentInfo'))
    const forgotInfo = localStorage.getItem('forgotInfo')
    forgotInfo
      ? this.router.navigate(['/reset-password'])
      : this.router.navigate(['/forgot-password'])
  }

  resetPassword(valid: any): void {
    if (
      Number(this.model.otp_one) &&
      Number(this.model.otp_two) &&
      Number(this.model.otp_three) &&
      Number(this.model.otp_four)
    ) {
      this.otpRequiredValidation = true
      this.otpCorrectValidation = true
      if (valid) {
        this.btnDisable = true
        this.forgotInfo = localStorage.getItem('forgotInfo')
        this.forgotInfo = JSON.parse(
          CryptoJS.AES.decrypt(this.forgotInfo, environment.secretKey).toString(
            CryptoJS.enc.Utf8
          )
        )
        this.model.public_id = this.forgotInfo.publicId
        this.model.otp = this.model.otp_one.concat(
          this.model.otp_two,
          this.model.otp_three,
          this.model.otp_four
        )
        this.model.otp = Number(this.model.otp)
        this.loginService.resetPassword(this.model).subscribe(
          (data: any) => {
            if(data){
              this.router.navigate(['/login'])
              this.alertService.success(data.message)
            }
            this.btnDisable = false
          }
        )
      }
    } else if (
      !this.model.otp_one &&
      !this.model.otp_two &&
      !this.model.otp_three &&
      !this.model.otp_four
    ) {
      this.otpRequiredValidation = false
      this.otpCorrectValidation = true
    } else {
      this.otpCorrectValidation = false
      this.otpRequiredValidation = true
    }
  }

  changeConfirmPasswordType(): void {
    this.confirmPasswordType === 'password'
      ? (this.confirmPasswordType = 'text')
      : (this.confirmPasswordType = 'password')
  }

  changePasswordType(): void {
    this.passwordType === 'password'
      ? (this.passwordType = 'text')
      : (this.passwordType = 'password')
  }

  resendOtp(): void {
    this.resendBtnDisable = true
    this.forgotInfo = localStorage.getItem('forgotInfo')
    this.forgotInfo = JSON.parse(
      CryptoJS.AES.decrypt(this.forgotInfo, environment.secretKey).toString(
        CryptoJS.enc.Utf8
      )
    )
    this.model.email = this.forgotInfo.email
    this.model.userType = 'customer'
    this.loginService.forGotPassword(this.model).subscribe(
      (data: any) => {
        this.resendBtnDisable = false
        this.alertService.success(data.message)
      },
      (err: any) => {
        this.resendBtnDisable = false
        if (err.error.error) {
          this.alertService.error(err.error.error)
        }
      }
    )
  }

  gotoNextField(event, nextElement, prevElement) {
    const key = event.key
    if (key === 'Backspace') {
      document.getElementById(prevElement).focus()
      this.otpRequiredValidation = true

      if (
        !this.model.otp_one &&
        !this.model.otp_two &&
        !this.model.otp_three &&
        !this.model.otp_four
      ) {
        this.otpRequiredValidation = false
      }
    } else {
      document.getElementById(nextElement).focus()
      this.otpRequiredValidation = true
    }
  }

  

contactUsDialog() {
    const dialogRef = this.dialog.open(ContactUsModalComponent, {
      width: '670px',
      panelClass: "contact-us-dialog"
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        
      }
    });
  }
}
