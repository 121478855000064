import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plant-ciscore',
  templateUrl: './plant-ciscore.component.html',
  styleUrls: ['./plant-ciscore.component.scss']
})
export class PlantCiscoreComponent implements OnInit {

  model:any ={
    etoh_natural_gas:24628,
    etoh_natural_gas_grams:'',
    etoh_natural_gas_ci:'',
    etoh_natural_gas_ci_change:'',
    etoh_natural_gas_gal_value:'',
    electrical_energy:0.63,
    electrical_energy_grams:'',
    electrical_energy_ci:'',
    electrical_energy_ci_change:'',
    electrical_energy_gal_value:'',
    etoh_energy_total:1,
    etoh_energy_total_grams:'',
    etoh_energy_total_ci:'',
    etoh_energy_total_ci_change:'',
    etoh_energy_total_gal_value:'',
    alpha_amylase:330,
    alpha_amylase_grams:'',
    alpha_amylase_ci:'',
    alpha_amylase_ci_change:'',
    alpha_amylase_gal_value:'',
    gluco_amylase:710,
    gluco_amylase_grams:'',
    gluco_amylase_ci:'',
    gluco_amylase_ci_change:'',
    gluco_amylase_gal_value:'',
    yeast:360,
    yeast_grams:'',
    yeast_ci:'',
    yeast_ci_change:'',
    yeast_gal_value:'',
    sulfuric_acid:554,
    sulfuric_acid_grams:'',
    sulfuric_acid_ci:'',
    sulfuric_acid_ci_change:'',
    sulfuric_acid_gal_value:'',
    ammonia:2123,
    ammonia_grams:'',
    ammonia_ci:'',
    ammonia_ci_change:'',
    ammonia_gal_value:'',
    naoh:2663,
    naoh_grams:'',
    naoh_ci:'',
    naoh_ci_change:'',
    naoh_gal_value:'',
    cao:1270,
    cao_grams:'',
    cao_ci:'',
    cao_ci_change:'',
    cao_gal_value:'',
    urea_chem:0,
    urea_chem_grams:'',
    urea_chem_ci:'',
    urea_chem_ci_change:'',
    urea_chem_gal_value:'',
    etoh_chemical_total_grams:'',
    etoh_chemical_total_ci:'',
    etoh_chemical_total_ci_change:'',
    etoh_chemical_total_gal_value:'',
    etoh_barge:520,
    etoh_barge_percent:20,
    etoh_barge_grams:'',
    etoh_barge_ci:'',
    etoh_barge_ci_change:'',
    etoh_barge_gal_value:'',
    etoh_rail:0,
    etoh_rail_percent:20,
    etoh_rail_grams:'',
    etoh_rail_ci:'',
    etoh_rail_ci_change:'',
    etoh_rail_gal_value:'',
    etoh_truck:80,
    etoh_truck_percent:60,
    etoh_truck_grams:'',
    etoh_truck_ci:'',
    etoh_truck_ci_change:'',
    etoh_truck_gal_value:'',
    bulk_truck:30,
    bulk_truck_grams:'',
    bulk_truck_ci:'',
    bulk_truck_ci_change:'',
    bulk_truck_gal_value:'',
    etoh_transportation_totals_grams:'',
    etoh_transportation_totals_ci:'',
    etoh_transportation_totals_ci_change:'',
    etoh_transportation_totals_gal_value:'',
    denaturant:2,
    denaturant_grams:'',
    denaturant_ci:'',
    denaturant_ci_change:'',
    denaturant_gal_value:'',
    denaturant_acre_value:'',
    etoh_plant_totals_ci:'',
    etoh_plant_totals_ci_change:'',
    etoh_plant_totals_gal_value:'',
    etoh_plant_totals_acre_value:'',
    dry_dgs:3.25,
    dry_dgs_ci:'',
    dry_dgs_ci_change:'',
    dry_dgs_gal_value:'',
    wet_dgs:1.36,
    wet_dgs_ci:'',
    wet_dgs_ci_change:'',
    wet_dgs_gal_value:'',
    corn_oil:0.27,
    corn_oil_ci:'',
    corn_oil_ci_change:'',
    corn_oil_gal_value:'',
    coproduct_totals:'',
    coproduct_totals_ci:'',
    coproduct_totals_ci_change:'',
    coproduct_totals_gal_value:'',
    indirect_ci:7.38,
    indirect_ci_change:'',
    indirect_gal_value:'',
    indirect_acre_value:'',
    carbon_capture:0,
    carbon_capture_gal_value:'',
    carbon_capture_acre_value:'',
  }

  constructor() { }

  ngOnInit(): void {
    this.calculateButton();
  }

  whole_number(e){return Math.round(e+Number.EPSILON)};
  one_decimal(e){return+e.toFixed(1)}
  two_decimals(e){return Math.round(100*(e+Number.EPSILON))/100}
  three_decimals(e){return Math.round(1e3*(e+Number.EPSILON))/1e3}
  four_decimals(e){return Math.round(1e4*(e+Number.EPSILON))/1e4}
  get_gal_value(e) {
    const carbon_credit_per_ton= 100.00;
    return e*(carbon_credit_per_ton/1e6)*80.5324*-1;
  }

  calculateButton() {
    // Ethanol Plant Energy Use
    const etoh_natural_gas = parseFloat(this.model.etoh_natural_gas) || 0;
    const electrical_energy = this.two_decimals(parseFloat(this.model.electrical_energy)) || 0;
    const etoh_energy_total = this.two_decimals(parseFloat(this.model.etoh_energy_total)) || 0;
    const doe_etoh_natural_gas_ci = 22.2;
    const doe_electrical_energy_ci = 2.95;
    const doe_etoh_energy_total_ci = 25.2;

    const etoh_natural_gas_grams = etoh_natural_gas * 0.0725931;
    const etoh_natural_gas_ci = this.one_decimal(etoh_natural_gas_grams/80.5324);
    const etoh_natural_gas_ci_change = this.two_decimals(etoh_natural_gas_ci-doe_etoh_natural_gas_ci);
    const etoh_natural_gas_gal_value = this.get_gal_value(etoh_natural_gas_ci_change);
    const electrical_energy_grams = this.four_decimals(electrical_energy*(377.35*etoh_energy_total));
    const electrical_energy_ci = this.two_decimals(electrical_energy_grams/80.5324);
    const electrical_energy_ci_change = this.two_decimals(electrical_energy_ci-doe_electrical_energy_ci);
    const electrical_energy_gal_value= this.get_gal_value(electrical_energy_ci_change);
    const etoh_energy_total_grams= this.whole_number(etoh_natural_gas_grams+electrical_energy_grams);
    const etoh_energy_total_ci= this.one_decimal(etoh_natural_gas_ci+electrical_energy_ci);
    const etoh_energy_total_ci_change= this.two_decimals(etoh_energy_total_ci-doe_etoh_energy_total_ci);
    const etoh_energy_total_gal_value= this.get_gal_value(etoh_energy_total_ci_change);

    this.model.etoh_natural_gas_grams = this.whole_number(etoh_natural_gas_grams);
    this.model.etoh_natural_gas_ci = etoh_natural_gas_ci;
    this.model.etoh_natural_gas_ci_change = etoh_natural_gas_ci_change;
    this.model.etoh_natural_gas_gal_value = "$"+ this.two_decimals(etoh_natural_gas_gal_value);
    this.model.electrical_energy_grams = electrical_energy_grams;
    this.model.electrical_energy_ci = electrical_energy_ci;
    this.model.electrical_energy_ci_change = electrical_energy_ci_change;
    this.model.electrical_energy_gal_value = "$"+ this.two_decimals(electrical_energy_gal_value);
    this.model.etoh_energy_total_grams = etoh_energy_total_grams;
    this.model.etoh_energy_total_ci = etoh_energy_total_ci;
    this.model.etoh_energy_total_ci_change = etoh_energy_total_ci_change;
    this.model.etoh_energy_total_gal_value = "$"+ this.two_decimals(etoh_energy_total_gal_value);


    //Ethanol Plant Chemical Manufacture & Transportation Emissions
    const alpha_amylase = this.two_decimals(parseFloat(this.model.alpha_amylase)) || 0;
    const gluco_amylase = this.two_decimals(parseFloat(this.model.gluco_amylase)) || 0;
    const yeast = this.two_decimals(parseFloat(this.model.yeast)) || 0;
    const sulfuric_acid = this.two_decimals(parseFloat(this.model.sulfuric_acid)) || 0;
    const ammonia = this.two_decimals(parseFloat(this.model.ammonia)) || 0;
    const naoh = this.two_decimals(parseFloat(this.model.naoh)) || 0;
    const cao = this.two_decimals(parseFloat(this.model.cao)) || 0;
    const urea_chem = this.two_decimals(parseFloat(this.model.urea_chem)) || 0;
    const doe_alpha_amylase_ci = 0.047;
    const doe_gluco_amylase_ci = 0.46;
    const doe_yeast_ci = 0.092;
    const doe_sulfuric_acid_ci = 0.003;
    const doe_ammonia_ci = 0.59;
    const doe_naoh_ci = 0.65;
    const doe_cao_ci = 0.17;
    const doe_urea_chem_ci = 0;
    const doe_etoh_chemical_total_ci = 2.01;

    const alpha_amylase_grams= this.one_decimal(.01149*alpha_amylase);
    const alpha_amylase_ci= this.two_decimals(alpha_amylase_grams/80.5324);
    const alpha_amylase_ci_change = this.two_decimals(alpha_amylase_ci-this.two_decimals(doe_alpha_amylase_ci));
    const alpha_amylase_gal_value= this.get_gal_value(alpha_amylase_ci_change);
    const gluco_amylase_grams= this.one_decimal(.05223*gluco_amylase);
    const gluco_amylase_ci= this.two_decimals(gluco_amylase_grams/80.5324);
    const gluco_amylase_ci_change= this.two_decimals(gluco_amylase_ci-this.two_decimals(doe_gluco_amylase_ci));
    const gluco_amylase_gal_value= this.get_gal_value(gluco_amylase_ci_change);
    const yeast_grams= this.one_decimal(.02047*yeast);
    const yeast_ci= this.two_decimals(yeast_grams/80.5324);
    const yeast_ci_change = this.two_decimals(yeast_ci-this.two_decimals(doe_yeast_ci));
    const yeast_gal_value= this.get_gal_value(yeast_ci_change);
    const sulfuric_acid_grams= this.one_decimal(3924e-7*sulfuric_acid);
    const sulfuric_acid_ci= this.two_decimals(sulfuric_acid_grams/80.5324);
    const sulfuric_acid_ci_change = this.two_decimals(sulfuric_acid_ci-this.two_decimals(doe_sulfuric_acid_ci));
    const sulfuric_acid_gal_value= this.get_gal_value(sulfuric_acid_ci_change);
    const ammonia_grams= this.two_decimals(.0222*ammonia);
    const ammonia_ci= this.two_decimals(ammonia_grams/80.5324);
    const ammonia_ci_change= this.two_decimals(ammonia_ci- this.two_decimals(doe_ammonia_ci));
    const ammonia_gal_value= this.get_gal_value(ammonia_ci_change);
    const naoh_grams= this.one_decimal(.01978*naoh);
    const naoh_ci= this.two_decimals(naoh_grams/80.5324);
    const naoh_ci_change= this.two_decimals(naoh_ci- this.two_decimals(doe_naoh_ci));
    const naoh_gal_value= this.get_gal_value(naoh_ci_change);
    const cao_grams= this.one_decimal(.01078*cao);
    const cao_ci= this.two_decimals(cao_grams/80.5324);
    const cao_ci_change= this.two_decimals(cao_ci- this.two_decimals(doe_cao_ci));
    const cao_gal_value= this.get_gal_value(cao_ci_change);
    const urea_chem_grams= this.one_decimal(.015907*urea_chem);
    const urea_chem_ci= this.two_decimals(urea_chem_grams/80.5324);
    const urea_chem_ci_change= this.two_decimals(urea_chem_ci- this.two_decimals(doe_urea_chem_ci));
    const urea_chem_gal_value= this.get_gal_value(urea_chem_ci_change);
    const etoh_chemical_total_grams= this.one_decimal(alpha_amylase_grams+gluco_amylase_grams+yeast_grams+sulfuric_acid_grams+ammonia_grams+naoh_grams+cao_grams);
    const etoh_chemical_total_ci= this.two_decimals(alpha_amylase_ci+gluco_amylase_ci+yeast_ci+sulfuric_acid_ci+ammonia_ci+naoh_ci+cao_ci);
    const etoh_chemical_total_ci_change= this.two_decimals(etoh_chemical_total_ci-doe_etoh_chemical_total_ci);
    const etoh_chemical_total_gal_value= this.get_gal_value(etoh_chemical_total_ci_change);

    this.model.alpha_amylase_grams = alpha_amylase_grams;
    this.model.alpha_amylase_ci = alpha_amylase_ci;
    this.model.alpha_amylase_ci_change = alpha_amylase_ci_change;
    this.model.alpha_amylase_gal_value = "$"+ this.two_decimals(alpha_amylase_gal_value);
    this.model.gluco_amylase_grams = gluco_amylase_grams;
    this.model.gluco_amylase_ci = gluco_amylase_ci;
    this.model.gluco_amylase_ci_change = gluco_amylase_ci_change;
    this.model.gluco_amylase_gal_value = "$"+ this.two_decimals(gluco_amylase_gal_value);
    this.model.yeast_grams = yeast_grams;
    this.model.yeast_ci = yeast_ci;
    this.model.yeast_ci_change = yeast_ci_change;
    this.model.yeast_gal_value = "$"+ this.two_decimals(yeast_gal_value);
    this.model.sulfuric_acid_grams = sulfuric_acid_grams;
    this.model.sulfuric_acid_ci = sulfuric_acid_ci;
    this.model.sulfuric_acid_ci_change = sulfuric_acid_ci_change;
    this.model.sulfuric_acid_gal_value = "$"+ this.two_decimals(sulfuric_acid_gal_value);
    this.model.ammonia_grams = ammonia_grams;
    this.model.ammonia_ci = ammonia_ci;
    this.model.ammonia_ci_change = ammonia_ci_change;
    this.model.ammonia_gal_value = "$"+ this.two_decimals(ammonia_gal_value);
    this.model.naoh_grams = naoh_grams;
    this.model.naoh_ci = naoh_ci;
    this.model.naoh_ci_change = naoh_ci_change;
    this.model.naoh_gal_value = "$"+ this.two_decimals(naoh_gal_value);
    this.model.cao_grams = cao_grams;
    this.model.cao_ci = cao_ci;
    this.model.cao_ci_change = cao_ci_change;
    this.model.cao_gal_value = "$"+ this.two_decimals(cao_gal_value);
    this.model.urea_chem_grams = urea_chem_grams;
    this.model.urea_chem_ci = urea_chem_ci;
    this.model.urea_chem_ci_change = urea_chem_ci_change;
    this.model.urea_chem_gal_value = "$"+ this.two_decimals(urea_chem_gal_value);
    this.model.etoh_chemical_total_grams = etoh_chemical_total_grams;
    this.model.etoh_chemical_total_ci = etoh_chemical_total_ci;
    this.model.etoh_chemical_total_ci_change = etoh_chemical_total_ci_change;
    this.model.etoh_chemical_total_gal_value = "$"+ this.two_decimals(etoh_chemical_total_gal_value);


    //Ethanol Transportation Emissions
    const etoh_barge = this.two_decimals(parseFloat(this.model.etoh_barge)) || 0;
    const etoh_barge_percent = this.two_decimals(parseFloat(this.model.etoh_barge_percent)/100) || 0;
    const etoh_rail = this.two_decimals(parseFloat(this.model.etoh_rail)) || 0;
    const etoh_rail_percent = this.two_decimals(parseFloat(this.model.etoh_rail_percent)/100) || 0;
    const etoh_truck = this.two_decimals(parseFloat(this.model.etoh_truck)) || 0;
    const etoh_truck_percent = this.two_decimals(parseFloat(this.model.etoh_truck_percent)/100) || 0;
    const bulk_truck = this.two_decimals(parseFloat(this.model.bulk_truck)) || 0;
    
    const doe_etoh_barge_percent=13.2/100
    const doe_etoh_rail_percent=this.two_decimals(78.9/100);
    const doe_etoh_truck_percent= this.two_decimals(7.9/100);
    const doe_etoh_transportation_totals_ci= this.two_decimals(1);

    const etoh_barge_grams= this.two_decimals(etoh_barge*(.141518328787879*doe_etoh_barge_percent)*etoh_barge_percent)
    const etoh_barge_ci= this.two_decimals(etoh_barge_grams/80.5324);
    const etoh_barge_ci_change= this.two_decimals(etoh_barge_ci- this.two_decimals(parseFloat(0.12.toString())));
    const etoh_barge_gal_value= this.get_gal_value(etoh_barge_ci_change);
    const etoh_rail_grams= this.two_decimals(etoh_rail*(.0863873300316857*doe_etoh_rail_percent)*etoh_rail_percent)
    const etoh_rail_ci= this.two_decimals(etoh_rail_grams/80.5324);
    const etoh_rail_ci_change= this.two_decimals(etoh_rail_ci- this.two_decimals(parseFloat(0.68.toString())));
    const etoh_rail_gal_value= this.get_gal_value(etoh_rail_ci_change);
    const etoh_truck_grams= this.two_decimals(etoh_truck*(.439105459493671*doe_etoh_truck_percent)*etoh_truck_percent);
    const etoh_truck_ci= this.two_decimals(etoh_truck_grams/80.5324);
    const etoh_truck_ci_change= this.two_decimals(etoh_truck_ci- this.two_decimals(parseFloat(0.03.toString())));
    const etoh_truck_gal_value= this.get_gal_value(etoh_truck_ci_change);
    const bulk_truck_grams= this.two_decimals(.439116333066667*bulk_truck);
    const bulk_truck_ci= this.two_decimals(bulk_truck_grams/80.5324);
    const bulk_truck_ci_change= this.two_decimals(bulk_truck_ci- this.two_decimals(parseFloat(0.16.toString())));
    const bulk_truck_gal_value= this.get_gal_value(bulk_truck_ci_change);
    const etoh_transportation_totals_grams= this.two_decimals(etoh_barge_grams+etoh_rail_grams+etoh_truck_grams+bulk_truck_grams);
    const etoh_transportation_totals_ci= this.two_decimals(etoh_barge_ci+etoh_rail_ci+etoh_truck_ci+bulk_truck_ci);
    const etoh_transportation_totals_ci_change= this.two_decimals(etoh_transportation_totals_ci-doe_etoh_transportation_totals_ci);
    const etoh_transportation_totals_gal_value= this.get_gal_value(etoh_transportation_totals_ci_change);

    this.model.etoh_barge_grams = etoh_barge_grams;
    this.model.etoh_barge_ci = etoh_barge_ci;
    this.model.etoh_barge_ci_change = etoh_barge_ci_change;
    this.model.etoh_barge_gal_value = "$"+ this.two_decimals(etoh_barge_gal_value);
    this.model.etoh_rail_grams = etoh_rail_grams;
    this.model.etoh_rail_ci = etoh_rail_ci;
    this.model.etoh_rail_ci_change = etoh_rail_ci_change;
    this.model.etoh_rail_gal_value = "$"+ this.two_decimals(etoh_rail_gal_value);
    this.model.etoh_truck_grams = etoh_truck_grams;
    this.model.etoh_truck_ci = etoh_truck_ci;
    this.model.etoh_truck_ci_change = etoh_truck_ci_change;
    this.model.etoh_truck_gal_value = "$"+ this.two_decimals(etoh_truck_gal_value);
    this.model.bulk_truck_grams = bulk_truck_grams;
    this.model.bulk_truck_ci = bulk_truck_ci;
    this.model.bulk_truck_ci_change = bulk_truck_ci_change;
    this.model.bulk_truck_gal_value = "$"+ this.two_decimals(bulk_truck_gal_value);
    this.model.etoh_transportation_totals_grams = etoh_transportation_totals_grams;
    this.model.etoh_transportation_totals_ci = etoh_transportation_totals_ci;
    this.model.etoh_transportation_totals_ci_change = etoh_transportation_totals_ci_change;
    this.model.etoh_transportation_totals_gal_value = "$"+ this.two_decimals(etoh_transportation_totals_gal_value);
    

    // Denaturant inclusion rate
    const denaturant = this.two_decimals(parseFloat(this.model.denaturant)/100) || 0;

    const doe_denaturant_ci = 1.2;
    const corn_yield = 178.4;
    const ethanol_yield = 2.864;

    const denaturant_grams= this.two_decimals(4831.944*denaturant);
    const denaturant_ci= this.two_decimals(denaturant_grams/80.5324);
    const denaturant_ci_change= this.two_decimals(denaturant_ci-doe_denaturant_ci);
    const denaturant_gal_value= this.get_gal_value(denaturant_ci_change);
    const denaturant_acre_value = this.two_decimals(denaturant_gal_value*corn_yield*ethanol_yield);

    this.model.denaturant_grams = denaturant_grams;
    this.model.denaturant_ci = denaturant_ci;
    this.model.denaturant_ci_change = denaturant_ci_change;
    this.model.denaturant_gal_value = "$"+ this.two_decimals(denaturant_gal_value);
    this.model.denaturant_acre_value = "$"+ this.two_decimals(denaturant_acre_value);


    // SUBTOTAL: Ethanol Plant Energy, Chemicals, Transportation & Denaturant
    const doe_etoh_plant_totals_ci = 29.4;

    const etoh_plant_totals_ci= this.two_decimals(denaturant_ci+etoh_transportation_totals_ci+etoh_chemical_total_ci+etoh_energy_total_ci);
    const etoh_plant_totals_ci_change= this.two_decimals(etoh_plant_totals_ci-doe_etoh_plant_totals_ci);
    const etoh_plant_totals_gal_value= this.get_gal_value(etoh_plant_totals_ci_change);
    const etoh_plant_totals_acre_value= this.two_decimals(etoh_plant_totals_gal_value*corn_yield*ethanol_yield);
    
    this.model.etoh_plant_totals_ci = etoh_plant_totals_ci;
    this.model.etoh_plant_totals_ci_change = etoh_plant_totals_ci_change;
    this.model.etoh_plant_totals_gal_value = "$"+ this.two_decimals(etoh_plant_totals_gal_value);
    this.model.etoh_plant_totals_acre_value = "$"+ this.two_decimals(etoh_plant_totals_acre_value);


    //Co-Product Credits
    const dry_dgs = this.two_decimals(parseFloat(this.model.dry_dgs)) || 0;

    const doe_dry_dgs_ci=-7.66;
    const doe_upstream_total_ci = 29.3;
    const upstream_total = 29.4;
    const state_average_gmj = 0;
    const doe_dry_dgs = 3.25;
    const dry_dgs_ci= this.two_decimals(-1*(-1*doe_dry_dgs_ci-.66*(doe_upstream_total_ci-(upstream_total-state_average_gmj))*.13)*(dry_dgs/doe_dry_dgs));
    const dry_dgs_ci_change= this.two_decimals(dry_dgs_ci-doe_dry_dgs_ci);
    const dry_dgs_gal_value= this.get_gal_value(dry_dgs_ci_change);
    this.model.dry_dgs_ci = dry_dgs_ci;
    this.model.dry_dgs_ci_change = dry_dgs_ci_change;
    this.model.dry_dgs_gal_value = "$"+ this.two_decimals(dry_dgs_gal_value);

    const wet_dgs = this.two_decimals(parseFloat(this.model.wet_dgs)) || 0; 
    const doe_wet_dgs_ci = -3.2;
    const doe_wet_dgs = 1.36;
    const wet_dgs_ci= this.two_decimals(-1*(-1*doe_wet_dgs_ci-.66*(doe_upstream_total_ci-(upstream_total-state_average_gmj))*.09)*(wet_dgs/doe_wet_dgs));
    const wet_dgs_ci_change= this.two_decimals(wet_dgs_ci-doe_wet_dgs_ci);
    const wet_dgs_gal_value= this.get_gal_value(wet_dgs_ci_change);
    this.model.wet_dgs_ci = wet_dgs_ci;
    this.model.wet_dgs_ci_change = wet_dgs_ci_change;
    this.model.wet_dgs_gal_value = "$"+ this.two_decimals(wet_dgs_gal_value);

    const corn_oil = this.two_decimals(parseFloat(this.model.corn_oil)) || 0; 
    const corn_oil_ci=0;
    const doe_corn_oil_ci = 0;
    const corn_oil_ci_change= this.two_decimals(corn_oil_ci- this.two_decimals(parseFloat(doe_corn_oil_ci.toString())));
    const corn_oil_gal_value= this.get_gal_value(corn_oil_ci_change);
    this.model.corn_oil_ci = corn_oil_ci;
    this.model.corn_oil_ci_change = corn_oil_ci_change;
    this.model.corn_oil_gal_value = "$"+ this.two_decimals(corn_oil_gal_value);

    const coproduct_totals= this.two_decimals(dry_dgs+wet_dgs+corn_oil);
    const coproduct_totals_ci= this.two_decimals(dry_dgs_ci+wet_dgs_ci+corn_oil_ci);
    const doe_coproduct_totals_ci = -10.86;
    const coproduct_totals_ci_change= this.two_decimals(coproduct_totals_ci-doe_coproduct_totals_ci);
    const coproduct_totals_gal_value= this.get_gal_value(coproduct_totals_ci_change);
    this.model.coproduct_totals = coproduct_totals;
    this.model.coproduct_totals_ci = coproduct_totals_ci;
    this.model.coproduct_totals_ci_change = coproduct_totals_ci_change;
    this.model.coproduct_totals_gal_value = "$"+ this.two_decimals(coproduct_totals_gal_value);


    //International and Domestic Land Use Change Penalty
    const indirect_ci = this.two_decimals(parseFloat(this.model.indirect_ci)) || 0; 
    const doe_indirect_ci = 7.38;
    const indirect_ci_change= this.two_decimals(indirect_ci-doe_indirect_ci);
    const indirect_gal_value= this.get_gal_value(indirect_ci_change);
    const indirect_acre_value= this.two_decimals(indirect_gal_value*corn_yield*ethanol_yield);
    this.model.indirect_ci_change = indirect_ci_change;
    this.model.indirect_gal_value = "$"+ this.two_decimals(indirect_gal_value);
    this.model.indirect_acre_value = "$"+ this.two_decimals(indirect_acre_value);


    //Credit for Carbon Capture and Sequestration
    const carbon_capture = this.two_decimals(parseFloat(this.model.carbon_capture)) || 0; 
   
    if (carbon_capture>0||carbon_capture<-35) {
        alert("The Credit for Carbon Capture value must be between -35 and 0.");
    } else {
        const carbon_capture_gal_value= this.get_gal_value(carbon_capture);
        const carbon_capture_acre_value= this.two_decimals(carbon_capture_gal_value*corn_yield*ethanol_yield);
        this.model.carbon_capture_gal_value = "$"+ this.two_decimals(carbon_capture_gal_value);
        this.model.carbon_capture_acre_value = "$"+ this.two_decimals(carbon_capture_acre_value);
    }
  }

}
