<div class="chat-popup">
    <div class="row ml-0 mr-0 mb-0" style="height: 430px;">
        <div class="col-md-6 left-side">
            <div class="text-center header">
                <span class="left-header">CONTACT DETAILS</span>
            </div>
            <div class="text-center input-box-gap">
                <img src="assets/images/contact/email.png" width="15px" style="margin-top: -3px;">
                <a href="mailto:Hello@TransparencyWise.com" class="left-text">Hello@TransparencyWise.com</a>
            </div>
            <div class="text-center mb-4 input-box-gap">
                <a href="https://www.facebook.com/transparencywise/" target="_blank" style="margin-right: 15px;">
                    <img src="assets/images/contact/facebook.png" width="20px">
                </a>
                <a href=" https://www.linkedin.com/company/transparencywise/" target="_blank">
                    <img src="assets/images/contact/linkedin.png" width="20px">
                </a>
            </div>  
        </div>
        <div class="col-md-6 right-side">
            <div class="">
                <img src="assets/images/contact/close-blue.png" alt="" class="float-right close-btn" width="10px"
                (click)="close()">
            </div>
            <div class="text-center mt-5">
                <span class="right-header">NEED HELP?</span>
            </div>
            <div class="text-center" style="margin-bottom: 22px;">
                <span class="mute-text">Feel free to send your message</span>
            </div>

            <form (ngSubmit)="contact(contactForm.valid)" #contactForm="ngForm">
                <div class="text-center">
                    <div class=" input-group pr-0 ">
                        <div class="input-group-append">
                            <span
                                class="input-group-text image-box">
                                <img class="" src="assets/images/contact/contact-person.png" width="13px">
                            </span>
                        </div>
                        <input type="text" class="input-box" placeholder="Full Name" [(ngModel)]="model.name"
                        [ngClass]="{error: name?.errors && (name.dirty || contactForm.submitted)}"
                          #name="ngModel" required name="name">
                    </div>

                    <div *ngIf="name.errors">
                        <div class="validation_div">
                            <p *ngIf="name.errors.required && contactForm.submitted" class="mb-0">
                                The name is required
                            </p>
                            <p class="text-danger" *ngIf="name.errors.pattern">
                                The name is invalid.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-center input-box-gap">
                    <div class=" input-group pr-0 ">
                        <div class="input-group-append">
                            <span
                                class="input-group-text image-box">
                                <img class="" src="assets/images/contact/contact-email.png" width="15px">
                            </span>
                        </div>
                        <input type="text" class="input-box" placeholder="Email" [(ngModel)]="model.email"
                        pattern="[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-zA-Z]{2,8}$"
                        [ngClass]="{error: email?.errors && (email.dirty || contactForm.submitted)}"
                          #email="ngModel" required name="email">
                    </div>

                    <div *ngIf="email.errors">
                        <div class="validation_div">
                            <p *ngIf="email.errors.pattern && contactForm.submitted" class="mb-0">
                                The correct email is required
                            </p>
                            <p *ngIf="email.errors.required && contactForm.submitted" class="mb-0">
                                The email is required
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-center input-box-gap">
                    <textarea class="text-area " rows="4" placeholder="How Can We Help?"
                        [(ngModel)]="model.message" #message="ngModel" required name="message"
                        [ngClass]="{error:message?.errors && (message.dirty || contactForm.submitted)}">
                    </textarea>
                    <div *ngIf="message.errors">
                        <div class="validation_div">
                            <p *ngIf="message.errors.required && contactForm.submitted" class="mb-0">
                                The field is required
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-center input-box-gap" style="margin-bottom: 20px;">
                    <button type="submit" class="btn-send" [disabled]="btnDisable">
                      <span class="" [ngClass]="{ 'icon-loader load spin': btnDisable }"></span>
                      SEND
                    </button>  
                </div>
            </form>

            
        </div>
    </div>
</div>