import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/_services';
import { HomeService } from '../home.service';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss']
})
export class ContactUsModalComponent implements OnInit {
  btnDisable: boolean
  model: any = {}
  @ViewChild('contactForm', { static: true }) formValues: any
  constructor(private dialogRef:MatDialogRef<ContactUsModalComponent>,
    private el: ElementRef,
    private alertService: AlertService,
    private homeService : HomeService,) { }

  ngOnInit(): void {
  }
  
  close(){
    this.dialogRef.close();
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      'form .ng-invalid'
    )
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    })
    setTimeout(() => {
      firstInvalidControl.focus()
    }, 1000)
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset: any = 50
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset
  }

  contact(valid: any): void {
    if (valid) {
      const sendData : any = {
        email:this.model.email,
        message : this.model.message,
        name : this.model.name,
        subject : "Need Help",
      }
      this.btnDisable = true
      this.homeService.contactUs(sendData).subscribe(
        (data: any) => {
          this.alertService.success(data.message)
          this.btnDisable = false
          this.formValues.resetForm()
          this.dialogRef.close();
        },
        (err: any) => {
          this.btnDisable = false
          if (err.error.error) {
            this.alertService.error(err.error.error)
          }
        }
      )
    }else {
      this.scrollToFirstInvalidControl()
    }
  }

}


