import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ciscore',
  templateUrl: './ciscore.component.html',
  styleUrls: ['./ciscore.component.scss']
})
export class CiscoreComponent implements OnInit {

  model: any = {
    corn_yield:178.4,
    ethanol_yield:2.864,
    fossil_fuel_diesel:7.22,
    fossil_fuel_diesel_ci:'',
    bio_renewable_diesel:0,
    bio_renewable_diesel_percent:3658,
    bio_renewable_diesel_ci:'',
    clear_flame_diesel:0,
    clear_flame_diesel_ci:'',
    etoh_blended_gasoline:1.28,
    etoh_blended_gasoline_percent:10700,
    etoh_blended_gasoline_ci:'',
    natural_gas:87,
    natural_gas_ci:'',
    lpg:2.2,
    lpg_ci:'',
    electricity:69.3,
    electricity_ci:'',
    corn_transport_miles_field_farm:10,
    corn_transport_miles_field_farm_percent:85,
    corn_transport_miles_field_farm_ci:'',
    corn_transport_miles_farm_plant:40,
    corn_transport_miles_farm_plant_percent:85,
    corn_transport_miles_farm_plant_ci:'',
    nitrogen_fertilizer_ammonia:49,
    nitrogen_fertilizer_ammonia_ci:'',
    nitrogen_fertilizer_urea:36.3,
    nitrogen_fertilizer_urea_ci:'',
    nitrogen_fertilizer_ammonium_nitrate:3.2,
    nitrogen_fertilizer_ammonium_nitrate_ci:'',
    nitrogen_fertilizer_ammonium_sulfate:3.2,
    nitrogen_fertilizer_ammonium_sulfate_ci:'',
    nitrogen_fertilizer_urea_ammonium_nitrate:50.5,
    nitrogen_fertilizer_urea_ammonium_nitrate_ci:'',
    nitrogen_fertilizer_MAP:'',
    nitrogen_fertilizer_MAP_ci:'',
    nitrogen_fertilizer_DAP:'',
    nitrogen_fertilizer_DAP_ci:'',
    anhydrous_precip:1.07,
    anhydrous_eef:1,
    anhydrous_application_timing:1.0879,
    anhydrous_nrate:0.913,
    anhydrous_n2o:'',
    granular_urea_eef:1,
    granular_urea_placement:1.35,
    granular_urea_timing:1.1331,
    granular_urea_n2o:'',
    ammonium_nitrate_eef:1,
    ammonium_nitrate_placement:1.25,
    ammonium_nitrate_timing:0.982,
    ammonium_nitrate_n2o:'',
    ammonium_sulfate_eef:1,
    ammonium_sulfate_placement:1.25,
    ammonium_sulfate_timing:1.1544,
    ammonium_sulfate_n2o:'',
    urea_ammonium_nitrate_eef:1,
    urea_ammonium_nitrate_placement:1.15,
    urea_ammonium_nitrate_timing:0.9825,
    urea_ammonium_nitrate_n2o:'',
    nitrogen_phosphate_placement:1.2,
    nitrogen_phosphate_timing:1.2,
    nitrogen_phosphate_n2o:'',
    n2o_fertilizer_cisum:'',
    total_precip:'',
    cn_ratio:0.7,
    total_corn_residue_n2o:'',
    phosphate_fert_map:29.6,
    phosphate_fert_dap:29.6,
    liquid_fert:0,
    liquid_fert_ci:'',
    potash_fertilizer:59.9,
    potash_fertilizer_ci:'',
    calcium_carbonate:573,
    calcium_carbonate_ci:'',
    soil_emmissions_ci:'',
    herbicide:2.3,
    herbicide_ci:'',
    insecticide:0.0049,
    insecticide_ci:'',
  }

  totalci:any

  constructor() { }

  ngOnInit(): void {
    this.calculateButton();
  }

  calculateButton() {
    // Base Level Yields
    const ethanol_yield = parseFloat(this.model.ethanol_yield) || 0;
    const corn_yield = parseFloat(this.model.corn_yield) || 0;
    const ethanol_yield_acre = (corn_yield * ethanol_yield * 80.5324);

    // Corn Production Cuels & Emission Formulas
    const fossil_diesel = parseFloat(this.model.fossil_fuel_diesel) || 0;
    const bio_renewable_diesel = parseFloat(this.model.bio_renewable_diesel) || 0;
    const bio_renewable_diesel_percent = parseFloat(this.model.bio_renewable_diesel_percent) || 0;
    const clear_flame_diesel = parseFloat(this.model.clear_flame_diesel) || 0;
    const etoh_blended_gasoline = parseFloat(this.model.etoh_blended_gasoline) || 0;
    const etoh_blended_gasoline_percent = parseFloat(this.model.etoh_blended_gasoline_percent) || 0;
    const natural_gas = parseFloat(this.model.natural_gas) || 0;
    const lpg = parseFloat(this.model.lpg) || 0;
    const electricity = parseFloat(this.model.electricity) || 0;

    const fossil_fuel_diesel_ci = ((fossil_diesel * 12140) / ethanol_yield_acre);
    const bio_renewable_diesel_ci = ((bio_renewable_diesel * bio_renewable_diesel_percent) / ethanol_yield_acre);
    const clear_flame_diesel_ci = ((clear_flame_diesel * 3568.42) / ethanol_yield_acre);
    const etoh_blended_gasoline_ci = ((etoh_blended_gasoline * etoh_blended_gasoline_percent) / ethanol_yield_acre);
    const natural_gas_ci = ((natural_gas * 80) / ethanol_yield_acre);
    const lpg_ci = ((lpg * 6965) / ethanol_yield_acre);
    const electricity_ci = ((electricity * 439) / ethanol_yield_acre);

    this.model.fossil_fuel_diesel_ci = fossil_fuel_diesel_ci.toFixed(2);
    this.model.bio_renewable_diesel_ci = bio_renewable_diesel_ci.toFixed(2);
    this.model.clear_flame_diesel_ci = clear_flame_diesel_ci.toFixed(2);
    this.model.etoh_blended_gasoline_ci = etoh_blended_gasoline_ci.toFixed(2);
    this.model.natural_gas_ci = natural_gas_ci.toFixed(2);
    this.model.lpg_ci = lpg_ci.toFixed(2);
    this.model.electricity_ci = electricity_ci.toFixed(2);

    // Corn Transportation Fuel Emissions Formulas
    const field_farm = parseFloat(this.model.corn_transport_miles_field_farm) || 0;
    const field_farm_percent = parseFloat(this.model.corn_transport_miles_field_farm_percent) || 0;
    const field_farm_ghg = (field_farm_percent / (field_farm / 10))
    const farm_plant = parseFloat(this.model.corn_transport_miles_farm_plant) || 0;
    const farm_plant_percent = parseFloat(this.model.corn_transport_miles_farm_plant_percent) || 0;
    const farm_plant_ghg = (2.245 * farm_plant_percent * (farm_plant / 40));

    const field_farm_ci = (field_farm_ghg / (80.5324 * ethanol_yield));
    const farm_plant_ci = (farm_plant_ghg / (80.5324 * ethanol_yield));

    this.model.corn_transport_miles_field_farm_ci = field_farm_ci.toFixed(2);
    this.model.corn_transport_miles_farm_plant_ci = farm_plant_ci.toFixed(2);

    // Phosphate Fertilizer Formulas
    const phosphate_fert_map = parseFloat(this.model.phosphate_fert_map) || 0;
    const phosphate_fert_dap = parseFloat(this.model.phosphate_fert_dap) || 0;
    const liquid_fert = parseFloat(this.model.liquid_fert) || 0; 

    const phosphate_fert_map_ci = (phosphate_fert_map * 999) / ethanol_yield_acre;
    const phosphate_fert_dap_ci = (phosphate_fert_dap * 826) / ethanol_yield_acre;
    const liquid_fert_ci = (liquid_fert * 920) / ethanol_yield_acre;

    this.model.phosphate_fert_map_ci = phosphate_fert_map_ci.toFixed(2);
    this.model.phosphate_fert_dap_ci = phosphate_fert_dap_ci.toFixed(2);
    this.model.liquid_fert_ci = liquid_fert_ci.toFixed(2);

    // Nitrogen Fertilizer Formulas
    const nitrogen_fertilizer_ammonia = parseFloat(this.model.nitrogen_fertilizer_ammonia) || 0;
    const nitrogen_fertilizer_urea = parseFloat(this.model.nitrogen_fertilizer_urea) || 0;
    const nitrogen_fertilizer_ammonium_nitrate = parseFloat(this.model.nitrogen_fertilizer_ammonium_nitrate) || 0;
    const nitrogen_fertilizer_ammonium_sulfate = parseFloat(this.model.nitrogen_fertilizer_ammonium_sulfate) || 0;
    const nitrogen_fertilizer_urea_ammonium_nitrate = parseFloat(this.model.nitrogen_fertilizer_urea_ammonium_nitrate) || 0;

    const nitrogen_fertilizer_ammonia_ci = (nitrogen_fertilizer_ammonia * 1437) / (80.5324 * ethanol_yield * corn_yield);
    const nitrogen_fertilizer_urea_ci = (nitrogen_fertilizer_urea * 1886) / (80.5324 * ethanol_yield * corn_yield);
    const nitrogen_fertilizer_ammonium_nitrate_ci = (nitrogen_fertilizer_ammonium_nitrate * 3038) / (80.5324 * ethanol_yield * corn_yield);
    const nitrogen_fertilizer_ammonium_sulfate_ci =  (nitrogen_fertilizer_ammonium_sulfate * 1588) / (80.5324 * ethanol_yield * corn_yield);
    const nitrogen_fertilizer_urea_ammonium_nitrate_ci = (nitrogen_fertilizer_urea_ammonium_nitrate * 2661) / (80.5324 * ethanol_yield * corn_yield);
    const nitrogen_fertilizer_MAP = (phosphate_fert_map / .52 * .11);
    const nitrogen_fertilizer_MAP_ci = (nitrogen_fertilizer_MAP / .11 * 211 / ethanol_yield_acre);
    const nitrogen_fertilizer_DAP = (phosphate_fert_dap / .46 * .148 +liquid_fert / .34 * .1);
    const nitrogen_fertilizer_DAP_ci = (nitrogen_fertilizer_DAP / .18 * 386 / ethanol_yield_acre);

    this.model.nitrogen_fertilizer_ammonia_ci = nitrogen_fertilizer_ammonia_ci.toFixed(2);
    this.model.nitrogen_fertilizer_urea_ci = nitrogen_fertilizer_urea_ci.toFixed(2);
    this.model.nitrogen_fertilizer_ammonium_nitrate_ci = nitrogen_fertilizer_ammonium_nitrate_ci.toFixed(2);
    this.model.nitrogen_fertilizer_ammonium_sulfate_ci = nitrogen_fertilizer_ammonium_sulfate_ci.toFixed(2);
    this.model.nitrogen_fertilizer_urea_ammonium_nitrate_ci = nitrogen_fertilizer_urea_ammonium_nitrate_ci.toFixed(2);
    this.model.nitrogen_fertilizer_MAP = nitrogen_fertilizer_MAP.toFixed(2);
    this.model.nitrogen_fertilizer_MAP_ci = nitrogen_fertilizer_MAP_ci.toFixed(2);
    this.model.nitrogen_fertilizer_DAP = nitrogen_fertilizer_DAP.toFixed(2);
    this.model.nitrogen_fertilizer_DAP_ci = nitrogen_fertilizer_DAP_ci.toFixed(2);

    // N2O Fertilizer Formulas (skipping for rn)
    const anhydrous_precip = parseFloat(this.model.anhydrous_precip) || 0;
    const anhydrous_eef = parseFloat(this.model.anhydrous_eef) || 0;
    const anhydrous_application_timing = parseFloat(this.model.anhydrous_application_timing) || 0;
    const anhydrous_nrate = parseFloat(this.model.anhydrous_nrate) || 0;
    const granular_precip_text = parseFloat(this.model.anhydrous_precip) || 0;
    const granular_urea_eef = parseFloat(this.model.granular_urea_eef) || 0;
    const granular_urea_placement = parseFloat(this.model.granular_urea_placement) || 0;
    const granular_urea_timing = parseFloat(this.model.granular_urea_timing) || 0;
    const granular_nrate_text = parseFloat(this.model.anhydrous_nrate) || 0;
    var granular_precip = 0;
    var granular_nrate = 0;
    const ammonium_nitrate_eef = parseFloat(this.model.ammonium_nitrate_eef) || 0;
    const ammonium_nitrate_placement = parseFloat(this.model.ammonium_nitrate_placement) || 0;
    const ammonium_nitrate_timing = parseFloat(this.model.ammonium_nitrate_timing) || 0;
    var ammonium_nitrate_precip = 0;
    var ammonium_nitrate_nrate = 0;
    const ammonium_sulfate_eef = parseFloat(this.model.ammonium_sulfate_eef) || 0;
    const ammonium_sulfate_placement = parseFloat(this.model.ammonium_sulfate_placement) || 0;
    const ammonium_sulfate_timing = parseFloat(this.model.ammonium_sulfate_timing) || 0;
    var ammonium_sulfate_precip = 0;
    var ammonium_sulfate_nrate = 0;
    const urea_ammonium_nitrate_eef = parseFloat(this.model.urea_ammonium_nitrate_eef) || 0;
    const urea_ammonium_nitrate_placement = parseFloat(this.model.urea_ammonium_nitrate_placement) || 0;
    const urea_ammonium_nitrate_timing = parseFloat(this.model.urea_ammonium_nitrate_timing) || 0;
    var urea_ammonium_nitrate_precip = 0;
    var urea_ammonium_nitrate_nrate = 0;
    const nitrogen_phosphate_placement = parseFloat(this.model.nitrogen_phosphate_placement) || 0;
    const nitrogen_phosphate_timing = parseFloat(this.model.nitrogen_phosphate_timing) || 0;
    var nitrogen_phosphate_precip = 0;
    var nitrogen_phosphate_nrate = 0;
    const cn_ratio = parseFloat(this.model.cn_ratio) || 0;
    var corn_residue_precip = 0;

    if (granular_precip_text == 0.88) {
      granular_precip = 1;
      ammonium_nitrate_precip = 0.88;
      ammonium_sulfate_precip = 0.9;
      urea_ammonium_nitrate_precip = .85;
      nitrogen_phosphate_precip = .89;
      corn_residue_precip = .85;
    } else if (granular_precip_text == 0.93) {
      granular_precip = 1.1;
      ammonium_nitrate_precip = 0.91;
      ammonium_sulfate_precip = 1;
      urea_ammonium_nitrate_precip = .92;
      nitrogen_phosphate_precip = .97;
      corn_residue_precip = 1.1;
    } else if (granular_precip_text == 1.07) {
      granular_precip = 1.15;
      ammonium_nitrate_precip = 1.1;
      ammonium_sulfate_precip = 1.1;
      urea_ammonium_nitrate_precip = 1.1;
      nitrogen_phosphate_precip = 1.07;
      corn_residue_precip = 1.4;
    }

    if (granular_nrate_text == 0.6) { 
      granular_nrate = .6;
      ammonium_nitrate_nrate = .6;
      ammonium_sulfate_nrate = .6;
      urea_ammonium_nitrate_nrate = .85;
      nitrogen_phosphate_nrate = .6;
    } else if (granular_nrate_text == 0.913) {
      granular_nrate = .81;
      ammonium_nitrate_nrate = .75;
      ammonium_sulfate_nrate = .91;
      urea_ammonium_nitrate_nrate = .95;
      nitrogen_phosphate_nrate = .86;
    } else if (granular_nrate_text == 1.2) {
      granular_nrate = 1;
      ammonium_nitrate_nrate = .9;
      ammonium_sulfate_nrate = 1.2;
      urea_ammonium_nitrate_nrate = .86;
      nitrogen_phosphate_nrate = 1.1;
    }

    const anhydrous_n2o = (0.01374 * nitrogen_fertilizer_ammonia * anhydrous_precip * anhydrous_eef * .9698 * .9698 * anhydrous_application_timing * anhydrous_nrate * 189140 / ethanol_yield_acre);
    const granular_urea_n2o = (0.01374 * nitrogen_fertilizer_urea * granular_precip * granular_urea_eef * 0.7 * granular_urea_placement * granular_urea_timing * granular_nrate *189550 / ethanol_yield_acre);
    const ammonium_nitrate_n2o = (0.01374 * nitrogen_fertilizer_ammonium_nitrate * ammonium_nitrate_precip *ammonium_nitrate_eef * 1 * ammonium_nitrate_placement * ammonium_nitrate_timing * ammonium_nitrate_nrate * 265 * (44/28) * 454 / ethanol_yield_acre);
    const ammonium_sulfate_n2o = (.0136 * nitrogen_fertilizer_ammonium_sulfate * ammonium_sulfate_precip * ammonium_sulfate_eef * 0.7 * ammonium_sulfate_placement * ammonium_sulfate_timing * ammonium_sulfate_nrate * 189058.5714 / ethanol_yield_acre);
    const urea_ammonium_nitrate_n2o = (.01369 * nitrogen_fertilizer_urea_ammonium_nitrate * urea_ammonium_nitrate_precip * urea_ammonium_nitrate_eef * .85 * urea_ammonium_nitrate_placement * urea_ammonium_nitrate_timing * urea_ammonium_nitrate_nrate * 189058.5714 / ethanol_yield_acre) ;
    const nitrogen_phosphate_n2o = (.0148 * (nitrogen_fertilizer_MAP + nitrogen_fertilizer_DAP) * nitrogen_phosphate_precip * anhydrous_eef * .7 * nitrogen_phosphate_placement * nitrogen_phosphate_timing * nitrogen_phosphate_nrate *189058.5714 / ethanol_yield_acre);

    const n2o_sum = anhydrous_n2o + granular_urea_n2o + ammonium_nitrate_n2o + ammonium_sulfate_n2o + urea_ammonium_nitrate_n2o + nitrogen_phosphate_n2o;
    const residue_n2o = (141.6 * corn_yield / 454 * .0129 * corn_residue_precip * cn_ratio * 189020 / ethanol_yield_acre);

    this.model.anhydrous_n2o = anhydrous_n2o.toFixed(2);
    this.model.granular_urea_n2o = granular_urea_n2o.toFixed(2);
    this.model.ammonium_nitrate_n2o = ammonium_nitrate_n2o.toFixed(2);
    this.model.ammonium_sulfate_n2o = ammonium_sulfate_n2o.toFixed(2);
    this.model.urea_ammonium_nitrate_n2o = urea_ammonium_nitrate_n2o.toFixed(2);
    this.model.nitrogen_phosphate_n2o = nitrogen_phosphate_n2o.toFixed(2);
    this.model.n2o_fertilizer_cisum = n2o_sum.toFixed(2);
    this.model.total_corn_residue_n2o = residue_n2o.toFixed(2);

    // Potash Fertilizer Formulas
    const potash_fertilizer = parseFloat(this.model.potash_fertilizer) || 0;

    const potash_fertilizer_ci = (potash_fertilizer * 245 / ethanol_yield_acre);

    this.model.potash_fertilizer_ci = potash_fertilizer_ci.toFixed(2);

    // Lime Formulas
    const calcium_carbonate = parseFloat(this.model.calcium_carbonate) || 0;

    const calcium_carbonate_ci = (calcium_carbonate * 0.0243 / (80.5324 * ethanol_yield));
    const soil_emmissions_ci = (calcium_carbonate * 0.5261 / (80.5324 * ethanol_yield) + calcium_carbonate_ci);

    this.model.calcium_carbonate_ci = calcium_carbonate_ci.toFixed(2);
    this.model.soil_emmissions_ci = soil_emmissions_ci.toFixed(2);

    // Herbicide & Insecticide Formulas
    const herbicide = parseFloat(this.model.herbicide) || 0;
    const insecticide = parseFloat(this.model.insecticide_ci) || 0;

    const herbicide_ci = (herbicide * 8420 / ethanol_yield_acre);
    const insecticide_ci = (insecticide * 9938 / ethanol_yield_acre);

    this.model.herbicide_ci = herbicide_ci.toFixed(2);
    this.model.insecticide_ci = insecticide_ci.toFixed(4);

    this.model.total_precip = this.getDescriptiveText(this.model.anhydrous_precip);

    this.totalci = fossil_fuel_diesel_ci + bio_renewable_diesel_ci + clear_flame_diesel_ci + 
      etoh_blended_gasoline_ci + natural_gas_ci + lpg_ci + electricity_ci + field_farm_ci + farm_plant_ci + 
      nitrogen_fertilizer_ammonia_ci + nitrogen_fertilizer_urea_ci + nitrogen_fertilizer_ammonium_nitrate_ci + 
      nitrogen_fertilizer_ammonium_sulfate_ci + nitrogen_fertilizer_urea_ammonium_nitrate_ci + 
      nitrogen_fertilizer_MAP_ci + nitrogen_fertilizer_DAP_ci + n2o_sum + phosphate_fert_map_ci + 
      phosphate_fert_dap_ci + liquid_fert_ci + potash_fertilizer_ci + calcium_carbonate_ci + 
      soil_emmissions_ci + herbicide_ci + insecticide_ci + residue_n2o
  }

  getDescriptiveText(value: string): string {
    switch (value.toString()) {
      case '0.88':
        return 'Less than 25';
      case '0.93':
        return 'Between 25 to 30';
      case '1.07':
        return 'Greater than 30';
      default:
        return '';
    }
  }

}
