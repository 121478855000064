<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <img src="assets/images/holy-smokes_details.png" style="width: 100%" />
    </div>
  </div>

  <!-- Tabs -->
  <mat-tab-group [selectedIndex]="tabIndex">
    <mat-tab label="Traditional Village">
      <div>
        <h3 class="h3-title mt-3 mb-3">Traditional Village</h3>
      </div>

      <div class="">
        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9">
                <p class="bold">Pit Cooked Chicken With White Sauce</p>
                <span
                  >Served With Collard Greens, Pimento Cheese <br />
                  Potato Salad, and Bread & Butter Pickles</span
                >
              </div>
              <div class="col-md-3 text-center"></div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9">
                <p class="bold">West Tennesse Dry Ribs & Smoked Spare Ribs</p>
                <span
                  >Served With Hash & Rice, Cracklin’ Corn Pudding, and
                  Pickles</span
                >
              </div>
              <div class="col-md-3 text-center">
                <span>Proudly supplied By</span> <br>
                <img
                  src="assets/images/Swift Heritage Logo.png"
                  class="menu-logo"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9">
                <p class="bold">Whole Hog Two Ways</p>
                <span
                  >Served With Corn Pudding, Baked <br />Beans, and
                  Cracklin</span
                >
              </div>
              <div class="col-md-3 text-center"></div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 class="h3-title mt-3 mb-3">Who’s Cooking?</h3>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Anthony DiBernardo of Swig & Swine</li>
                <li>Carey Bringle of Peg Leg Porker</li>
                <li>Chris Lilly of Big Bob Gibson Bar-B-Q</li>
                <li>Michael Bessinger of Bessinger’s BBQ</li>
              </ul>
            </div>

            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Pat Martine of Martin’s Bar-B-Que Joint</li>
                <li>Rodney Scott of Rodney Scott’s BBQ</li>
                <li>Sam Jones of Sam Jones BBQ</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row mt-30 mb-30">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <span class="bold mt-3">Village Presented by:</span> <br />
              <img src="assets/images/water-edge.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Texas Village">
      <div>
        <h3 class="h3-title mt-3 mb-3">Texas Village</h3>
      </div>

      <div class="">
        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9">
                <p class="bold">Ribs</p>
                <span
                  >Served With Elot Salad, Tater Tot Casserole, <br />and Dill
                  Pickles</span
                >
              </div>
              <div class="col-md-3 text-center">
                <span>Proudly supplied By</span> <br>
                <img
                  src="assets/images/Swift Heritage Logo.png"
                  class="menu-logo"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9">
                <p class="bold">Sausage</p>
                <span
                  >Served With Cheesy Hominy, Texas Caviar, <br />Pickled Red
                  Onion, and Pickles</span
                >
              </div>
              <div class="col-md-3 text-center"></div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9">
                <p class="bold">Whole Hog Two Ways</p>
                <span
                  >Served With Red Skinned Potato Salad, <br />Calabacitas
                  Onion, and Pickles</span
                >
              </div>
              <div class="col-md-3 text-center">
                <span>Proudly supplied By</span> <br />
                <img
                  src="assets/images/certified-angus-beef.png"
                  width="70px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 class="h3-title mt-3 mb-3">Who’s Cooking?</h3>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Billy Durney of Hometown Bar-B-Que</li>
                <li>
                  Harrison Sapp & Griffin Bufkin of Southern Soul Barbeque
                </li>
                <li>Jonathan & Justin Fox of Fox Bros Bar-B-Q</li>
                <li>Madoson Ruckel of Mama Jean’s Barbecue</li>
              </ul>
            </div>

            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Ronnie Evans & Philip Moseley of Blue Oak BBQ</li>
                <li>Tank Jackson of Holy City Hogs</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row mt-30 mb-30">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <p class="bold mt-3">Village Presented by:</p>
              <img src="assets/images/YETI-Logo-Blue.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="New School Village">
      <div>
        <h3 class="h3-title mt-3 mb-3">New School Village</h3>
      </div>

      <div class="">
        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Smoked Sirloin Taco</p>
                <span
                  >Served With Elot Salad, Tater Tot Casserole, <br />and Dill
                  Pickles</span
                >
              </div>
              <div class="col-md-4">
                <p class="bold">Team:</p>
                <span>Jonathan & Justin Fox Billy Dum</span>
              </div>
              <div class="col-md-3 text-center">
                <span>Proudly supplied By</span> <br />
                <img
                  src="assets/images/certified-angus-beef.png"
                  width="70px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Fried Hawg Bawls</p>
                <span
                  >Served With Cheesy Hominy, Texas Caviar, Pickled Red Onion,
                  and Pickles</span
                >
              </div>
              <div class="col-md-4">
                <p class="bold">Team:</p>
                <span>Harrison Sapp & Griffin Bufkin Tan Jackson</span>
              </div>
              <div class="col-md-3 text-center"></div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Smoked Tri-Tip</p>
                <span
                  >Served With Red Skinned Potato Salad, Calabacitas Onion, and
                  Pickles</span
                >
              </div>
              <div class="col-md-4">
                <p class="bold">Team:</p>
                <span>Ronnie Evans & Philip Moseley Madison Ruckel</span>
              </div>
              <div class="col-md-3 text-center"></div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 class="h3-title mt-3 mb-3">Who’s Cooking?</h3>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Andrew Meñoz of Moo’s Craft Barbecue</li>
                <li>Arnis & Mallory Robbins of Evie Mae’s Pit Barbecue</li>
                <li>Chris Fultz of ZZQ Texas Craft Barbeque</li>
                <li>Cody Sperry of Hoodoo Brown Barbeque</li>
              </ul>
            </div>

            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Leonard Botello IV of TRUTH BBQ</li>
                <li>Patrick Feges of Feges BBQ</li>
                <li>Stephen Franklin of DAS BBQ</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row mt-30 mb-30">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <p class="bold mt-3">Village Presented by:</p>
              <img src="assets/images/Crown logo.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Coastal Village">
      <div>
        <h3 class="h3-title mt-3 mb-3">Coastal Village</h3>
      </div>

      <div class="">
        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Smoked Oyster Stew</p>
                <span
                  >Served With Smoked Oysters, Tasso Ham, Mushroom, Grilled
                  Bread, and Gremolata</span
                >
              </div>
              <div class="col-md-4">
                <p class="bold">Team:</p>
                <span>Evan LeRoy<br />Matt Register</span>
              </div>
              <div class="col-md-3 text-center">
                <span>Proudly supplied By</span> <br />
                <img
                  src="assets/images/CHERRYPOINTSEAFOODCO_D.png"
                  width="70px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Smoked Swordfish</p>
                <span
                  >Served With Carolina Gold Pirlou, Peas, Lemon, Caper, and Castelvetrano Olive</span
                >
              </div>
              <div class="col-md-4">
                <p class="bold">Team:</p>
                <span>Elliot Moss<br>Aaron Siegel and Taylor Garrigan</span>
              </div>
              <div class="col-md-3 text-center">
                <span>Proudly supplied By</span> <br />
                <img
                  src="assets/images/CHERRYPOINTSEAFOODCO_D.png"
                  width="70px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-5">
                  <p class="bold">Mississippi Delta Shrimp & Grits</p>
                  
                </div>
                <div class="col-md-4">
                  <p class="bold">Team:</p>
                  <span>Chef Jamie Hough<br>Leslie Roark Scott</span>
                </div>
                <div class="col-md-3 text-center">
                  <span>Proudly supplied By</span> <br />
                  <img
                    src="assets/images/CHERRYPOINTSEAFOODCO_D.png"
                    width="70px"
                  />
                </div>
              </div>
            </div>
          </div>
      </div>

      <div>
        <h3 class="h3-title mt-3 mb-3">Who’s Cooking?</h3>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Evan LeRoy of LeRoy and Lewis BBQ</li>
                <li>Chef Jamie Hough of Zero Forks Given</li>
                <li>Leslie Roark Scott of Ubon’s Restaurant</li>
                <li>Matt Register of Southern Smoke BBQ</li>
              </ul>
            </div>

            <div class="col-md-6">
              <ul class="cooking-ul">
                <li>Elliot Moss of Regina’s</li>
                <li>Aaron Siegel & Taylor Garrigan of Home Team BBQ</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row mt-30 mb-30">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <p class="bold mt-3">Village Presented by:</p>
              <img src="assets/images/sponsor-anderson.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Beverage Menu">
        <div>
            <h3 class="h3-title mt-3 mb-0">Beverage Menu</h3>
            <span class="lightgray">Available For All GA Ticket Holders</span>

            <div>
                <h4 class="h4-submenu"><span>Wine ($7)</span></h4>
                <p class="bold mb-0">Buccaneer Red Wine  (6oz)</p>
                <p class="bold">Buccaneer White Wine  (6oz)</p>
            </div>
            <div>
                <h4 class="h4-submenu"><span>Beer/Seltzer ($6-8)</span></h4>
                <p class="bold mb-0">Commonhouse Broad Path Brown Ale (12oz)</p>
                <p class="bold mb-0">Commonhouse Park Circle Pale Ale (12oz)</p>
                <p class="bold mb-0">Estuary 25 Island Wide IPA (12oz)</p>
                <p class="bold mb-0">Estuary Watermelon Crush Seltzer (12oz)</p>
                <p class="bold mb-0">Estuary License To Chill Lager (12oz)</p>
                <p class="bold mb-0">Bud Light (16oz)</p>
                <p class="bold">Michelob Ultra (16oz)</p>
            </div>
            <div>
                <h4 class="h4-submenu"><span>Craft Cocktails ($12)</span></h4>
                <p class="bold mb-1">Arnold Palmer <span class="lightgray ml-4">Deep Eddy Lemon Vodka, Black Tea, Simple Syrup</span></p>
                <p class="bold mb-1">Ginger Peach Mule <span class="lightgray ml-4">Deep Eddy Peach Vodka, Lemon Juice, Mint, Ginger Beer</span></p>
                <p class="bold mb-1">Paloma <span class="lightgray ml-4">Lunazul Reposado Tequila, Grapefruit Juice, Lime Juice, Agave</span></p>
                <p class="bold mb-1">Spicy Cooler <span class="lightgray ml-4">Lunazul Blanco Tequila, Jalapeño, Cucumber, Lime, Agave</span></p>
                <p class="bold mb-1">Apple Smash <span class="lightgray ml-4">Elijah Craig Small Batch Bourbon, Apple Cider, Lime Juice, Ginger Beer</span></p>
                <p class="bold mb-1">Larceny Cheer <span class="lightgray ml-4">Larceny Boudon, Cheerwine</span></p>
            </div>
            <div>
                <h4 class="h4-submenu"><span>Soda/Tea ($3)</span></h4>
                <p class="bold mb-30">Golden Peak Sweet Tea, Coke, Diet Coke, Sprite, Cheerwine</p>
            </div>
          </div>
    </mat-tab>
  </mat-tab-group>
  <!-- ./Tabs -->
</div>

<div class="donate-box">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-3">
        <h2 class="text-white bold">Want To Donate?</h2>
        <a href="https://www.convergepay.com/hosted-payments/?ssl_txn_auth_token=4RFk2bb8TdWUsWg9wolsKQAAAXyeK1Ue" target="_blank"><button class="white-btn mt-2">Support Today</button></a>
        <p class="text-white banner-p">YOUR SUPPORT WILL HELP BENEFIT HOGS FOR THE CAUSE, MUSC SHAWN JENKINS CHILDREN’S HOSPITAL AND THE RONALD MCDONALD HOUSE OF CHARLESTON</p>
      </div>
    </div>
    <!-- <button class="yellow-btn mt-3">DONATE</button> -->
  </div>
</div>
