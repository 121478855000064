<div class="feedback-dilog">
    <div mat-dialog-title>
        <div class="d-flex align-items-center justify-content-between">
            <h2>Filter</h2>
            <a href="javascript:void(0)" (click)="close()"><span class="icon-close"></span></a>
        </div>
    </div>
    <mat-dialog-content>
        <div class="filter">
            <h5>Choose Brand</h5>
            <ul>
                <li *ngFor="let brand of brands; let i=index">
                    <div class="my_radio mb-0">
                        <input [checked]="brand.check==true" (change)="toggle(brand.public_id, $event,i)" name="{{brand.public_id}}" class="form-check-input" type="checkbox" id="{{brand.public_id}}"/>
                        <label class="form-check-label" for="{{brand.public_id}}"> {{brand.name}}</label>
                        <span class="check"></span>
                    </div>
                </li>
            </ul>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="text-right">
        <div class="d-flex justify-content-end w-100">
            <button [disabled]="btnDisable" type="submit" class="btn border-btn" (click)="onReset()">Reset</button>
            <button mat-button mat-dialog-close class="ml-2 btn theme-btn" (click)="onSubmit()">Submit</button>
        </div>
    </mat-dialog-actions>
</div>
