import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { Observable } from 'rxjs/internal/Observable'
import { environment } from '../../environments/environment'
import { of, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import constApi from 'src/assets/json/endpoint.json'
import { AlertService } from '../_services'
import { HomeService } from '../user/home/home.service'

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  isAgeVerify = false
  options: any
  encryptedData: any
  api: any
  mapApi: any
  constructor(private http: HttpClient, private alertService: AlertService, private homeService: HomeService) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    this.options = { headers }
    this.api = constApi.productApi
    this.mapApi = constApi.googleApi
  }
  updateToken(): void {
    this.encryptedData = localStorage.getItem('userInfo')
    if (this.encryptedData) {
      const user: any = JSON.parse(
        CryptoJS.AES.decrypt(
          this.encryptedData,
          environment.secretKey
        ).toString(CryptoJS.enc.Utf8)
      )
      const headers: any = new HttpHeaders({
        Authorization: user.tokenType + ' ' + user.accessToken,
      })
      this.options = { headers }
    }
  }
  getLatLong(addres): Observable<any> {
    if (!addres) {
      addres = "";
    }
    return this.http.get(
      this.mapApi.geoApi +
      addres +
      "&result_type=postal_code  &key=" + environment.googleApiKey
    );
  }

 

  getAllBrands(search): Observable<any> {
    let name = ''
    if(search) {
      name = search
    } else {
      name = ''
    }
    return this.http.get(this.api.baseUrl + 'all-brands/?search=' +name)
  }
  
  private handleError() {
    return throwError('Something bad happened; please try again later.')
  }
  errorHandler(error: any): any {
    this.alertService.error(error.error.error ? error.error.error : 'Something went wrong')
    this.homeService.log_out(error.status)
    return throwError(error)
  }
}
