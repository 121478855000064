<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <img src="assets/images/holy-smokes_details.png" style="width: 100%" />
    </div>
  </div>

  <!-- Tabs -->
  <mat-tab-group>
    <mat-tab label="VIP Village">
      <div>
        <h3 class="h3-title mt-3 mb-3">VIP Village</h3>
      </div>

      <div class="">
        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Tuna Toastada</p>
                <span>Served With Red Jalapeño Salsa</span>
              </div>
              <div class="col-md-7">
                <p class="bold">Chef:</p>
                <span>Ben Berryhill of Red Drum</span>
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Duck & Andouille Gumbo</p>
                <span>Served With Carolina Gold Rice and Cracklins</span>
              </div>
              <div class="col-md-7">
                <p class="bold">Chef:</p>
                <span>Kevin Mitchell of Chefscholar LLC</span>
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Fried Gulf Oyster</p>
                <span
                  >Served With Pickled Chili Mayonnaise and Pumpkin Salad</span
                >
              </div>
              <div class="col-md-7">
                <p class="bold">Chef:</p>
                <span
                  >Ryan Rewitt & Stephen Styjewski of <br />Pêche Seafood</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Cha Shao Rib Bao</p>
                <span
                  >Served With Pickled Chili Mayonnaise and Pumpkin Salad</span
                >
              </div>
              <div class="col-md-7">
                <p class="bold">Chef:</p>
                <span>Shuai & Corrie Wang of King BBQ</span>
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Smoked Fish</p>
                <span>Served With South Chili Crunch</span>
              </div>
              <div class="col-md-7">
                <p class="bold">Chef:</p>
                <span
                  >Ryan Rewitt & Stephen Styjewski of <br />Pêche Seafood</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Grilled Octopus</p>
                <span
                  >Served With Squid Ink Bean Puree and Black Truffle
                  Escabeche</span
                >
              </div>
              <div class="col-md-7">
                <p class="bold">Chef:</p>
                <span>Jacques Larson of Wild Olive/The Obstinate Daughter</span>
              </div>
            </div>
          </div>
        </div>

        <div class="full-div mb-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <p class="bold">Cheese & Charcuterie</p>
                <span
                  >Served With Housemade Pickled Products, Dried Fruit, Nuts,
                  Bread, and Crackers</span
                >
              </div>
              <div class="col-md-7">
                <p class="bold">Chef:</p>
                <span
                  >Patty Floersheimer & Trudi Wagner of goat. sheep. cow.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="container-fluid">
          <span class="bold mt-3">Village Presented by:</span>
          <div class="row mt-30 mb-30">
            <div class="col-md-2">
              <img src="assets/images/GreatOPl091422.png" width="100%" />
            </div>
            <div class="col-md-2">
              <img src="assets/images/Enlightened Soil Corp logo.png" width="100%" />
            </div>
            <div class="col-md-6 mt-5">
              <img src="assets/images/BFF.png" width="100%" />
            </div>
            <div class="col-md-2">
              <img src="assets/images/Transparency Wise V1.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <!-- ./Tabs -->
</div>

<div class="donate-box">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-3">
        <h2 class="text-white bold">Want To Donate?</h2>
        <a href="https://www.convergepay.com/hosted-payments/?ssl_txn_auth_token=4RFk2bb8TdWUsWg9wolsKQAAAXyeK1Ue" target="_blank"><button class="white-btn mt-2">Support Today</button></a>
        <p class="text-white banner-p">YOUR SUPPORT WILL HELP BENEFIT HOGS FOR THE CAUSE, MUSC SHAWN JENKINS CHILDREN’S HOSPITAL AND THE RONALD MCDONALD HOUSE OF CHARLESTON</p>
      </div>
    </div>
  </div>
</div>
