import { Attribute, Directive, forwardRef } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms'

@Directive({
  selector:
    '[appValidateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualValidator),
      multi: true,
    },
  ],
})
export class EqualValidator implements Validator {
  constructor(
    @Attribute('validateEqual') public validateEqual: string,
    @Attribute('reverse') public reverse: string
  ) {}

  private get isReverse(): any {
    if (!this.reverse) {
      return false
    }
    return this.reverse === 'true' ? true : false
  }

  validate(c: AbstractControl): { [key: string]: any } {
    // self value
    const v: any = c.value

    // control vlaue
    const e: any = c.root.get(this.validateEqual)

    // value not equal
    if (e && v !== e.value && !this.isReverse) {
      return {
        validateEqual: false,
      }
    }

    // value equal and reverse
    this.checkValidate(e, v)

    return null
  }
  checkValidate(e: any, v: any): void {
    if (e && v === e.value && this.isReverse) {
      if (e.errors) {
        delete e.errors.validateEqual
        if (!Object.keys(e.errors).length) {
          e.setErrors(null)
        }
      }
    }

    // value not equal and reverse
    if (e && v !== e.value && this.isReverse) {
      e.setErrors({
        validateEqual: false,
      })
    }
  }
}
