import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-source-details',
  templateUrl: './source-details.component.html',
  styleUrls: ['./source-details.component.scss']
})
export class SourceDetailsComponent implements OnInit {
  title: any
  constructor(public dialogRef: MatDialogRef<SourceDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.title = data
    }

  ngOnInit(): void {
  }

}
