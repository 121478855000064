import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { ProductService } from 'src/app/product-management/product.service'
@Component({
  selector: 'app-product-beverage',
  templateUrl: './product-beverage.component.html',
  styleUrls: ['./product-beverage.component.scss'],
})
export class ProductBeverageComponent {
  checkedRemember: any = false
  bevarageRememberMe: any
  btnDisable = true
  checked = false
  constructor(public dialogRef: MatDialogRef<ProductBeverageComponent>, private productService: ProductService) { }
  close() {
    localStorage.setItem('isNotRedirect', 'yes')
    this.dialogRef.close('close')
  }
  toggle(event) {
    this.checkedRemember = event.target.checked
    localStorage.setItem('isCheckedBevarage', this.checkedRemember)
  }
  rememberMe(val) {
    val === 'yes' ? this.productService.isAgeVerify = true : this.productService.isAgeVerify = false
    localStorage.setItem('bevarageRememberMe', val)
    this.dialogRef.close(val)
  }
}
