<div class="login-page">
  <div class="login-content user-log">
    <div class="login-logo">
      <a class="navbar-brand" routerLink="/">
        <img
          src="assets\images\TW-Logo-(26).png" alt="image"
        />
        <img
          class="mob-logo"
          src="assets\images\TW-Logo-(26).png" alt="image"
        />
      </a>
    </div>
    <div class="co-box">
      <p>
        "If a picture is worth a thousand words, a video is worth a thousand
        pictures"
      </p>
      <button class="btn btn-yellow" (click)="contactUsDialog()">
        Contact Us
      </button>
    </div>
  </div>
  <div class="user-form">
    <div class="loginnow">
      <div class="form-box">
        <div class="head d-flex align-items-center justify-content-between">
          <h3>Login</h3>
          <div class="d-flex align-items-center">
            <a href="javascript:void(0)" routerLink="/">
              <span class="icon-arrow mr-1"></span> Back to Home
            </a>
          </div>
        </div>
        <form (ngSubmit)="login(loginForm.valid)" #loginForm="ngForm">
          <div class="form-group">
            <label>Email</label>
            <input
              maxlength="150"
              pattern="[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-zA-Z]{2,8}$"
              name="email"
              type="email"
              placeholder="Enter email"
              class="form-control"
              [(ngModel)]="model.email"
              [ngClass]="{
                error: email?.errors && (email.dirty || loginForm.submitted)
              }"
              #email="ngModel"
              required
            />
            <div *ngIf="email.errors">
              <div class="validation_div">
                <p
                  *ngIf="email.errors.pattern && loginForm.submitted"
                  class="mb-0"
                >
                  The correct email is required
                </p>
                <p
                  *ngIf="email.errors.required && loginForm.submitted"
                  class="mb-0"
                >
                  The email is required
                </p>
              </div>
            </div>
          </div>
          <div class="form-group ic-pass">
            <label>Password</label>
            <span
              (click)="changePasswordType()"
              [ngClass]="{
                'icon-show-pass': passwordType != 'password',
                'icon-hide-pass': passwordType == 'password'
              }"
            ></span>
            <input
              minlength="6"
              maxlength="30"
              [(ngModel)]="model.password"
              type="{{ passwordType }}"
              name="password"
              placeholder="Enter password"
              class="form-control with-icon"
              #password="ngModel"
              required
              [ngClass]="{
                error:
                  password?.errors && (password.dirty || loginForm.submitted)
              }"
            />
            <div *ngIf="password.errors">
              <div class="validation_div">
                <p
                  class="mb-0"
                  *ngIf="
                    password.errors.minlength &&
                    loginForm.submitted &&
                    !password.errors.pattern
                  "
                >
                  The Password must be at least 6 characters long
                </p>
                <p
                  class="mb-0"
                  *ngIf="password.errors.required && loginForm.submitted"
                  class="error-msg"
                >
                  The password is required
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="my_radio mb-0">
              <input
                [(ngModel)]="rememberMe"
                name="terms"
                class="form-check-input"
                type="checkbox"
                id="terms1"
              />
              <label class="form-check-label" for="terms1"> Remember me</label>
              <span class="check"></span>
            </div>
            <div class="links">
              <a routerLink="/forgot-password">Forgot Password?</a>
            </div>
          </div>
          <div class="text-center pt-3">
            <button [disabled]="btnDisable" type="submit" class="btn theme-btn">
              <span
                class=""
                [ngClass]="{ 'icon-loader load spin': btnDisable }"
              ></span>
              Login
            </button>
          </div>
        </form>
        <div class="social-links">
          <h5>or</h5>
          <div class="d-flex align-items-center justify-content-between">
            <div id="buttonDiv"></div> 
          </div>
        </div>
        <div class="signup-text">
          <p>Don't have an account? <a routerLink="/sign-up">Signup </a></p>
        </div>
      </div>
    </div>
  </div>
</div>
