<div class="login-page">
  <div class="login-content user-log">
    <div class="login-logo">
      <a class="navbar-brand" routerLink="/login">
        <img
          src="{{ appConstant?.website_logo_path }}{{
            pageInfo?.website_content?.website_logo?.website_logo
          }}"
        />
        <img
          class="mob-logo"
          src="{{ appConstant?.website_logo_path }}{{
            pageInfo?.website_content?.website_logo?.website_logo
          }}"
        />
      </a>
    </div>
    <div class="co-box">
      <p>
        "If a picture is worth a thousand words, a video is worth a thousand
        pictures"
      </p>
      <!-- <button class="btn btn-yellow" routerLink="/help-and-support">
        Contact Us
      </button> -->
      <button class="btn btn-yellow" (click)="contactUsDialog()">
        Contact Us
      </button>
    </div>
  </div>
  <div class="user-form">
    <div class="loginnow">
      <div class="form-box">
        <div class="head d-flex align-items-center justify-content-between">
          <h3>Reset Password</h3>
          <div class="d-flex align-items-center">
            <a href="javascript:void(0)" routerLink="/login">
              <span class="icon-arrow mr-1"></span> Back to Login
            </a>
          </div>
        </div>
        <form
          (ngSubmit)="resetPassword(resetPasswordForm.valid)"
          #resetPasswordForm="ngForm"
        >
          <div class="form-group">
            <label>Enter OTP</label>
            <div class="d-flex otp-inputs">
              <div class="form-group mb-0">
                <input
                  type="text"
                  maxlength="1"
                  minlength="1"
                  placeholder="-"
                  type="text"
                  id="otp_one"
                  class="form-control"
                  [(ngModel)]="model.otp_one"
                  name="otp_one"
                  [ngClass]="{
                    'input-error-border':
                      otpCorrectValidation === false ||
                      otpRequiredValidation === false
                  }"
                  #otp_one="ngModel"
                  required
                  (keyup)="gotoNextField($event, 'otp_two', 'otp_one')"
                />
              </div>
              <div class="form-group mb-0">
                <input
                  type="text"
                  maxlength="1"
                  minlength="1"
                  placeholder="-"
                  type="text"
                  id="otp_two"
                  class="form-control"
                  [(ngModel)]="model.otp_two"
                  name="otp_two"
                  [ngClass]="{
                    'input-error-border':
                      otpCorrectValidation === false ||
                      otpRequiredValidation === false
                  }"
                  #otp_two="ngModel"
                  required
                  (keyup)="gotoNextField($event, 'otp_three', 'otp_one')"
                />
              </div>
              <div class="form-group mb-0">
                <input
                  type="text"
                  maxlength="1"
                  minlength="1"
                  placeholder="-"
                  type="text"
                  id="otp_three"
                  class="form-control"
                  [(ngModel)]="model.otp_three"
                  name="otp_three"
                  [ngClass]="{
                    'input-error-border':
                      otpCorrectValidation === false ||
                      otpRequiredValidation === false
                  }"
                  #otp_three="ngModel"
                  required
                  (keyup)="gotoNextField($event, 'otp_four', 'otp_two')"
                />
              </div>
              <div class="form-group mb-0">
                <input
                  type="text"
                  maxlength="1"
                  minlength="1"
                  placeholder="-"
                  type="text"
                  id="otp_four"
                  class="form-control"
                  [(ngModel)]="model.otp_four"
                  name="otp_four"
                  [ngClass]="{
                    'input-error-border':
                      otpCorrectValidation === false ||
                      otpRequiredValidation === false
                  }"
                  #otp_four="ngModel"
                  required
                  (keyup)="gotoNextField($event, 'otp_four', 'otp_three')"
                />
              </div>
            </div>
            <div [hidden]="otpRequiredValidation === true">
              <div class="validation_div">
                <p class="mb-0">The otp is required</p>
              </div>
            </div>

            <div class="text-right reset-link">
              <a
                href="javascript:void(0)"
                (click)="resendOtp()"
                *ngIf="resendBtnDisable == false"
              >
                <span class="icon-refresh"></span> Resend OTP
              </a>
              <a href="javascript:void(0)" *ngIf="resendBtnDisable == true">
                <span
                  class=""
                  [ngClass]="{ 'icon-loader load spin': resendBtnDisable }"
                ></span>
                Resend OTP
              </a>
            </div>
          </div>

          <div class="form-group ic-pass">
            <label>New Password</label>
            <span
              (click)="changePasswordType()"
              [ngClass]="{
                'icon-show-pass': passwordType != 'password',
                'icon-hide-pass': passwordType == 'password'
              }"
            ></span>
            <input
              pattern="^(^\S*)$"
              minlength="6"
              maxlength="30"
              validateEqual="confirm_password"
              reverse="true"
              #new_password="ngModel"
              required
              [(ngModel)]="model.new_password"
              name="new_password"
              type="{{ passwordType }}"
              placeholder="Enter new password"
              class="form-control"
              [ngClass]="{
                'input-error':
                  new_password?.errors &&
                  (new_password.dirty || resetPasswordForm.submitted)
              }"
              required
            />
            <div
              class="validation_div"
              *ngIf="
                new_password.errors &&
                (new_password.dirty ||
                  new_password.touched ||
                  resetPasswordForm.submitted)
              "
            >
              <p class="mb-0" *ngIf="new_password.errors.required">
                The new password is required
              </p>
              <p
                class="mb-0"
                *ngIf="
                  new_password.errors.minlength &&
                  resetPasswordForm.submitted &&
                  !new_password.errors.pattern
                "
              >
                The new password must be at least 6 characters long
              </p>
              <p
                class="mb-0"
                *ngIf="
                  new_password.errors.pattern && resetPasswordForm.submitted
                "
              >
                The new password does not contain any space
              </p>
            </div>
          </div>
          <div class="form-group ic-pass">
            <label>Confirm Password</label>
            <span
              (click)="changeConfirmPasswordType()"
              [ngClass]="{
                'icon-show-pass': confirmPasswordType != 'password',
                'icon-hide-pass': confirmPasswordType == 'password'
              }"
            ></span>
            <input
              maxlength="30"
              validateEqual="new_password"
              reverse="false"
              [(ngModel)]="model.confirm_password"
              name="confirm_password"
              #confirm_password="ngModel"
              required
              type="{{ confirmPasswordType }}"
              value=""
              placeholder="Enter confirm new password"
              class="form-control"
              required
              [ngClass]="{
                'input-error':
                  confirm_password?.errors &&
                  (confirm_password.dirty || resetPasswordForm.submitted)
              }"
            />
            <div
              [hidden]="
                confirm_password.valid ||
                (confirm_password.pristine && !resetPasswordForm.submitted)
              "
              class="validation_div"
              style="color: red"
            >
              <p
                class="mb-0"
                *ngIf="model.confirm_password && resetPasswordForm.submitted"
              >
                Confirm password doesn't match
              </p>
              <p class="mb-0" *ngIf="!model.confirm_password">
                The confirm password is required
              </p>
            </div>
          </div>

          <div class="text-center pt-3">
            <button [disabled]="btnDisable" type="submit" class="btn theme-btn">
              <span
                class=""
                [ngClass]="{ 'icon-loader load spin': btnDisable }"
              ></span>
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
