import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { Observable } from 'rxjs/internal/Observable'
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router'
import { AlertService } from '../../_services/index'
import { DataService } from '../data.service'
import constApi from 'src/assets/json/endpoint.json'
@Injectable({
  providedIn: 'root',
})
export class HomeService {
  options: any
  encryptedData: any
  api = constApi.homeApi
  constructor(
    private http: HttpClient,
    private router: Router,
    private data: DataService,
    private alertService: AlertService
  ) {
    this.encryptedData = localStorage.getItem('userInfo')
    if (this.encryptedData) {
      const user: any = JSON.parse(
        CryptoJS.AES.decrypt(
          this.encryptedData,
          environment.secretKey
        ).toString(CryptoJS.enc.Utf8)
      )
      const headers: any = new HttpHeaders({
        Authorization: user.tokenType + ' ' + user.accessToken,
      })
      this.options = { headers }
    }
  }

  updateToken(): void {
    this.encryptedData = localStorage.getItem('userInfo')
    if (this.encryptedData) {
      const user: any = JSON.parse(
        CryptoJS.AES.decrypt(
          this.encryptedData,
          environment.secretKey
        ).toString(CryptoJS.enc.Utf8)
      )
      const headers: any = new HttpHeaders({
        Authorization: user.tokenType + ' ' + user.accessToken,
      })
      this.options = { headers }
    }
  }

  getInformation(): Observable<any> {
    this.updateToken()
    return this.http.get(this.api.baseUrl + this.api.webContent, this.options)
  }

  contactUs(data: any): Observable<any> {
    this.updateToken()
    const sendData: any = {
      email: data.email,
      message: data.message,
      name: data.name,
      subject: data.subject,
    }
    data.mobile_number
      ? (sendData.mobile_number = Number(data.mobile_number))
      : delete sendData.mobile_number
    return this.http.post(
      this.api.baseUrl + this.api.contact,
      sendData,
      this.options
    )
  }

  getUserInfo(): Observable<any> {
    this.updateToken()
    return this.http.get(
      constApi.authApi.baseUrl + constApi.authApi.customerProfile,
      this.options
    )
  }

  changePassword(data: any): Observable<any> {
    const sendData: any = {
      current_password: data.old_password,
      new_password: data.password,
    }
    return this.http.post(
      constApi.authApi.baseUrl + constApi.authApi.changePassword,
      sendData,
      this.options
    )
  }

  editUserInfo(data: any): Observable<any> {
    if (!data.mobile_number) {
      delete data.mobile_number
    }
    this.updateToken()
    return this.http.patch(
      constApi.authApi.baseUrl + constApi.authApi.updateCustomerProfile,
      data,
      this.options
    )
  }

  Upload(file: File): Observable<any> {
    this.updateToken()
    const formdata: FormData = new FormData()
    formdata.append('image', file, file.name)
    return this.http.patch(
      constApi.authApi.baseUrl + constApi.authApi.customerProfileImage,
      formdata,
      this.options
    )
  }
  favoriteRestaurantList(data:any): Observable<any> {
    this.updateToken()
    return this.http.get(
      constApi.productApi.baseUrl + constApi.productApi.brands+'favourites/'+
      '?size='+
      data.limit +
      '&page=' +
      data.page +
      '&search_value=' +
      data.search.trim() +
      '&brands=' +
      data.brands +
      '&product_type=' +
      data.product_type,
      this.options
    )
  }

  favoriteList(data:any): Observable<any> {
    this.updateToken()
    return this.http.get(
      constApi.productApi.baseUrl + constApi.productApi.favoriteProducts+
      '?size='+
      data.limit +
      '&page=' +
      data.page +
      '&search_value=' +
      data.search.trim() +
      '&brands=' +
      data.brands +
      '&product_type=' +
      data.product_type,
      this.options
    )
  }
  log_out(status: any): void {
    if (status === 403 || status === 401) {
      const headers: any = new HttpHeaders({
        Authorization: '',
      })
      this.options = { headers }
      localStorage.removeItem('userInfo')
      this.data.changeMessage('update header')
      this.alertService.error(
        'Your account has been deactivated by admin, please visit to our support team for further details.'
      )
      this.options = ''
      this.router.navigate(['/login'])
    }
  }

  myFavouriteRecipeList() {
    this.updateToken()
    return this.http.get(
      constApi.productApi.baseUrl + constApi.productApi.favoriteRecipes,
      this.options
    )
  }

  myFavouriteCouponList() {
    this.updateToken()
    return this.http.get(
      constApi.productApi.baseUrl + constApi.productApi.favoriteCoupons,
      this.options
    )
  }
}
