import { Component, OnInit, Optional, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  brands: any = []
  checkedBrands = []
  btnDisable = true
  FilterValues: any = {}
  FilterStatus: any = {}
  checkBoxId: any = []

  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<FilterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.brands = data?.productList?.brands
  }
  
  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.checkedBrands.push(params.id)
      
    })
   this.data?.productList?.checkId ?  this.checkedBrands = JSON.parse(
      JSON.stringify(this.data?.productList?.checkId)
    ) : ''
    this.checkedBrands.length > 0
      ? (this.btnDisable = false)
      : (this.btnDisable = true)

    if (this.checkedBrands.length > 0) {
      for (let i = 0; i < this.checkedBrands.length; i++) {
        const index = this.brands.findIndex(
          (obj) => obj.public_id === parseInt(this.checkedBrands[i])
        )
        index === -1
          ? (this.brands[index].check = false)
          : (this.brands[index].check = true)
      }
    }
  }

  toggle(id, event, ind) {
    console.log(id)
    console.log(event)
    console.log(ind)
    this.checkBoxId.push(id)
    if (event.target.checked === true) {
      this.checkedBrands.push(id)
    } else {
      const index = this.checkedBrands.indexOf(id)
      this.checkedBrands.splice(index, 1)
      this.brands[ind].check = false
      this.checkedBrands = JSON.parse(JSON.stringify(this.checkedBrands))
    }
    this.checkedBrands.length > 0
      ? (this.btnDisable = false)
      : (this.btnDisable = true)
  }

  close() {
    if (this.data.productList.checkId != '') {
      if (this.checkedBrands.length > 0) {
        // eslint-disable-next-line no-self-assign
        this.data.productList.checkId = this.data.productList.checkId
        this.FilterStatus = 'no'
      } else {
        this.data.productList.checkId = []
        this.FilterStatus = 'reset'
      }
    } else {
      this.data.productList.checkId = []
    }
    this.FilterValues = {
      checkVal: this.data.productList.checkId,
      status: this.FilterStatus,
    }
    this.dialogRef.close(this.FilterValues)
  }

  onSubmit2() {
    this.brands = this.checkedBrands
    console.log(this.brands)
      this.data.productList.checkId = JSON.parse(
        JSON.stringify(this.checkedBrands)
      )
      this.FilterValues = {
        checkVal: this.data.productList.checkId,
        status: 'yes',
      }
  }

  onSubmit() {
    if (this.checkedBrands.length > 0) {
      this.brands = this.checkedBrands
      this.data.productList.checkId = JSON.parse(
        JSON.stringify(this.checkedBrands)
      )
      this.FilterValues = {
        checkVal: this.data.productList.checkId,
        status: 'yes',
      }
    } else {
      this.checkBoxId.length > 0
        ? (this.FilterStatus = 'reset')
        : (this.FilterStatus = 'no')
      this.data.productList.checkId = []
      this.FilterValues = {
        checkVal: this.data.productList.checkId,
        status: this.FilterStatus,
      }
    }
    this.dialogRef.close(this.FilterValues)
  }

  onReset() {
    this.data.productList.checkId = []
    for (let i = 0; i < this.brands.length; i++) {
      this.brands[i].check = false
    }
    this.FilterValues = {
      checkVal: this.data.productList.checkId,
      status: 'reset',
    }
    this.dialogRef.close(this.FilterValues)
  }
}
