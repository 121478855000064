<div class="login-page">
    <div class="login-content user-log">
        <div class="login-logo">
            <a class="navbar-brand" routerLink="/login">    
                <img src="{{ appConstant?.website_logo_path }}{{ pageInfo?.website_content?.website_logo?.website_logo }}" />            
                <img class="mob-logo" src="{{ appConstant?.website_logo_path }}{{ pageInfo?.website_content?.website_logo?.website_logo }}" />
            </a>
        </div>
        <div class="co-box">
            <p>
                "If a picture is worth a thousand words, a video is worth a thousand pictures"
            </p>
            <!-- <button class="btn btn-yellow" routerLink="/help-and-support">
        Contact Us
      </button> -->
            <button class="btn btn-yellow" (click)="contactUsDialog()">
        Contact Us
      </button>
        </div>
    </div>
    <div class="user-form">
        <div class="loginnow">
            <div class="form-box">
                <div class="head d-flex align-items-center justify-content-between">
                    <h3 class="text-capitalize">Forgot Password?</h3>
                    <div class="d-flex align-items-center">
                        <a href="javascript:void(0)" routerLink="/login">
                            <span class="icon-arrow mr-1"></span> Back to Login
                        </a>
                    </div>
                </div>
                <div class="text-center">
                    <span>Enter your e-mail address and we'll send you a link to reset your password.</span>
                </div>
                <div class="text-center forgot-img pt-4 pt-md-3">
                    <img src="assets/images/forgot_password.png" class="img-fluid" />
                </div>
                <form (ngSubmit)="forgotPassword(forgotPasswordForm.valid)" #forgotPasswordForm="ngForm">
                    <div class="form-group">
                        <label>Email</label>
                        <input maxlength="150" pattern="[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-zA-Z]{2,8}$" name="email" type="email" placeholder="Enter email" class="form-control" [(ngModel)]="model.email" [ngClass]="{
                error: email?.errors && (email.dirty || forgotPasswordForm.submitted)
              }" #email="ngModel" required />
                        <div *ngIf="email.errors">
                            <div class="validation_div">
                                <p *ngIf="email.errors.pattern && forgotPasswordForm.submitted" class="mb-0">
                                    The correct email is required
                                </p>
                                <p *ngIf="email.errors.required && forgotPasswordForm.submitted" class="mb-0">
                                    The email is required
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="text-center pt-3">
                        <button [disabled]="btnDisable" type="submit" class="btn theme-btn">
                        <span
                          class=""
                          [ngClass]="{ 'icon-loader load spin': btnDisable }"
                        ></span>
                        Send
                      </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>