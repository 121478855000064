import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { Subject } from 'rxjs/Subject'
import { Alert, AlertType } from '../_models/alert'

@Injectable()
export class AlertService {
  private subject: any = new Subject<Alert>()
  private keepAfterRouteChange: boolean

  constructor(private router: Router) {
    this.keepAfterRouteChange = false
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false
        } else {
          this.clear()
        }
      }
    })
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable()
  }

  success(
    message: string,
    timeoutValue = 2000,
    status = false,
    signupStatus = '',
    keepAfterRouteChange = false
  ): void {
    this.alert(
      AlertType.Success,
      message,
      timeoutValue,
      status,
      signupStatus,
      keepAfterRouteChange
    )
  }

  signup(
    message: string,
    timeoutValue = 6000,
    status = false,
    signupStatus = 'signup',
    keepAfterRouteChange = false
  ): void {
    this.alert(
      AlertType.Success,
      message,
      timeoutValue,
      status,
      signupStatus,
      keepAfterRouteChange
    )
  }

  error(
    message: string,
    timeoutValue = 10000,
    status = false,
    signupStatus = '',
    keepAfterRouteChange = false
  ): void {
    this.alert(
      AlertType.Error,
      message,
      timeoutValue,
      status,
      signupStatus,
      keepAfterRouteChange
    )
  }

  info(
    message: string,
    timeoutValue = 10000,
    status = false,
    signupStatus = '',
    keepAfterRouteChange = false
  ): void {
    this.alert(
      AlertType.Info,
      message,
      timeoutValue,
      status,
      signupStatus,
      keepAfterRouteChange
    )
  }

  warn(
    message: string,
    timeoutValue = 10000,
    status = false,
    signupStatus = '',
    keepAfterRouteChange = false
  ): void {
    this.alert(
      AlertType.Warning,
      message,
      timeoutValue,
      status,
      signupStatus,
      keepAfterRouteChange
    )
  }

  alert(
    type: AlertType,
    message: string,
    timeoutValue = 10000,
    status = false,
    signupStatus = '',
    keepAfterRouteChange = false
  ): void {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next({
      type,
      message,
      timeoutValue,
      status,
      signupStatus,
    })
  }

  clear(): void {
    this.subject.next()
  }
}
