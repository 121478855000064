import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holy-smokes-village-detail',
  templateUrl: './holy-smokes-village-detail.component.html',
  styleUrls: ['./holy-smokes-village-detail.component.scss']
})
export class HolySmokesVillageDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
