import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/internal/Observable'
import constApi from 'src/assets/json/endpoint.json'
@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  options: any
  encryptedData: any
  api: any
  constructor(private http: HttpClient) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    this.options = { headers }
    this.api = constApi.authApi
  }

  signup(data: any): Observable<any> {
    const sendData: any = {
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
      zip_code:data?.zipCode
    }
    if (data.social_access_token) {
      sendData.social_access_token = data.social_access_token
      sendData.user_type = 'customer'
      sendData.social_type = 'facebook'
      return this.http.post(
        this.api.baseUrl + this.api.customerSocialLogin,
        sendData
      )
    } else {
      return this.http.post(
        this.api.baseUrl + this.api.customerCreate,
        sendData,
        this.options
      )
    }
  }
  getUserInfo(data: any): Observable<any> {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: data.tokenType + ' ' + data.accessToken,
    })
    this.options = { headers }
    return this.http.get(
      this.api.baseUrl + this.api.customerProfile,
      this.options
    )
  }
}
