<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <img
        src="assets/images/holy-smokes_details.png"
        class=""
        style="width: 100%"
      />
    </div>
  </div>

  <!-- Tabs -->
  <mat-tab-group>
    <mat-tab label="Village Menu">
      <div class="howitwork product-list restaurant-list-sect">
        <div class="container">
          <div class="row justify-center">
            <div class="col-sm-6 col-md-4 col-xl-3">
              <div class="pro-box">
                <div class="img">
                  <img src="assets/images/menu/1.png" />
                </div>
                <div class="content">
                  <h4>Traditional Village</h4>
                  <h5>Open: 11:00 AM - 4:00 PM</h5>
                </div>
                <div class="hover-box">
                  <button class="btn theme-btn" routerLink="/holysmokeschs-details/0">View Details</button>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">
              <div class="pro-box">
                <div class="img">
                  <img src="assets/images/menu/2.png" />
                </div>
                <div class="content">
                  <h4>Texas Village</h4>
                  <h5>Open: 11:00 AM - 4:00 PM</h5>
                </div>
                <div class="hover-box">
                  <button class="btn theme-btn" routerLink="/holysmokeschs-details/1">View Details</button>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-xl-3">
              <div class="pro-box">
                <div class="img">
                  <img src="assets/images/menu/3.png" />
                </div>
                <div class="content">
                  <h4>New School Village</h4>
                  <h5>Open: 11:00 AM - 4:00 PM</h5>
                </div>
                <div class="hover-box">
                  <button class="btn theme-btn" routerLink="/holysmokeschs-details/2">View Details</button>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-4 col-xl-3">
              <div class="pro-box">
                <div class="img">
                  <img src="assets/images/menu/4.png" />
                </div>
                <div class="content">
                  <h4>Coastal Village</h4>
                  <h5>Open: 11:00 AM - 4:00 PM</h5>
                </div>
                <div class="hover-box">
                  <button class="btn theme-btn" routerLink="/holysmokeschs-details/3">View Details</button>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">
              <div class="pro-box">
                <div class="img">
                  <img src="assets/images/menu/5.png" />
                </div>
                <div class="content">
                  <h4>VIP</h4>
                  <h5>Open: 11:00 AM - 4:00 PM</h5>
                </div>
                <div class="hover-box">
                  <button class="btn theme-btn" routerLink="/holysmokeschs-village">View Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Event Map"> <img src="assets/images/event_map.png" class="tab-image mt-30 mb-30" /> </mat-tab>
    <mat-tab label="Parking Map"> <img src="assets/images/parking_map.png" class="tab-image mt-30 mb-30" /> </mat-tab>
    <mat-tab label="Our Sponsers"> 
      <img src="assets/images/sponsers.svg" class="tab-image mt-30" /> 
      <img src="assets/images/sponsers2.svg" class="tab-image mb-30" />
    </mat-tab>
  </mat-tab-group>
  <!-- ./Tabs -->
</div>

<div class="donate-box">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-3">
        <h2 class="text-white bold">Want To Donate?</h2>
        <a href="https://www.convergepay.com/hosted-payments/?ssl_txn_auth_token=4RFk2bb8TdWUsWg9wolsKQAAAXyeK1Ue" target="_blank"><button class="white-btn mt-2">Support Today</button></a>
        <p class="text-white banner-p">YOUR SUPPORT WILL HELP BENEFIT HOGS FOR THE CAUSE, MUSC SHAWN JENKINS CHILDREN’S HOSPITAL AND THE RONALD MCDONALD HOUSE OF CHARLESTON</p>
      </div>
      <!-- <div class="col-md-6">
        <div class="card bottom-card">
          <div class="card-header">Donation</div>
          <div class="card-body">
            <p>Enter the required information in the fields below.</p>
  
            <div class="row">
              <div class="col-md-6">Amount:</div>
              <div class="col-md-6">
                <input type="text" name="amount" placeholder="e.g. $1.00"> USD
              </div>
            </div>
  
          </div>
        </div>
      </div> -->
    </div>
    
  </div>
	
</div>