<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#00ff00" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<section class="content-pages">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="title text-center">
          <h2>{{ privcyPolicy?.title ? privcyPolicy?.title : '' }}</h2>
        </div>
      </div>
    </div>
    <div class="add-content" *ngIf="!noRecordFound && privcyPolicy?.content">
      <article class="simmer-animation" [ngClass]="{ 'd-none': showSimmer === false }">
        <div class="text w-50"></div>
        <div class="text w-75"></div>
        <div class="s-search"></div>
      </article>
      <p [innerHTML]="privcyPolicy?.content | safeHtml"></p>
    </div>
    <div class="add-content" *ngIf="noRecordFound && !privcyPolicy?.content">
      <div class="col-md-12 text-center no-data">
        <img src="assets/images/no-record.png" />
        <h4 class="mt-3">Record Not Found</h4>
      </div>
    </div>
  </div>
</section>