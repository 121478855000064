import { Component, ElementRef, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as CryptoJS from 'crypto-js'
import { environment } from '../../environments/environment'
import { AlertService } from '../_services/index'
import { SignUpService } from './sign-up.service'
import { ContactUsModalComponent } from '../user/home/contact-us-modal/contact-us-modal.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  isShow = false
  terms = false
  isShowTermsContent = false
  isShowPrivacyContent = false
  model: any = {}
  accessToken: any
  expiresIn: any
  tokenType: any
  btnDisable: any = false
  passwordType: any = 'password'
  confirmPasswordType: any = 'password'
  socialInfo: any = {}
  pageInfo: any = {}
  appConstant: any
  constructor(
    private router: Router,
    private el: ElementRef,
    private alertService: AlertService,
    private signUpService: SignUpService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.appConstant = environment
    this.pageInfo = JSON.parse(localStorage.getItem('contentInfo'))
    this.socialInfo = localStorage.getItem('socialInfo')
    if (this.socialInfo) {
      this.socialInfo = JSON.parse(
        CryptoJS.AES.decrypt(this.socialInfo, environment.secretKey).toString(
          CryptoJS.enc.Utf8
        )
      )
      this.model = this.socialInfo
    }
  }
  signup(valid: any): void {
    if (valid) {
      this.router.url === '/brand-login'
        ? (this.model.userType = 'brand')
        : (this.model.userType = 'superuser')
      this.btnDisable = true
      this.signUpService.signup(this.model).subscribe(
        (data: any) => {
          this.accessToken = data.access_token
          this.expiresIn = data.expires_in
          this.tokenType = data.token_type
          localStorage.removeItem('socialInfo')
          this.getUserInfo()
        },
        (err: any) => {
          this.btnDisable = false
          if (err.error.error) {
            this.alertService.error(err.error.error)
          }
        }
      )
    } else {
      setTimeout(() => {
        this.scrollToFirstInvalidControl()
      }, 800)
    }
  }
  toggle() {
    this.isShow = !this.isShow
  }
  ContentTermstoggle() {
    this.isShowTermsContent = !this.isShowTermsContent
  }
  ContentPrivacytoggle() {
    this.isShowPrivacyContent = !this.isShowPrivacyContent
  }
  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .input-error')
    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      })
      setTimeout(() => {
        firstInvalidControl.focus()
      }, 1000)
    }
  }
  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset: any = 50
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset
  }

  getUserInfo(): void {
    const sendData: any = {
      accessToken: this.accessToken,
      expiresIn: this.expiresIn,
      tokenType: this.tokenType,
    }
    this.signUpService.getUserInfo(sendData).subscribe(
      (data: any) => {
        this.model = JSON.parse(JSON.stringify(data))
        this.model.userType = sendData.userType
        this.model.first_name = data.first_name
        this.model.last_name = data.last_name
        this.model.email = data.email
        this.model.image = data.image
        this.model.accessToken = this.accessToken
        this.model.expiresIn = this.expiresIn
        this.model.tokenType = this.tokenType
        const setUserData: any = CryptoJS.AES.encrypt(
          JSON.stringify(this.model),
          environment.secretKey
        ).toString()
        localStorage.setItem('userInfo', setUserData)
        this.btnDisable = false
        this.alertService.success('You are logged in successfully.')
        this.router.navigate(['/'])
      },
      () => {
        this.btnDisable = false
      }
    )
  }

  changePasswordType(): void {
    this.passwordType === 'password'
      ? (this.passwordType = 'text')
      : (this.passwordType = 'password')
  }

  changeConfirmPasswordType(): void {
    this.confirmPasswordType === 'password'
      ? (this.confirmPasswordType = 'text')
      : (this.confirmPasswordType = 'password')
  }

 

contactUsDialog() {
    const dialogRef = this.dialog.open(ContactUsModalComponent, {
      width: '670px',
      panelClass: "contact-us-dialog"
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        
      }
    });
  }
}
