import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { of, throwError } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { catchError } from 'rxjs/operators'
import { AlertService } from '../_services'
import constApi from 'src/assets/json/endpoint.json'
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  options: any
  encryptedData: any
  api: any
  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private spinner: NgxSpinnerService
  ) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    this.options = { headers }
    this.api = constApi.authApi
  }
  login(data: any): Observable<any> {
    const sendData: any = {
      email: data.email,
      password: data.password,
      user_type: data.userType,
    }
    return this.http
      .post(this.api.baseUrl + this.api.customerLogin, sendData, this.options)
      .pipe(
        catchError((err) => {
          this.errorHandler(err)
          return of(null)
        })
      )
  }
  getUserInfo(data: any): Observable<any> {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: data.tokenType + ' ' + data.accessToken,
    })
    this.options = { headers }
    return this.http
      .get(this.api.baseUrl + this.api.customerProfile, this.options)
      .pipe(
        catchError((err) => {
          this.errorHandler(err)
          return of(null)
        })
      )
  }
  forGotPassword(data: any): Observable<any> {
    const sendData: any = { email: data.email, user_type: data.userType }
    return this.http.post(this.api.baseUrl + this.api.forgotPassword, sendData).pipe(
      catchError((err) => {
        this.errorHandler(err)
        return of(null)
      })
    )
  }
  resetPassword(data: any): Observable<any> {
    const sendData: any = {
      otp: data.otp,
      new_password: data.new_password,
      forgot_password_public_id: data.public_id,
    }
    return this.http.post(this.api.baseUrl + this.api.updatePassword, sendData).pipe(
      catchError((err) => {
        this.errorHandler(err)
        return of(null)
      })
    )
  }

  socialLogin(data: any): Observable<any> {
    return this.http.post(this.api.baseUrl + this.api.customerSocialLogin, data)
  }
  
  errorHandler(error: any): any {
    this.spinner.hide()
    if (error.status === 403) {
      this.alertService.error(error.error.error)
    } else if (error.status === 404) {
      this.alertService.error(error.error.error)
    } else if (error.status === 400) {
      this.alertService.error(error.error.error)
    } else if (error.status === 500) {
      this.alertService.error('Something went wrong')
    } else if (error.status === 0) {
      this.alertService.error(error.error.error)
    } else if (error.status === 422) {
      this.alertService.error(error.error.error)
    } else if (error.status === 409) {
      this.alertService.error(error.error.error)
    } else if (error.status === 401) {
      this.alertService.error(error.error.error)
    }else{
      this.alertService.error('Something went wrong')
    }
    return throwError(error)
  }
}
