<div class="feedback-dilog">
  <div mat-dialog-title class="popup-header">
    <div class="d-flex justify-content-end text-right close-btn">
      <a href="javascript:void(0)" (click)="close()"
        ><span class="icon-close"></span
      ></a>
    </div>
  </div>
  <mat-dialog-content>
    <div class="filter text-center">
      <h3>Confirm Age Please</h3>
      <h5>Are you 21 years old or olders?</h5>
      <p>(As part of commitment to responsible drinking)</p>
      <div class="my_radio mb-0">
        <input
          [checked]="checked"
          (change)="toggle($event)"
          name="product_remember"
          class="form-check-input"
          type="checkbox"
          id="product_remember"
        />
        <label class="form-check-label" for="product_remember">
          Remember me on this device</label
        >
        <span class="check"></span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="text-right">
    <div class="d-flex justify-content-center w-100">
      <button type="submit" class="btn yes-btn" (click)="rememberMe('yes')">
        Yes
      </button>
      <button
        mat-button
        mat-dialog-close
        class="ml-2 btn no-btn"
        (click)="rememberMe('no')"
      >
        No
      </button>
    </div>
  </mat-dialog-actions>
</div>
